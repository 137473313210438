import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl, FormControlLabel, Switch, Chip } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../store/cms-context';

import { getAccessJwtToken } from '../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { a, Analytics, Storage } from 'aws-amplify';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimeZones from "../../Utilities/TimeZones";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import { clone, cloneDeep, min } from "lodash";
import { ONE_DAY } from "../../Utilities/Metrics";
import { has_marketing_write_access } from "../../Utilities/HelperFunctions";


const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));

function FacebookConfigs(props) {


  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_collections, set_all_collections] = React.useState([]);

  const [component_state, set_component_state] = React.useState({layout_type: 'list', collection_id : ''})

  const [all_campaigns, set_all_campaigns] = React.useState([]);

  const [all_accounts, set_all_accounts] = React.useState([]);

  const [active_accounts, set_active_accounts] = React.useState([]);

  const [excluded_campaigns, set_excluded_campaigns] = React.useState([]);

  const [verbose_logging, set_verbose_logging] = React.useState(false);

  const navigate = useNavigate();


  useEffect(() => {

    isMountedRef.current = true

    get_configurations_settings()

    return () => isMountedRef.current = false;

  }, [])

  useEffect(() =>{

    console.log('all campaigns: ', all_campaigns)

  }, [all_campaigns])


  async function get_picture_async(key, index) {

    const picUrl = await Storage.get(key, {
      level: 'public',
      bucket: 'snag-cms175447-staging',
      expires:86400,
      region: 'eu-west-2',
    });

    console.log('url was', picUrl)

    set_all_collections((previous_collections) => {

      const temp_collections = [...previous_collections]

      if(index < temp_collections.length){
        temp_collections[index].image.url = picUrl;
      }

      return(set_all_collections(temp_collections))

    })

  }

  function handle_exc_campaigns_change(event, newValue){

    set_excluded_campaigns(newValue)

  }

  function handle_active_accounts_change(event, newValue){

    set_active_accounts(newValue)

  }


  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Configurations</Typography> 

          <div>

          <FormControlLabel
          value="Verbose logging"
          control={<CustomSwitch checked={verbose_logging} onChange={(event) => {update_configurations("test", event.target.checked)}} color="primary" />}
          label={<Typography fontSize={12} color={grey[800]}><strong>Verbose logging</strong></Typography>}
          labelPlacement="start"
          sx={{margin:1}}
        />

          <Button variant="contained" onClick = {() => {update_configurations("rules", verbose_logging)}}>Update Configurations</Button>

          </div>

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >

      <Paper elevation = {2} style={{marginTop:16, padding:20}}>
        
        <Grid
          container
          spacing={2}
          alignItems="center"
        >

          <Grid
            item
            xs={12}
          >

          <Autocomplete
                multiple
                id="active-accounts"
                options={all_accounts}
                getOptionLabel={(option) => option.name}
                value={active_accounts}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {handle_active_accounts_change(event, newValue)}}
                renderInput={(params) => (
                  <TextField {...params} label="Active Accounts" placeholder="Active Accounts" />
                )}
          />     

        </Grid>

        <Grid
            item
            xs={12}
          >

          <Autocomplete
                multiple
                id="excluded-campaigns"
                options={all_campaigns}
                getOptionLabel={(option) => option.name}
                value={excluded_campaigns}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {handle_exc_campaigns_change(event, newValue)}}
                renderOption={(props, option, index) => {

                  const key = `listItem-${option.id}`;
                  return (
                    <li {...props} key={key}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Excluded Campaigns" placeholder="Excluded Campaigns" />
                )}
          />
                      

        </Grid>

    

        {active_accounts.map((account, index) => { 

          return(

            <Grid key={index} item xs={12} >

            <Grid container spacing={2}>

            <Grid
            item 
            xs={12}
            >

              <Typography fontSize={14}><strong>{account.name}</strong></Typography>

            </Grid>

            <Grid
              item
              xs={6}
              md={3}
            >


            <FormControl fullWidth >
              <InputLabel htmlFor="outlined-adornment-cpa">Cpa Threshold</InputLabel>
              <OutlinedInput
                id="outlined-adornment-cpa"
                value={active_accounts[index].cpa_threshold ? active_accounts[index].cpa_threshold : ''}
                type="number"
                size="small"
                onChange={(event) => {

                  console.log('value was: ', event.target.value)

                  const temp_accounts = [...active_accounts];

                  if(event.target.value >= 0){
                    temp_accounts[index].cpa_threshold = event.target.value;
                  }
                  else{
                    temp_accounts[index].cpa_threshold = 0;
                  }

                  set_active_accounts(temp_accounts);

                }}
                startAdornment={<InputAdornment position="start">£</InputAdornment>}
                label="Cpa Threshold"
              />
            </FormControl>

            </Grid>

            <Grid
              item
              xs={6}
              md={3}
            >


            <FormControl fullWidth >
              <InputLabel htmlFor="outlined-adornment-spend">Spend Threshold</InputLabel>
              <OutlinedInput
                id="outlined-adornment-spend"
                value={active_accounts[index].spend_threshold ? active_accounts[index].spend_threshold : ''}
                type="number"
                size="small"
                onChange={(event) => {

                  console.log('value was: ', event.target.value)

                  const temp_accounts = [...active_accounts];

                  if(event.target.value >= 0){
                    temp_accounts[index].spend_threshold = event.target.value;
                  }
                  else{
                    temp_accounts[index].spend_threshold = 0;
                  }

                  set_active_accounts(temp_accounts);

                }}
                startAdornment={<InputAdornment position="start">£</InputAdornment>}
                label="Spend Threshold"
              />
            </FormControl>

            </Grid>

            <Grid
              item
              xs={6}
              md={3}
            >

                <LocalizationProvider LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  label="Night start"
                  value={active_accounts[index].night_start != null ? active_accounts[index].night_start : null}
                  onChange={(newValue) => {

                    console.log('value was: ', newValue)

                    const temp_accounts = [...active_accounts];

                    temp_accounts[index].night_start = newValue;

                    set_active_accounts(temp_accounts);

                  }}
                  renderInput={(params) => <TextField size="small" {...params} fullWidth/>}
                />
              </LocalizationProvider>

            </Grid>


            <Grid
              item
              xs={6}
              md={3}
            >

                <LocalizationProvider LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  label="Night end"
                  value={active_accounts[index].night_end != null ? active_accounts[index].night_end : null}
                  onChange={(newValue) => {

                    console.log('value was: ', newValue)

                    const temp_accounts = [...active_accounts];

                    temp_accounts[index].night_end = newValue;

                    set_active_accounts(temp_accounts);

                    /*
                    console.log('new value was: ', newValue, ' : ', newValue.getTime() )
                    
                  if(typeof(newValue.getTime()) === 'number' && !isNaN(parseFloat(newValue.getTime()))){

                      console.log('value was: ', newValue)
                      const temp_accounts = [...active_accounts];
                      temp_accounts[index].night_end = newValue;
                      set_active_accounts(temp_accounts);

                  }else{

                      const temp_accounts = [...active_accounts];
                      temp_accounts[index].night_end = new Date()
                      set_active_accounts(temp_accounts);
                      console.log('not inside')
                    
                  }

                  */
             
                  }}
                  renderInput={(params) => <TextField size="small" {...params} fullWidth/>}
                />
              </LocalizationProvider>

            </Grid>

            <Grid item xs={6}
              md={4}>
          
              <TextField id="outlined-basic" InputLabelProps={{ shrink: true }} size="small" value={account.pixel_id != null ? account.pixel_id: ""} onChange={(event) => {

                console.log('value was: ', event.target.value)

                const temp_accounts = [...active_accounts];

                temp_accounts[index].pixel_id = event.target.value;

                set_active_accounts(temp_accounts);

              }}          
            fullWidth label="Pixel ID" variant="outlined" />
     
            </Grid>

            <Grid item xs={6}
              md={4}>
          
              <TextField id="outlined-basic" InputLabelProps={{ shrink: true }} size="small" value={account.conversion_domain != null ? account.conversion_domain : ""} onChange={(event) => {

                console.log('value was: ', event.target.value)

                const temp_accounts = [...active_accounts];

                temp_accounts[index].conversion_domain = event.target.value;

                set_active_accounts(temp_accounts);

              }}          
            fullWidth label="Conversion Domain" variant="outlined" />
     
            </Grid>

            <Grid item xs={12}
              md={4}>

              <div style={{position:'relative', zIndex:1000 - index, marginTop:1}}>
          
              <TimezoneSelect
                value={account.time_zone != null ? account.time_zone : ''}
                onChange={(value) => {console.log('value was: ', value);const temp_accounts = [...active_accounts]; temp_accounts[index].time_zone = value; set_active_accounts(temp_accounts);}}
              />

              </div>
     
            </Grid>
            

            <Grid
              item
              xs={9}
            >

                <LocalizationProvider LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  label="Interval"
                  value={active_accounts[index].time_interval ? active_accounts[index].time_interval : null}
                  onChange={(newValue) => {
                    
                    console.log('value was: ', newValue)

                    const temp_accounts = [...active_accounts];

                    temp_accounts[index].time_interval = newValue;

                    set_active_accounts(temp_accounts);
             
                  }}
                  renderInput={(params) => <TextField size="small" {...params} fullWidth/>}
                />
              </LocalizationProvider>

            </Grid>

            <Grid
              item
              xs={3}
            >

              <Button fullWidth variant="contained" onClick = {() => {handle_time_interval(index)}}>Add</Button>

            </Grid>

            {active_accounts[index].selected_intervals && active_accounts[index].selected_intervals.length > 0 ? 

              <>

            <Grid
            item 
            xs={12}
            >

              <FormControlLabel
              value="Toggle Intervals"
              control={<CustomSwitch checked={active_accounts[index].intervals_enabled ? active_accounts[index].intervals_enabled : false} onChange={(event) => {handle_time_interval_switch(index, event.target.checked)}} color="primary" />}
              label={<Typography fontSize={13} color={grey[800]}><strong>Toggle Intervals</strong></Typography>}
              labelPlacement="start"
              sx={{margin:1}}
              />

            </Grid>
            
            <Grid
            item
            xs={12}
            >

            <Stack direction="row" spacing={1}>
              {
                active_accounts[index].selected_intervals.map((selected_interval, index2) => {
                  return(
                    <Chip key={index2} color="primary" variant="outlined" label={`${selected_interval.getHours().toString().padStart(2, '0')}:${selected_interval.getMinutes().toString().padStart(2, '0')}`} onDelete={() => {handle_interval_delete(index, index2)}} />
                  )
                })
              }
            </Stack>

            </Grid>

            </>

            : null}
          
          </Grid>

          </Grid>

          )
            
          })}

        </Grid>

        </Paper>


    
        </Grid>



      </Grid>

    </Container>      


    </Box>

     }

    </> 


  );

  function get_configurations_settings() {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/facebook/configurations',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization': auth_creds.jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was',cloneDeep(result))

              if(result.data){

                var campaigns = result.data.campaigns;

                const exc_campaigns = result.data.rules.excluded_campaigns;

                set_all_campaigns(campaigns);

                // continue here (how to get campaigns from corresponding ids?)

                if(exc_campaigns.length > 0){

                  const temp_exc_campaigns = []

                  for (var i=0; i < campaigns.length; i++){

                    if(exc_campaigns.includes(campaigns[i].id.toString())){

                      temp_exc_campaigns.push(campaigns[i])

                    }

                  }

                  console.log('setting excluded campaigns: ', temp_exc_campaigns)

                  set_excluded_campaigns(temp_exc_campaigns)

              }

              var accounts = result.data.facebook_accounts

              const act_accounts = result.data.rules.active_accounts;

                set_all_accounts(accounts);

                const temp_act_accounts = []

                if(act_accounts && act_accounts.length > 0){


                for (var z=0; z < accounts.length; z++){

                  for (var z1=0; z1< act_accounts.length; z1++){

                    if(act_accounts[z1].id.toString() === accounts[z].id.toString()){

                      const temp_account = act_accounts[z1]

                      temp_account.name = accounts[z].name

                      if(temp_account.cpa_threshold == null){
                        temp_account.cpa_threshold = ''
                      }
  
                      if(temp_account.spend_threshold == null){
                        temp_account.spend_threshold = ''
                      }

                      if(temp_account.intervals_enabled == null){
                        temp_account.intervals_enabled = false
                      }

                      if(temp_account.selected_intervals){

                        const temp_intervals = [];

                        temp_account.selected_intervals.forEach((interval) => {
                          // Reset today's date to midnight

                          const today = new Date();

                          today.setHours(0, 0, 0, 0);

                          // Split the time string into hours and minutes
                          const parts = interval.split(':');
                          const hours = parseInt(parts[0], 10);
                          const minutes = parseInt(parts[1], 10);

                          // Set the hours and minutes from the time string
                          today.setHours(hours);
                          today.setMinutes(minutes);

                          temp_intervals.push(today)

                        })

                        temp_account.selected_intervals = temp_intervals

                      }

  
                      if(temp_account.night_start != null){
  
                        const start_date = new Date();
  
                        start_date.setHours(parseInt(temp_account.night_start.split(":")[0]))
  
                        start_date.setMinutes(parseInt(temp_account.night_start.split(":")[1]))
  
                        start_date.setSeconds(0)
  
                        temp_account.night_start = start_date
  
                        console.log('nigth start was: ', start_date)
  
                      }
  
                      if(temp_account.night_end != null){
  
                        const end_date = new Date();
  
                        end_date.setHours(parseInt(temp_account.night_end.split(":")[0]))
  
                        end_date.setMinutes(parseInt(temp_account.night_end.split(":")[1]))
  
                        end_date.setSeconds(0)
  
                        temp_account.night_end = end_date
  
                        console.log('nigth end was: ', end_date)
  
                      }
  
                      temp_act_accounts.push(temp_account)

                      break;

                    }

                  }

                }

                console.log('setting excluded campaigns: ', temp_act_accounts)

                set_active_accounts(temp_act_accounts)

              }

              set_verbose_logging(result.data.rules.verbose)

              console.log('setting was 0:')

              const temp_analytics = [...result.data.analytics]

              console.log('setting was:')

            const final_analytics = []


            temp_act_accounts.forEach((account) => {

              const account_index = temp_analytics.findIndex(child => ("act" + "_" + child.account) === account.id)

              if(account_index > -1){

                const child = temp_analytics[account_index]

                child.account_name = account.name

                console.log('first was', account.name)

                child.turned_off = child.turned_off.map((second_child)=>{
                  var myToday = new Date(second_child.date.replace(/-/g, "/"))
                  myToday.setMinutes(0)
                  myToday.setSeconds(0)
                   return({"x": myToday.getTime(), "y":second_child.count})
                 })

                 const min_time = new Date().getTime() - 1.5 * ONE_DAY

                 const final_turned_off = []

                 if(child.turned_off.length > 0){


                    child.turned_off.forEach((temp_child) =>{

                      if(temp_child.x >= min_time){

                        final_turned_off.push(temp_child)
                      }

                    })

                 }

                 child.turned_off = final_turned_off


                 child.turned_on = child.turned_on.map((second_child)=>{
                  var myToday = new Date(second_child.date.replace(/-/g, "/"))
                  myToday.setMinutes(0)
                  myToday.setSeconds(0)
                   return({"x": myToday.getTime(), "y":second_child.count})
                 })

                 const final_turned_on = []

                 if(child.turned_on.length > 0){

                    child.turned_on.forEach((temp_child) =>{

                      if(temp_child.x >= min_time){
  
                        final_turned_on.push(temp_child)
                      }

                    })

                 }

                 child.turned_on = final_turned_on

                 final_analytics.push(child)
                
              }

            })



             console.log('setting analuytis', final_analytics)

            

                /*

                var temp_collections = result.collections;

                if(temp_collections.length === 0){

                  cmsContext.setSnackBarOpen(true, "info", "No collections yet. Let's create one to get started.")

                }
                
                temp_collections = temp_collections.map((collection) => {
                  return(Object.assign(collection,{'is_checked':false}))
                });

                set_all_collections(temp_collections)

                for(var collection_index in temp_collections){

                  if(temp_collections[collection_index].image && temp_collections[collection_index].image.aws_key){

                    get_picture_async(temp_collections[collection_index].image.aws_key, collection_index)

                  }

                }
                */
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
      )

    })


  }


  function handle_time_interval_switch(index, checked) {

    const temp_accounts = [...active_accounts];

    console.log("interval account was: ", temp_accounts[index])

    temp_accounts[index].intervals_enabled = checked

    set_active_accounts(temp_accounts)

  }

  function handle_time_interval(index) {

    const temp_accounts = [...active_accounts];

    console.log("interval account was: ", temp_accounts[index])

    if(!temp_accounts[index].time_interval){

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid time interval!")
      return

    }

    if(!temp_accounts[index].selected_intervals){
      temp_accounts[index].intervals_enabled =  true
      temp_accounts[index].selected_intervals = []
    }


    var found_duplicate = false;

    temp_accounts[index].selected_intervals.forEach((interval) => {

      if(interval.getHours() === temp_accounts[index].time_interval.getHours()){

        found_duplicate = true;

      }

    })

    if(found_duplicate){

      cmsContext.setSnackBarOpen(true, "error", "Time intervals should be distinct!")
      return

    }

    temp_accounts[index].selected_intervals.push(temp_accounts[index].time_interval)

    temp_accounts[index].selected_intervals.sort((a, b) => a - b);

    set_active_accounts(temp_accounts)

  }

  function handle_interval_delete(index, index2) {

    const temp_accounts = [...active_accounts];

    console.log("interval account was: ", temp_accounts[index], " : ", index, " : ", index2)

    temp_accounts[index].selected_intervals.splice(index2, 1)

    if(temp_accounts[index].selected_intervals.length === 0){

      temp_accounts[index].intervals_enabled = false

    }

    console.log("updated account was: ", temp_accounts[index])

    set_active_accounts(temp_accounts)

  }
 
  function update_configurations(type, is_verbose){

    console.log('updating')

    var request_body = {}

    if(type === "rules"){

    request_body.rules = {}

    var exc_campaigns = [...excluded_campaigns]

    exc_campaigns = exc_campaigns.map((campaign) => campaign.id.toString())

    var act_accounts = cloneDeep(active_accounts)

    var start_date;

    var end_date;

    var found_error = false;

    for (var i=0; i < act_accounts.length; i++){

      start_date = act_accounts[i].night_start

      console.log('start date was: ', start_date)

      if(act_accounts[i].cpa_threshold == null || act_accounts[i].cpa_threshold.trim().length === 0){

        found_error = true;
        break

      }

      if(act_accounts[i].spend_threshold == null || act_accounts[i].spend_threshold.trim().length === 0){

        found_error = true;
        break

      }

      if(act_accounts[i].pixel_id == null || act_accounts[i].pixel_id.trim().length === 0){

        found_error = true;
        break

      }else{
        act_accounts[i].pixel_id = act_accounts[i].pixel_id.trim()
      }

      if(act_accounts[i].conversion_domain == null || act_accounts[i].conversion_domain.trim().length === 0){

        found_error = true;
        break

      }else{
        act_accounts[i].conversion_domain = act_accounts[i].conversion_domain.trim()
      }

      const temp_intervals = []

      if(act_accounts[i].selected_intervals){

        act_accounts[i].selected_intervals.forEach((selected_interval) => {

          temp_intervals.push(`${selected_interval.getHours().toString().padStart(2, '0')}:${selected_interval.getMinutes().toString().padStart(2, '0')}`)

        })

      }


      act_accounts[i].selected_intervals = temp_intervals

      if(!act_accounts[i].intervals_enabled){

        act_accounts[i].intervals_enabled = false

      }


      if(start_date) {

        if(isNaN(start_date.getHours()) || isNaN(start_date.getMinutes())){

          found_error = true
          break

        }else{

          start_date = start_date.getHours().toString() + ":" + start_date.getMinutes().toString()
          act_accounts[i].night_start = start_date;

        }

      }

      end_date = act_accounts[i].night_end

      
      if(end_date) {

        if(isNaN(end_date.getHours()) || isNaN(end_date.getMinutes())){

          found_error = true
          break

        }else{

          end_date = end_date.getHours().toString() + ":" + end_date.getMinutes().toString()
          act_accounts[i].night_end = end_date;

        }

      }

      if((act_accounts[i].night_start !== null && act_accounts[i].night_end === null) || (act_accounts[i].night_end !== null && act_accounts[i].start === null)){

        found_error = true

      }

      if(act_accounts[i].time_zone ==  null){
        found_error = true
        //act_accounts[i].time_zone = {'value':Intl.DateTimeFormat().resolvedOptions().timeZone}
      }

      if(act_accounts[i].time_interval){
        delete act_accounts[i].time_interval
      }

    }


    if(found_error){
      cmsContext.setSnackBarOpen(true, "error", "Please enter valid values for all fields!")
      return
    }

    request_body.rules.verbose = is_verbose

    request_body.rules.active_accounts = act_accounts;

    request_body.rules.excluded_campaigns = exc_campaigns;

    }else{
      request_body.verbose = is_verbose
    }

    
    console.log('request body: ', request_body)

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      console.log("auth creds were: ", auth_creds)
      
      if(!has_marketing_write_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
      }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/facebook/configurations', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization': auth_creds.jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was: ', result)

              if(result.message){

                if(type === "rules"){

                console.log('yes type rules')

                cmsContext.setSnackBarOpen(true, "success", result.message)

                get_configurations_settings()

                }else{

                  console.log('no only verbose')

                  set_verbose_logging(is_verbose)
                }
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }


};


export default FacebookConfigs;