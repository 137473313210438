import React, { useState, useRef, useEffect, useContext, useCallback } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../../store/cms-context';

import { getAccessJwtToken } from '../../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { default_mintsoft_order_split_target_dialog } from "../../../Utilities/Metrics";

import cloneDeep from 'lodash/cloneDeep';

import MintsoftOrderSplitDialog from "./SubComponents/MintsoftOrderSplitDialog";
import MintsoftOrderSplitsTable from "./SubComponents/MintsoftOrderSplitsTable";
import CurrenciesInfo from "../../../Utilities/CurrenciesInfo";
import { has_admin_access } from "../../../Utilities/HelperFunctions";




function MintsoftOrderSplits(props) {

  console.log("Mintsoft Order Splits rendered")

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_mintsoft_clients, set_all_mintsoft_clients] = useState([]);

  const [all_mintsoft_order_split_targets, set_all_mintsoft_order_split_targets] = React.useState([]);

  const [mintsoft_order_split_target_dialog_state, set_mintsoft_order_split_target_dialog_state] = React.useState(cloneDeep(default_mintsoft_order_split_target_dialog));


  useEffect(() => {

    isMountedRef.current = true

    get_all_mintsoft_order_split_targets()

    return () => isMountedRef.current = false;

  }, [])


 

  function show_create_mintsoft_order_split_target_dialog(){
    
    const temp_dialog_state = cloneDeep(default_mintsoft_order_split_target_dialog);

    temp_dialog_state.is_dialog_open = true

    temp_dialog_state.type = "Create"

    set_mintsoft_order_split_target_dialog_state(temp_dialog_state);

  }


  const delete_mintsoft_order_split_targets = useCallback(() => {
    
    console.log('delete mintsoft_order_split_targets')

    var temp_mintsoft_order_split_targets = [...all_mintsoft_order_split_targets].filter((mintsoft_order_split) => mintsoft_order_split.is_checked);

    console.log('filtered were', temp_mintsoft_order_split_targets)
    
    if(temp_mintsoft_order_split_targets.length === 0){

      cmsContext.setSnackBarOpen(true, "error", "Please select valid mintsoft order split targets!")
      return;

    }


    temp_mintsoft_order_split_targets = temp_mintsoft_order_split_targets.map((mintsoft_order_split) => {

      return(mintsoft_order_split.id)

    })

    console.log('final mintsoft order splits were', temp_mintsoft_order_split_targets)

    const request_body = {}

    request_body.mintsoft_order_split_target = temp_mintsoft_order_split_targets

    request_body.request_type = "Delete"

    request_body.account_email = cmsContext.current_shop.email

    console.log('delete body was: ', request_body)
    

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds) => {

       if(!has_admin_access(auth_creds.current_user)){
          cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
          setIsLoading(false)
          return
        }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/mintsoft/orders/splits/targets', {
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':auth_creds.jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was: ', result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_mintsoft_order_split_targets()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {

                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)

                }
          );
  
      });

  }, [all_mintsoft_order_split_targets]);


  const set_dialog_mintsoft_order_split_target = useCallback((mintsoft_order_split_target, is_open) => {

      const temp_mintsoft_order_split_target_dialog_state = cloneDeep(default_mintsoft_order_split_target_dialog)

      temp_mintsoft_order_split_target_dialog_state.mintsoft_order_split_target.id = mintsoft_order_split_target.id

      temp_mintsoft_order_split_target_dialog_state.mintsoft_order_split_target.selected_client = mintsoft_order_split_target.client

      temp_mintsoft_order_split_target_dialog_state.mintsoft_order_split_target.selected_currency = CurrenciesInfo[mintsoft_order_split_target.currency]

      temp_mintsoft_order_split_target_dialog_state.mintsoft_order_split_target.threshold = mintsoft_order_split_target.threshold

      temp_mintsoft_order_split_target_dialog_state.mintsoft_order_split_target.omitted_tags = mintsoft_order_split_target.omitted_tags

      temp_mintsoft_order_split_target_dialog_state.is_dialog_open = is_open

      temp_mintsoft_order_split_target_dialog_state.type = "Update"

      console.log("new state was: ", temp_mintsoft_order_split_target_dialog_state)

      set_mintsoft_order_split_target_dialog_state(temp_mintsoft_order_split_target_dialog_state)

  }, [])

  const set_dialog_loading = useCallback(( is_loading) => {

    const temp_mintsoft_order_split_dialog_state = {...mintsoft_order_split_target_dialog_state}

    temp_mintsoft_order_split_dialog_state.isLoading = is_loading

    console.log('dialog state was: ', temp_mintsoft_order_split_dialog_state)

    set_mintsoft_order_split_target_dialog_state(temp_mintsoft_order_split_dialog_state)

  },[mintsoft_order_split_target_dialog_state])


  const reset_dialog_state = useCallback((is_loading) => {

    set_mintsoft_order_split_target_dialog_state(cloneDeep(default_mintsoft_order_split_target_dialog))

  },[])





  const post_mintsoft_order_split_target = useCallback((mintsoft_order_split_target, request_type) => {

    set_dialog_loading(true)

    console.log('updating')

    var request_body = {}

    request_body.mintsoft_order_split_target = mintsoft_order_split_target

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    request_body.omitted_tags = mintsoft_order_split_target.omitted_tags

    console.log('body was: ', request_body)

    getAccessJwtToken().then((auth_creds) => {

      if(!has_admin_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        set_dialog_loading(false)
        return
      }


      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/mintsoft/orders/splits/targets', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was: ', result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                reset_dialog_state()
                
                get_all_mintsoft_order_split_targets()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_dialog_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
                set_dialog_loading(false)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }, [reset_dialog_state, set_dialog_loading, cmsContext]);







  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px", overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Mintsoft Order Split Targets</Typography>

          <div>

          <Button variant="contained" onClick = {() => {show_create_mintsoft_order_split_target_dialog()}}>Create Target</Button>

          </div>

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >


          {all_mintsoft_order_split_targets ? <MintsoftOrderSplitsTable delete_mintsoft_order_split_targets={delete_mintsoft_order_split_targets} set_dialog_mintsoft_order_split_target={set_dialog_mintsoft_order_split_target} all_mintsoft_order_split_targets={all_mintsoft_order_split_targets} set_all_mintsoft_order_split_targets={set_all_mintsoft_order_split_targets}>

          </MintsoftOrderSplitsTable>  : null}

        </Grid>

      </Grid>

    </Container>      

    </Box>

     }

     {mintsoft_order_split_target_dialog_state ? <MintsoftOrderSplitDialog all_mintsoft_clients={all_mintsoft_clients} post_mintsoft_order_split_target={post_mintsoft_order_split_target} mintsoft_order_split_target_dialog_state={mintsoft_order_split_target_dialog_state} set_mintsoft_order_split_target_dialog_state={set_mintsoft_order_split_target_dialog_state}></MintsoftOrderSplitDialog> : null}

    </> 


  );


  function get_all_mintsoft_order_split_targets() {
    
    console.log('store_access_token')

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/mintsoft/orders/splits/targets',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
  
              setIsLoading(false)
  
              console.log('result was',result)

             
              if(result.data) {

                if(result.data.targets.length === 0) {

                  cmsContext.setSnackBarOpen(true, "info", "No targets yet. Let's create one to get started.")

                  set_all_mintsoft_order_split_targets([])

                  set_all_mintsoft_clients(result.data.clients)

                  return

                }

                const all_mintsoft_order_splits = []

                var temp_mintsoft_order_split_target;

                result.data.targets.forEach((mintsoft_order_split) => {

                    temp_mintsoft_order_split_target = cloneDeep(mintsoft_order_split)
                    temp_mintsoft_order_split_target.is_checked = false
                    all_mintsoft_order_splits.push(temp_mintsoft_order_split_target)

                })

                set_all_mintsoft_clients(result.data.clients)

                set_all_mintsoft_order_split_targets(all_mintsoft_order_splits)

              }else if(result.errorMessage){
                
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
              
            },
        
            (error) => {

              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)

            }
           
      )

    })


  }

};

export default MintsoftOrderSplits;