import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Container, Grid, Typography, Stack, Button, CircularProgress } from '@mui/material';


import CmsContext from '../../../store/cms-context';

import { getAccessJwtToken } from '../../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import cloneDeep from 'lodash/cloneDeep';

import RedditAdGroupsListTable from "./RedditAdGroupsListTable";

import RedditAdGroupDialog from "../RedditAdGroupDialog/RedditAdGroupDialog";


import { default_reddit_ad_group_dialog as default_ad_group_dialog } from "../../../Utilities/Metrics";

import { default_reddit_adset_dialog as default_adset_dialog } from "../../../Utilities/Metrics";

import { default_reddit_campaign_dialog as default_campaign_dialog } from "../../../Utilities/Metrics";

import CampaignsDialog from "./SubComponents/CampaignsDialog";

import AdsetDialog from "./SubComponents/AdsetDialog";

import dayjs from 'dayjs';
import { has_marketing_write_access } from "../../../Utilities/HelperFunctions";


const default_spend = 25;

function RedditAdGroups(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_reddit_ad_groups, set_all_reddit_ad_groups] = React.useState([]);

  const [reddit_dialog_state, set_reddit_dialog_state] = React.useState({reddit: {}, is_dialog_open: false, is_dialog_loading: false});

  const [reddit_ad_group_dialog_state, set_reddit_ad_group_dialog_state] = React.useState(cloneDeep(default_ad_group_dialog));

  const [campaign_dialog_state, set_campaign_dialog_state] = React.useState(cloneDeep(default_campaign_dialog));

  const [adset_dialog_state, set_adset_dialog_state] = React.useState(cloneDeep(default_adset_dialog));

  const [next_token, set_next_token] = React.useState({});

  const [timestampOrder, setTimestampOrder] = React.useState('descending');

  const [hasMore, setHasMore] = React.useState(false);

  const [calendar_open, set_calendar_open] = React.useState(false);

  const [time_range, set_time_range] = React.useState('');

  const [ranges_button_text, set_ranges_button_text] = React.useState('Time range');

  const [QueryExecutionId, setQueryExecutionId] = React.useState('');
  
  const [selected_country, set_selected_country] = React.useState('All Countries');

  const [filter_spend, set_filter_spend] = React.useState(default_spend);

  const [filter_title, set_filter_title] = React.useState('');

  const [filter_headline, set_filter_headline] = React.useState('');

  const [filter_description, set_filter_description] = React.useState('');

  const navigate = useNavigate();

  useEffect(() => {

    console.log("selected country was: ", selected_country)

  }, [selected_country])

  useEffect(() => {

    isMountedRef.current = true

    get_all_reddit_ad_groups(selected_country, time_range, timestampOrder, QueryExecutionId, next_token, filter_spend, filter_title, filter_headline, filter_description)

    return () => isMountedRef.current = false;

  }, [])



  
  function handle_timestamp_order_change(tOrder) {

    setTimestampOrder(tOrder)

    set_all_reddit_ad_groups([])

    setQueryExecutionId('')

    set_next_token('')
    
    get_all_reddit_ad_groups(selected_country, time_range, tOrder, '', '', '', '', '', '', '')

  }


  function show_create_reddit_dialog(){
    
    const temp_dialog_state = cloneDeep({});

    temp_dialog_state.is_dialog_open = true

    set_reddit_dialog_state(temp_dialog_state);

  }


  function onDateChange(ranges) {
    // ranges ...
   // alert("changed check the console log");

   if(ranges.startDate && ranges.endDate){

    set_ranges_button_text(ranges.startDate.toISOString().split('T')[0] + " - " + ranges.endDate.toISOString().split('T')[0])

    set_time_range(ranges.startDate.toISOString().split('T')[0] + "-to-" + ranges.endDate.toISOString().split('T')[0])

   }

    console.log("ranges: ", ranges);

  };

  function clearSearch(){

    set_all_reddit_ad_groups([])

    set_time_range('')
    
    set_selected_country('All Countries')

    set_filter_spend('')

    set_ranges_button_text('Time range')

    setQueryExecutionId('')

    set_next_token('')

    get_all_reddit_ad_groups('', '', '', '', '', '', '', '', '', '')

  }

  function perform_search(){

    set_all_reddit_ad_groups([])

    get_all_reddit_ad_groups(selected_country, time_range, timestampOrder, '', '', filter_spend, filter_title, filter_headline, filter_description)

  }



  function post_ad_group(post_fb_ad_gp_dialog_state, all_reddit_ad_groups, reddit_ad_group_dialog_state) {

    var request_body = {}

    const ad_group_dialog_state = {...post_fb_ad_gp_dialog_state}

    const request_type = ad_group_dialog_state.type

    if(request_type === "Deploy"){

      deploy_ads()
      return

    }

    console.log("ad group dialog state from function was: ", ad_group_dialog_state)

    if(ad_group_dialog_state.adgroup.id.trim().length === 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid ad group name!")
      return

    }

    const ad_ids = []


    all_reddit_ad_groups.forEach((ad) => {
      
      if(ad.is_checked){

        ad_ids.push(ad['ad_id'])

      }

    })

    console.log("ads were: ", ad_ids)



    if(ad_ids.length === 0){

      cmsContext.setSnackBarOpen(true, "error", "Please select valid ads!")
      return

    }

    request_body.ad_group = ad_group_dialog_state.adgroup

    request_body.request_type = request_type

    request_body.ad_ids = ad_ids

    console.log('body was: ', request_body)


    toggle_ad_group_dialog(true, true)

    
    getAccessJwtToken().then((auth_creds) => {

      if(!has_marketing_write_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        toggle_ad_group_dialog(true, false)
        return
      }
      

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/ad_groups', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
          })
          .then(res => res.json())
          .then(
            (result) => {

              if(result.message){

                const temp_ad_groups = [...all_reddit_ad_groups]

                temp_ad_groups.forEach((ad_group) => {
                  ad_group.is_checked = false
                })

                set_all_reddit_ad_groups(temp_ad_groups)
                toggle_ad_group_dialog(false, false)
                cmsContext.setSnackBarOpen(true, "success", result.message)

              }else if(result.errorMessage){

                toggle_ad_group_dialog(true, false)
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)

              }else{

                toggle_ad_group_dialog(true, false)
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error: ', result)

              }
  
            },
        
            (error) => {

              toggle_ad_group_dialog(true, false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was: ', error)

            }
          
      )

    })

  }



  function post_adset() {

    // continue here get adsets / create object

    var request_body = {}

    const temp_state = cloneDeep(adset_dialog_state)

    console.log("adset dialog state from function was: ", temp_state)

    console.log("adset start time was: ", typeof temp_state.adset.start_time)


    if(temp_state.adset.name.trim().length === 0){

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid adset name!")
      return

    }

    if(Object.keys(temp_state.selected_account).length === 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid account!")
      return

    }

    if(Object.keys(temp_state.selected_campaign).length === 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid campaign!")
      return

    }


    var temp_locations = []

    if(temp_state.adset.targeting.geolocations.length > 0) {

      temp_state.adset.targeting.geolocations.forEach((geolocation) => {

        temp_locations.push(geolocation.id)

      })

    }

    console.log("temp location were: ", temp_locations)

    temp_state.adset.targeting.geolocations = temp_locations

    temp_locations = []

    if(temp_state.adset.targeting.excluded_geolocations.length > 0) {

      temp_state.adset.targeting.excluded_geolocations.forEach((geolocation) => {

        temp_locations.push(geolocation.id)

      })

    }

    temp_state.adset.targeting.excluded_geolocations = temp_locations

    var temp_communities = []

    if(temp_state.adset.targeting.communities.length > 0) {

      temp_state.adset.targeting.communities.forEach((community) => {

        temp_communities.push(community.name)

      })

    }

    temp_state.adset.targeting.communities = temp_communities

    temp_communities = []

    if(temp_state.adset.targeting.excluded_communities.length > 0) {

      temp_state.adset.targeting.excluded_communities.forEach((community) => {

        temp_communities.push(community.name)

      })

    }

    temp_state.adset.targeting.excluded_communities = temp_communities


    var temp_interests = []

    if(temp_state.adset.targeting.interests.length > 0) {

      temp_state.adset.targeting.interests.forEach((community) => {

        temp_interests.push(community.id)

      })

    }

    temp_state.adset.targeting.interests = temp_interests

    Object.keys(temp_state.adset).forEach((key) => {
      if(temp_state.adset[key] === 'None') {
        temp_state.adset[key] = null
      }
    })  


    if(temp_state.adset.bid_type){

      var bidValue = parseFloat(temp_state.adset.bid_value.trim());

      if (isNaN(bidValue)) {
          bidValue = -1;
      }

      console.log("bid value was: ", bidValue)

      if(bidValue >= 1 || bidValue < 0) {

        cmsContext.setSnackBarOpen(true, "error", "Bid value must be >= 0 and < 1!")
        return

      }

      if(temp_state.adset.bid_type.trim().length === 0) {

        cmsContext.setSnackBarOpen(true, "error", "Please select a valid bid type!")
        return

      }

    }else{
      temp_state.adset.bid_value = null
    }

    if(temp_state.adset.goal_type){

      var goalValue = parseFloat(temp_state.adset.goal_value.trim());

      if (isNaN(goalValue)) {
          goalValue = -1;
      }

      if(goalValue < 5) {
        cmsContext.setSnackBarOpen(true, "error", "Daily spend goals must be at least $5.00.")
        return
      }


      if(temp_state.adset.goal_type.trim().length === 0) {
        cmsContext.setSnackBarOpen(true, "error", "Please select a valid goal!")
        return
      }

    }else{
      temp_state.adset.goal_value = null
    }

    if(!temp_state.adset.status) {

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid campaign status!")
      return
      
    }

    var start_date = temp_state.adset.start_time

    var startDateObj;

    var endDateObj;

    if (start_date) {
      // Convert to JavaScript Date object if start_date is a dayjs object or a string
      startDateObj = dayjs.isDayjs(start_date) ? start_date.toDate() : new Date(start_date);
    
      if (
        isNaN(startDateObj.getFullYear()) ||
        isNaN(startDateObj.getMonth()) ||
        isNaN(startDateObj.getDate()) ||
        isNaN(startDateObj.getHours()) ||
        isNaN(startDateObj.getMinutes()) ||
        isNaN(startDateObj.getSeconds())
      ) {
        cmsContext.setSnackBarOpen(true, "error", "Please select a valid start date!")
        return
      } else {
        // Format the date and time to YY:MM:DD HH:MM:SS
        const year = startDateObj.getFullYear().toString(); // Get last two digits of the year
        const month = (startDateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = startDateObj.getDate().toString().padStart(2, '0');
        const hours = startDateObj.getHours().toString().padStart(2, '0');
        const minutes = startDateObj.getMinutes().toString().padStart(2, '0');
        const seconds = startDateObj.getSeconds().toString().padStart(2, '0');
    
        start_date = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

        temp_state.adset.start_time = start_date

      }
    }


    var end_date = temp_state.adset.end_time;

    if (end_date) {
      // Convert to JavaScript Date object if end_date is a dayjs object or a string
      endDateObj = dayjs.isDayjs(end_date) ? end_date.toDate() : new Date(end_date);

      if (
        isNaN(endDateObj.getFullYear()) ||
        isNaN(endDateObj.getMonth()) ||
        isNaN(endDateObj.getDate()) ||
        isNaN(endDateObj.getHours()) ||
        isNaN(endDateObj.getMinutes()) ||
        isNaN(endDateObj.getSeconds())
      ) {

        cmsContext.setSnackBarOpen(true, "error", "Please select a valid end date!");
        return;

      } else {

        const year = endDateObj.getFullYear().toString(); // Get last two digits of the year
        const month = (endDateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = endDateObj.getDate().toString().padStart(2, '0');
        const hours = endDateObj.getHours().toString().padStart(2, '0');
        const minutes = endDateObj.getMinutes().toString().padStart(2, '0');
        const seconds = endDateObj.getSeconds().toString().padStart(2, '0');

        end_date = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
        
        temp_state.adset.end_time = end_date

      }
    }

    console.log("start date was: ", start_date)

    console.log("end date was: ", end_date)

    if(startDateObj && endDateObj && endDateObj <= startDateObj) {

      cmsContext.setSnackBarOpen(true, "error", "End date should be greater than start date!")

      return

    }

    if(temp_state.adset.targeting.devices && temp_state.adset.targeting.devices.length > 0){


    temp_state.adset.targeting.devices = [
        {
          label_map: temp_state.adset.targeting.devices.reduce((acc, { make, model }) => {
            if (!acc[make]) acc[make] = [];
            acc[make].push(model);
            return acc;
          }, {})
        }
      ]

    }

    temp_state.adset.targeting.devices[0].os = "ANDROID"

    temp_state.adset.targeting.devices[0].type = "DESKTOP"

    request_body.campaign = temp_state.selected_campaign.id

    request_body.account_id = temp_state.selected_account

    request_body.request_type = temp_state.type

    request_body.adset = temp_state.adset

    request_body.adset.configured_status = temp_state.adset.status

    request_body.adset.optimization_goal = 'REACH'

    delete request_body.adset.status

    console.log('adset body was: ', request_body)

    toggle_adset_dialog(true, true)

    getAccessJwtToken().then((auth_creds) => {

      if(!has_marketing_write_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        toggle_adset_dialog(true, false)
        return
      }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/adsets', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
          })
          .then(res => res.json())
          .then(
            (result) => {

              if(result.adset){

                console.log('result was: ', result)
  
                toggle_adset_dialog(false, false)
                
                cmsContext.setSnackBarOpen(true, "success", "AdSet successfully created!")

                get_adsets(reddit_ad_group_dialog_state.selected_account, reddit_ad_group_dialog_state.selected_campaign.id)

              }else if(result.errorMessage){

                toggle_adset_dialog(true, false)
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)

              }else{

                toggle_adset_dialog(true, false)
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error: ', result)

              }
  
            },
        
            (error) => {

              toggle_adset_dialog(true, false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was: ', error)

            }
          
      )

    })

  };



  function get_adsets(account_id, campaign_id) {

    console.log("reddit ad group dialog state from get adset was: ", reddit_ad_group_dialog_state)

    toggle_ad_group_dialog(true, true)

    getAccessJwtToken().then((auth_creds) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/adsets', {
          crossDomain:true,
          method: 'GET',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt,
            'account_id': account_id,
            'campaign_id': campaign_id
          }
          })
          .then(res => res.json())
          .then(
            (result) => {


              if(result.adsets) {

                console.log('result was: ', result)

                // get campaigns here again

                toggle_ad_group_dialog(true, false)

                set_reddit_ad_group_dialog_state((previous_state) => {

                  var temp_state = {...previous_state}

                  temp_state.adsets = result.adsets
            
                  console.log("new dialog state was: ", temp_state)
                  
                  return set_reddit_ad_group_dialog_state(temp_state)
            
                })

                if(result.adsets.length === 0){

                  cmsContext.setSnackBarOpen(true, "error", "No adsets found for this campaign!")

                }

              }else if(result.errorMessage){

                toggle_ad_group_dialog(true, false)
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)

              }else{

                toggle_ad_group_dialog(true, false)
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error: ', result)

              }
  
            },
        
            (error) => {

              toggle_ad_group_dialog(true, false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was: ', error)

            }
          
      )

    })

  };


  function deploy_ads() {

    const request_body = {}

    const temp_state = {...reddit_ad_group_dialog_state}

    console.log("adgroup dialog state from function was: ", temp_state)


    if(temp_state.adgroup.id.trim().length === 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Ad Group name!")
      return

    }

    if(Object.keys(temp_state.selected_account).length === 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid account!")
      return

    }

    if(Object.keys(temp_state.selected_campaign).length === 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid campaign!")
      return

    }

    if(Object.keys(temp_state.selected_adset).length === 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid adset!")
      return

    }

    
    const sync_task = {}

    sync_task.target_account = temp_state.selected_account

    sync_task.target_campaign = temp_state.selected_campaign.id

    sync_task.selected_target_adset = temp_state.selected_adset.id

    sync_task.selected_source_ads = []

    temp_state.ads.forEach((ad) => {

      sync_task.selected_source_ads.push({'id': ad.ad_id, 'status': 'PAUSED'})

    })

    temp_state.accounts.forEach((account) => {

      if(account.id === temp_state.selected_account){

        sync_task.target_account_name = account.name

      }

    })

    sync_task.target_campaign_name = temp_state.selected_campaign.name

    sync_task.selected_target_adset_name = temp_state.selected_adset.name
    
    request_body.sync_task = sync_task

    request_body.task_type = 'ad_group'

    console.log('body was: ', request_body)
    
    toggle_ad_group_dialog(true, true)
    
    getAccessJwtToken().then((auth_creds) => {

      if(!has_marketing_write_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        toggle_ad_group_dialog(true, false)
        return
      }
      

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/sync_tasks', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
          })
          .then(res => res.json())
          .then(
            (result) => {

              console.log('result was: ', result)

              if(result.message){

                const temp_ad_groups = [...all_reddit_ad_groups]

                temp_ad_groups.forEach((ad_group) => {
                  ad_group.is_checked = false
                })

                set_all_reddit_ad_groups(temp_ad_groups)

                toggle_ad_group_dialog(false, false)
                cmsContext.setSnackBarOpen(true, "success", result.message)

              }else if(result.errorMessage){

                toggle_ad_group_dialog(true, false)
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)

              }else{

                toggle_ad_group_dialog(true, false)
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error: ', result)

              }
  
            },
        
            (error) => {

              toggle_ad_group_dialog(true, false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was: ', error)

            }
          
      )

    })

  }



  function post_campaign() {

    var request_body = {}

    const temp_state = {...campaign_dialog_state}

    console.log("campaign dialog state from function was: ", temp_state)

    if(temp_state.selected_account.trim().length === 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid account!")
      return

    }

    if(temp_state.campaign.name.trim().length === 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid campaign name!")
      return

    }

    if(temp_state.campaign.objective.trim().length === 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid campaign objective!")
      return

    }

    if(temp_state.campaign.funding_instrument.trim().length === 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid funding instrument!")
      return

    }
    
    if(temp_state.campaign.goal_type === 'None'){

      temp_state.campaign.goal_type = ""

    }

    if(temp_state.campaign.view_through_conversion_type === 'None'){

      temp_state.campaign.view_through_conversion_type = ""

    }


    if(temp_state.campaign.goal_type){

      var goalValue = parseInt(temp_state.campaign.goal_value.trim());

      if (isNaN(goalValue)) {
          goalValue = -1;
      }

      if(goalValue < 0) {

        cmsContext.setSnackBarOpen(true, "error", "Please select a valid goal value!")
        return

      }

      if(goalValue < 100){
        cmsContext.setSnackBarOpen(true, "error", "Goal value should be atleast 100!")
        return
      }

      if(temp_state.campaign.goal_type.trim().length === 0) {

        cmsContext.setSnackBarOpen(true, "error", "Please select a valid goal!")
        return

      }

    }

    var spend_cap = parseInt(temp_state.campaign.spend_cap.trim());

    if (isNaN(spend_cap)) {
      spend_cap = -1;
    }

    if(spend_cap < 0) {

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid spend cap!")
      return

    }

    if(spend_cap < 100){
      cmsContext.setSnackBarOpen(true, "error", "Spend cap should be atleast 100!")
      return
    }

    if(!temp_state.campaign.status) {

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid campaign status!")
      return
      
    }

    request_body.campaign = temp_state.campaign

    request_body.account_id = temp_state.selected_account

    request_body.request_type = temp_state.type

    console.log('post campaign body was: ', request_body)

    toggle_campaign_dialog(true, true)
    
    // continue here create campaign

    getAccessJwtToken().then((auth_creds) => {

      if(!has_marketing_write_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        toggle_campaign_dialog(true, false)
        return
      }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/campaigns', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
          })
          .then(res => res.json())
          .then(
            (result) => {

              console.log("result was: ", result)

              if(result.campaign){

                // get campaigns here again

                get_reddit_campaigns(reddit_ad_group_dialog_state.selected_account)

                toggle_campaign_dialog(false, false)

                cmsContext.setSnackBarOpen(true, "success", "Campaign successfully created!")

              }else if(result.errorMessage){

                toggle_campaign_dialog(true, false)
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)

              }else{

                toggle_campaign_dialog(true, false)
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error: ', result)

              }
  
            },
        
            (error) => {

              toggle_campaign_dialog(true, false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was: ', error)

            }
          
      )

    })

  };



  return(

    <>
   

   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
 
  >

    
    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Reddit Ad Groups</Typography>

          {/* <div>

          <ToggleButtons timestampOrder={timestampOrder} handle_timestamp_order_change={handle_timestamp_order_change}></ToggleButtons>

          </div> : null */}

          <Button style={{marginLeft:8}} variant="contained" onClick = {() => {window.open('/reddit/breakdowns','_blank');}}>Create</Button>

        </Stack>

      </Grid>



     {!isLoading && all_reddit_ad_groups && all_reddit_ad_groups.length > 0 ? <Grid item xs={12}>

        <RedditAdGroupsListTable delete_ad_groups={delete_ad_groups} show_adset_dialog={show_adset_dialog} filter_title={filter_title} set_filter_title={set_filter_title} filter_headline={filter_headline} set_filter_headline={set_filter_headline} filter_description={filter_description} set_filter_description={set_filter_description} filter_spend={filter_spend} set_filter_spend={set_filter_spend} selected_country={selected_country} set_selected_country={set_selected_country} clearSearch={clearSearch} ranges_button_text={ranges_button_text} perform_search={perform_search} onDateChange={onDateChange} calendar_open={calendar_open} set_calendar_open={set_calendar_open} timestampOrder={timestampOrder} handle_timestamp_order_change={handle_timestamp_order_change} handle_ad_group_action={handle_ad_group_action} all_reddit_ad_groups={all_reddit_ad_groups} set_all_reddit_ad_groups={set_all_reddit_ad_groups}></RedditAdGroupsListTable>
        
      </Grid> : null}


      <Grid item xs={12}>

      {isLoading ? <div style={{textAlign:'center', marginTop:40}}> <CircularProgress /> </div> : null}

      </Grid>

      <Grid item xs={12}>

      <div style={{width:'100%', textAlign:'center'}}>
      {hasMore && !isLoading ? <Button variant="contained" onClick = {() => {get_all_reddit_ad_groups(selected_country, time_range, timestampOrder, QueryExecutionId, next_token, filter_spend, filter_title, filter_headline, filter_description)}}>Load more</Button> : null}

      </div>
      </Grid>

    </Grid>
        
    </Container>

    <RedditAdGroupDialog get_adsets={get_adsets} adset_dialog_state={adset_dialog_state} initialize_adset_creation={initialize_adset_creation} campaign_dialog_state={campaign_dialog_state} show_campaign_dialog={show_campaign_dialog} get_reddit_campaigns={get_reddit_campaigns} post_ad_group={post_ad_group} reddit_ad_group_dialog_state={reddit_ad_group_dialog_state} set_reddit_ad_group_dialog_state={set_reddit_ad_group_dialog_state}></RedditAdGroupDialog>
    
    {campaign_dialog_state ? <CampaignsDialog post_campaign={post_campaign} campaign_dialog_state={campaign_dialog_state} set_campaign_dialog_state={set_campaign_dialog_state}></CampaignsDialog> : null}
    
    {adset_dialog_state ? <AdsetDialog post_adset={post_adset} adset_dialog_state={adset_dialog_state} set_adset_dialog_state={set_adset_dialog_state}></AdsetDialog> : null}


    </Box>

    </> 


  );



  function handle_ad_group_action(action_type) {

    setIsLoading(true)
    
    var checked_id = -1;

    const temp_ad_groups = [...all_reddit_ad_groups]

    temp_ad_groups.forEach((ad_group) => {

      if(ad_group.is_checked){

        checked_id = ad_group.id

      }

    })

    if(checked_id < 0){

      cmsContext.setSnackBarOpen(true, "error", "Please select a valid ad group!")
      return

    }

    
    getAccessJwtToken().then((auth_creds) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/ad_groups/details', {
          crossDomain: true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt,
            'ad_group_id': checked_id
          }})
          .then(res => res.json())
          .then(
            (result) => {

             console.log("reddit ad group details result was: ", result)

             setIsLoading(false)

             if(result.ad_group) {

              result.ads.forEach((ad, index) => {

                ad.is_checked = false

                if(ad.account_id && ad.creative_id && ad.image_hash){

                  get_picture_async(ad.account_id, ad.image_hash, ad.creative_id, ad.ad_id, index)

                }

              })

              show_ad_group_dialog(action_type, result)

              if(action_type === "Deploy"){

                get_reddit_accounts_data(action_type)
          
                return
          
              }

             }
  
            },
        
            (error) => {
              
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please try again!")
              console.log('error was',error)
              
            }

      )

    })

  }

  function initialize_adset_creation(type) {

    toggle_ad_group_dialog(true, true)

    getAccessJwtToken().then((auth_creds) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/adsets/configs', {
          crossDomain: true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {

             console.log("initialize adset configuration result was: ", result) 

             toggle_ad_group_dialog(true, false)

             if(result.data) {

              show_adset_dialog(type, result.data)

             }else if(result.errorMessage) {

              cmsContext.setSnackBarOpen(true, "error", result.errorMessage)

             }else {

              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please try again!")

             }
  
            },
        
            (error) => {

              toggle_ad_group_dialog(true, false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please try again!")
              console.log('error was',error)
              
            }
           
      )

    })

  }
  

  function toggle_dialog(is_open, isLoading, reddit) {

    const dialog_state = {}

    dialog_state.is_dialog_open = is_open

    dialog_state.is_dialog_loading = isLoading

    dialog_state.reddit = cloneDeep(reddit)

    set_reddit_dialog_state(dialog_state)

  }


  async function get_picture_async(account_id, image_hash, creative_id, ad_id, index){

    console.log('getting picture: ', account_id, " : image has : ", image_hash, index)

    getAccessJwtToken().then((auth_creds)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/images', {
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization': auth_creds.jwt,
            'source_account_id': account_id,
            'image_hash': image_hash,
            'creative_id': creative_id
          }})
          .then(res => res.json())
          .then(
            (result) => {


              //console.log('temp options', temp_options,'num: ', optionNum)
          
              set_reddit_ad_group_dialog_state((previous_state) => {
          
                  const temp_state = cloneDeep(previous_state)
          
                  // console.log('temp options', temp_options, 'num: ', optionNum)
          
                  const index = temp_state.ads.findIndex(object => {
          
                    return object.ad_id === ad_id;
          
                  });
          
                  // console.log('found index was: ', index)
          
                  temp_state.ads[index].url = result.url;
          
                  return(set_reddit_ad_group_dialog_state(temp_state))
          
              })
          



            },
        
            (error) => {
              console.log("Error")
            }
           
      )

    })


  }


  function get_all_reddit_ad_groups(selected_country, time_range, timestampOrder, QueryExecutionId , next_token, filter_spend, filter_title, filter_headline, filter_description) {

    console.log('executing get all ad groups')
    
    console.log('store_access_token')

    setIsLoading(true)

    console.log("filter spend: ", filter_spend)

    console.log("order was: ", timestampOrder, timestampOrder === 'ascending')

    getAccessJwtToken().then((auth_creds) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/ad_groups', {
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
              setIsLoading(false)

              if(result.ad_groups){

                console.log('setting analytics')

                result.ad_groups.forEach((ad_group) =>{

                  ad_group.is_checked = false
                  
                })

                if(result.ad_groups.length === 0){

                  setHasMore(false)
                  cmsContext.setSnackBarOpen(true, "info", "No ad groups yet. Please create one to ge started.")

                }

                var temp_ad_groups = [...all_reddit_ad_groups]

                temp_ad_groups = [...result.ad_groups]

                console.log('setting all', temp_ad_groups)

                set_all_reddit_ad_groups(temp_ad_groups)

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
           
      )

    })

  }

  function get_reddit_accounts_data(action_type){

    console.log('store_access_token')

    toggle_ad_group_dialog(true, true)

    getAccessJwtToken().then((auth_creds) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/configs', {
          crossDomain: true,
          method: 'GET',
          headers: {
            'Authorization': auth_creds.jwt,
            'configs_only': 'true'
          }})
          .then(res => res.json())
          .then(
            (result) => {

              console.log('result was: ', result)

              if(result.accounts){
                
                  set_reddit_ad_group_dialog_state((previous_state) => {

                  const temp_state = {...previous_state}

                  temp_state.accounts = result.accounts.active_accounts

                  temp_state.is_dialog_loading = false

                  temp_state.type = action_type

                  temp_state.is_dialog_open = true

                  return set_reddit_ad_group_dialog_state(temp_state)
            
                })

              }else{

                toggle_ad_group_dialog(true, false)
                
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload!")

              }
  
            },
        
            (error) => {

              toggle_ad_group_dialog(true, false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload!")
              console.log('error was',error)

            }
           
      )

    })


  }



  function get_reddit_campaigns(selected_account) {

    toggle_ad_group_dialog(true, true)

    getAccessJwtToken().then((auth_creds) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/campaigns', {
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt,
            'account_id': selected_account
          }})
          .then(res => res.json())
          .then(
            (result) => {

              console.log("get campaign result was result was: ", result)

              if(result.campaigns){

                set_reddit_ad_group_dialog_state((previous_state) => {

                  if(result.campaigns.length > 0){

                    const temp_state = {...previous_state}

                    console.log('setting analytics')
  
                    result.campaigns.forEach((campaign) => {
  
                      campaign.is_checked = false
                      
                    })

                    temp_state.selected_account = selected_account
  
                    temp_state.is_dialog_loading = false
  
                    temp_state.campaigns = result.campaigns

                    temp_state.funding_instruments = result.funding_instruments
  
                    return set_reddit_ad_group_dialog_state(temp_state)
  

                  }else{

                     
                    cmsContext.setSnackBarOpen(true, "info", "No campaigns found for this account!")
                    const temp_state = {...previous_state}
                    temp_state.campaigns = []
                    temp_state.selected_campaign = ''
                    temp_state.is_dialog_loading = false
                    return set_reddit_ad_group_dialog_state(temp_state)

                  }


                })


              

                // continue here (properly handle tokens and no campaigns if not found / loading state)

                /*

                temp_state.query_execution_id = result.query_execution_id

                if(result.NextToken){

                  temp_state.next_token = result.next_token
                  
                }else{

                  temp_state.next_token = null

                }

                */


              }else if(result.errorMessage){

                toggle_ad_group_dialog(true, false)
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{

                toggle_ad_group_dialog(true, false)
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {

              toggle_ad_group_dialog(true, false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)

            }
           
      )

    })

  }



  function toggle_ad_group_dialog(is_open, is_loading) {

    set_reddit_ad_group_dialog_state((previous_state) => {

      var temp_state = {...previous_state}

      temp_state.is_dialog_open = is_open

      temp_state.is_dialog_loading = is_loading

      console.log("new dialog state was: ", temp_state)

      return set_reddit_ad_group_dialog_state(temp_state)

    })

  }

  function toggle_campaign_dialog(is_open, is_loading) {

    set_campaign_dialog_state((previous_state) => {

      var temp_state = {...previous_state}

      temp_state.is_dialog_open = is_open

      temp_state.is_dialog_loading = is_loading

      console.log("new dialog state was: ", temp_state)

      return set_campaign_dialog_state(temp_state)

    })

  }

  function toggle_adset_dialog(is_open, is_loading) {

    set_adset_dialog_state((previous_state) => {

      var temp_state = {...previous_state}

      temp_state.is_dialog_open = is_open

      temp_state.is_dialog_loading = is_loading

      console.log("new dialog state was: ", temp_state)

      return set_adset_dialog_state(temp_state)

    })

  }

  function show_ad_group_dialog(type, result) {

      const temp_state = cloneDeep(default_ad_group_dialog)

      temp_state.is_dialog_open = true

      temp_state.adgroup = result.ad_group

      temp_state.type = type

      temp_state.ads = result.ads

      set_reddit_ad_group_dialog_state(temp_state)

  }

  function show_campaign_dialog(type) {

    const temp_state = cloneDeep(default_campaign_dialog)

    temp_state.is_dialog_open = true

    temp_state.accounts = reddit_ad_group_dialog_state.accounts

    temp_state.selected_account = reddit_ad_group_dialog_state.selected_account

    temp_state.all_funding_instruments = reddit_ad_group_dialog_state.funding_instruments

    temp_state.type = type

    set_campaign_dialog_state(temp_state)

  }

  function show_adset_dialog(type, targeting_options) {


    const temp_state = cloneDeep(default_adset_dialog)

    temp_state.is_dialog_open = true

    temp_state.accounts = reddit_ad_group_dialog_state.accounts

    temp_state.selected_account = reddit_ad_group_dialog_state.selected_account

    temp_state.campaigns = reddit_ad_group_dialog_state.campaigns

    temp_state.selected_campaign = reddit_ad_group_dialog_state.selected_campaign

    temp_state.targeting_options.targeting_communities = targeting_options.targeting_communities

    temp_state.targeting_options.targeting_devices = targeting_options.targeting_devices

    temp_state.targeting_options.targeting_locations = targeting_options.targeting_locations

    temp_state.targeting_options.targeting_interests = targeting_options.targeting_interests
    
    temp_state.type = type

    set_adset_dialog_state(temp_state)

  }


  function set_dialog_loading(is_loading) {  

    const temp_reddit_state = {...reddit_dialog_state}

    temp_reddit_state.isLoading = is_loading

    set_reddit_dialog_state(temp_reddit_state)

  }

  function reset_dialog_state(){

    set_reddit_dialog_state(cloneDeep({}))

  }

  function post_reddit(reddit, request_type) {

    console.log('updating')

    var request_body = {}

    request_body.reddit = reddit

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    set_dialog_loading(true)

    getAccessJwtToken().then((auth_creds)=>{

      if(!has_marketing_write_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        set_dialog_loading(false)
        return
      }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/ad_groups', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {

              set_dialog_loading(false)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }

  function delete_ad_groups(){

    console.log('delete ad_groups')

    var temp_ad_groups = [...all_reddit_ad_groups].filter((reddit) => reddit.is_checked);

    console.log('filtered were', temp_ad_groups)

    if(temp_ad_groups.length === 0){

      cmsContext.setSnackBarOpen(true, "error", "Please select valid ad_groups!")
      return;

    }else if(temp_ad_groups.length > 20){

      cmsContext.setSnackBarOpen(true, "error", "Please select a maximum of 20 ad_groups!")
      return;

    }

    temp_ad_groups = temp_ad_groups.map((reddit) => {

      return(reddit.id)

    })

    console.log('final ad_groups were', temp_ad_groups)

    const request_body = {}

    request_body.ad_group = temp_ad_groups

    request_body.request_type = "Delete"

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      if(!has_marketing_write_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
      }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/ad_groups', {
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':auth_creds.jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {

                  console.log("result was: ", result)

                  setIsLoading(false)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_reddit_ad_groups()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }

                  
                },
            
                (error) => {
                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error.toString())
                  console.log('error was',error)
                }
          );
  
      });

  }


};


export default RedditAdGroups;