import React, { useState, useRef, useEffect, useContext, useCallback } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl, IconButton } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../../store/cms-context';

import { getAccessJwtToken } from '../../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import BringItBackDialog from "./SubComponents/BringItBackDialog";

import BringItBackListTable from "./SubComponents/BringItBackListTable";

import CheckIcon from '@mui/icons-material/Check';

import cloneDeep from 'lodash/cloneDeep';

import { default_bring_it_back_goal_dialog } from "../../../Utilities/Metrics";
import { has_product_management_write_access } from "../../../Utilities/HelperFunctions";

function BringItBack(props) {

  console.log("Bring it back goal rendered")

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_bring_it_back_goals, set_all_bring_it_back_goals] = React.useState([]);

  const [active_accounts, set_active_accounts] = React.useState([]);

  const [email_time, set_email_time] = React.useState('');

  const [bring_it_back_goal_dialog_state, set_bring_it_back_goal_dialog_state] = React.useState(cloneDeep(default_bring_it_back_goal_dialog));

  const [default_target, set_default_target] = React.useState('')


  useEffect(() => {

    isMountedRef.current = true

    get_all_bring_it_back_goals()

    return () => isMountedRef.current = false;

  }, [])


 
  function handle_active_accounts_change(event, newValue){

    set_active_accounts(newValue)

  }

  function show_create_bring_it_back_goal_dialog() {
    
    const temp_dialog_state = cloneDeep(default_bring_it_back_goal_dialog);

    temp_dialog_state.is_dialog_open = true

    temp_dialog_state.type = "Create"

    set_bring_it_back_goal_dialog_state(temp_dialog_state);

  }

  const delete_bring_it_back_goals = useCallback(() => {
    
    console.log('delete bring_it_back_goals')

    var temp_bring_it_back_goals = [...all_bring_it_back_goals].filter((bring_it_back_goal) => bring_it_back_goal.is_checked);

    console.log('filtered were', temp_bring_it_back_goals)

    if(temp_bring_it_back_goals.length === 0){
      // might never execute (need to select bring_it_back_goals to display delete button)
      cmsContext.setSnackBarOpen(true, "error", "Please select valid targets!")
      return;
    }

    temp_bring_it_back_goals = temp_bring_it_back_goals.map((bring_it_back_goal) => {

      return(bring_it_back_goal.id)

    })

    console.log('final bring_it_back_goals were: ', temp_bring_it_back_goals)

    const request_body = {}

    request_body.bring_it_back_goal = temp_bring_it_back_goals

    request_body.request_type = "Delete"

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)
    
    setIsLoading(true)

    getAccessJwtToken().then((auth_creds) => {

      if(!has_product_management_write_access(auth_creds.current_user)){
          cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
          setIsLoading(false)
          return
      }
      

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/bring_it_back/goals', {
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':auth_creds.jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was: ', result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_bring_it_back_goals()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {

                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)

                }
          );
  
      });

  }, [all_bring_it_back_goals]);


  const handle_product_reset = useCallback((index) => {
    
    console.log('reset bring it back goal')

    var temp_goal = [...all_bring_it_back_goals][index]

    const request_body = {}

    request_body.bring_it_back_goal = temp_goal

    request_body.request_type = "reset_count"
    
    setIsLoading(true)

    getAccessJwtToken().then((auth_creds) => {

      if(!has_product_management_write_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
    }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/bring_it_back/goals', {
              crossDomain:true,
              method: 'post',
              headers: {
                'content-type':'application/json',
                'Authorization':auth_creds.jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was: ', result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_bring_it_back_goals()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {

                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)

                }
          );
  
      });

  }, [all_bring_it_back_goals]);


  const set_dialog_bring_it_back_goal = useCallback((bring_it_back_goal, is_open) => {

      const temp_bring_it_back_goal_state = cloneDeep(default_bring_it_back_goal_dialog)

      temp_bring_it_back_goal_state.bring_it_back_goal.product = bring_it_back_goal.product

      temp_bring_it_back_goal_state.bring_it_back_goal.quantity = bring_it_back_goal.quantity

      temp_bring_it_back_goal_state.bring_it_back_goal.id = bring_it_back_goal.id

      temp_bring_it_back_goal_state.is_dialog_open = is_open

      temp_bring_it_back_goal_state.type = "Update"

      console.log("new state was: ", temp_bring_it_back_goal_state)

      set_bring_it_back_goal_dialog_state(temp_bring_it_back_goal_state)

  }, [])



  const set_dialog_loading = useCallback(( is_loading) => {

    const temp_bring_it_back_goal_state = {...bring_it_back_goal_dialog_state}

    temp_bring_it_back_goal_state.isLoading = is_loading

    console.log('dialog state was: ', temp_bring_it_back_goal_state)

    set_bring_it_back_goal_dialog_state(temp_bring_it_back_goal_state)

  },[bring_it_back_goal_dialog_state])



  const reset_dialog_state = useCallback((is_loading) => {

    set_bring_it_back_goal_dialog_state(cloneDeep(default_bring_it_back_goal_dialog))

  },[])



  const post_bring_it_back_goal = useCallback((bring_it_back_goal, request_type) => {

    set_dialog_loading(true)

    console.log('updating')

    var request_body = {}

    request_body.bring_it_back_goal = bring_it_back_goal

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    getAccessJwtToken().then((auth_creds) => {

      if(!has_product_management_write_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
    }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/bring_it_back/goals', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                reset_dialog_state()
                
                get_all_bring_it_back_goals()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_dialog_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
                set_dialog_loading(false)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }, [reset_dialog_state, set_dialog_loading, cmsContext]);



  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >

    <Container maxWidth={false} style={{maxWidth:"1000px", overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Targets</Typography>

          <div>

          <TextField sx={{marginRight:1}} 
          value={default_target}
          onChange={(event)=>{set_default_target(event.target.value)}}
          InputProps={{ endAdornment: (<InputAdornment position="end"> 
          <IconButton onClick={() => {handle_default_target_update()}}> 
         <img alt="confirm_default_target" style={{
                        height: 36,
                        width: 36,
                    }} src="/static/images/check.png"></img>
          </IconButton>
          </InputAdornment>
          ),
          }} 
          type="number" id="default_quantity" size="small" InputLabelProps={{shrink:true}} label="Default Target" variant="outlined" />

          <Button variant="contained" onClick = {() => {show_create_bring_it_back_goal_dialog()}}>Create Target</Button>

          </div>

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >


         {bring_it_back_goal_dialog_state ? <BringItBackListTable handle_product_reset={handle_product_reset} delete_bring_it_back_goals={delete_bring_it_back_goals} set_dialog_bring_it_back_goal={set_dialog_bring_it_back_goal} all_bring_it_back_goals={all_bring_it_back_goals} set_all_bring_it_back_goals={set_all_bring_it_back_goals}>

          </BringItBackListTable>  : null}

        </Grid>

      </Grid>

    </Container>      

    </Box>

     }

     {bring_it_back_goal_dialog_state ? <BringItBackDialog post_bring_it_back_goal={post_bring_it_back_goal} bring_it_back_goal_dialog_state={bring_it_back_goal_dialog_state} set_bring_it_back_goal_dialog_state={set_bring_it_back_goal_dialog_state}></BringItBackDialog> : null}

    </> 

  );


  function handle_default_target_update(){

    console.log("handle default clicked")

    set_dialog_loading(true)

    console.log('updating')

    var request_body = {}

    var temp_target = default_target

    temp_target = temp_target.toString().trim()

    temp_target = parseInt(temp_target) || -1;

    if(temp_target < 0){

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid quantity!")
      return

    }

    request_body.request_type = "Update"

    request_body.configuration = {}

    request_body.configuration.id = 'bring_it_back_default_target'

    request_body.configuration.quantity = temp_target

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    getAccessJwtToken().then((auth_creds) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/configurations', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },  
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                reset_dialog_state()
                
                get_all_bring_it_back_goals()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_dialog_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
                set_dialog_loading(false)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })



  }



  function get_all_bring_it_back_goals() {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/bring_it_back/goals', {
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization': auth_creds.jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
              setIsLoading(false)
  
              console.log('result was',result)
             
              if(result.data) {

                set_default_target(result.data.default_target)

                if(result.data.all_goals.length === 0){

                  cmsContext.setSnackBarOpen(true, "info", "No goals yet. Let's create one to get started.")

                  set_all_bring_it_back_goals([])

                  return

                }

                const all_bring_it_back_goals = []

                var temp_bring_it_back_goal;

                result.data.all_goals.forEach((bring_it_back_goal) => {

                    temp_bring_it_back_goal = cloneDeep(bring_it_back_goal)
                    temp_bring_it_back_goal.is_checked = false
                    all_bring_it_back_goals.push(temp_bring_it_back_goal)

                })
                
                console.log('all options')

                set_all_bring_it_back_goals(all_bring_it_back_goals)

              } else if(result.errorMessage) {
                
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else {

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
              
              
            },

            (error) => {

              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)

            }
           
      )

    })

  }



};

export default BringItBack;