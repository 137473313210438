import * as React from 'react';
import { useState , useContext } from 'react';
import { Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { DashboardSidebar } from '../DashboardSideBar/DashboardSideBar';
import Dashboard from '../Dashboard/Dashboard';
import { DashboardNavbar } from '../DashboardNavBar/DashboardNavBar';
import CmsContext from '../../store/cms-context';
import CollectionEncapsulation from '../CreateCollection/CollectionEncapsulation'
import ListCollections from '../ListCollections/ListCollections'
import FacebookAds from '../FacebookAds/FacebookAds';
import AnalyticsCMS from '../AnalyticsCMS/AnalyticsCMS';
import Instagram from '../Instagram/Instagram';
import FacebookTags from '../FacebookTags/FacebookTags'
import GoogleAds from '../GoogleAds/GoogleAds';
import FacebookSync from '../FacebookSync/FacebookSync';
import GoogleSearch from '../GoogleSearch/GoogleSearch';
import GoogleAdsManageSearch from '../GoogleAdsManageSearch/GoogleAdsManageSearch';
import InstagramShopify from '../InstagramShopify/InstagramShopify';
import ScheduledProducts from '../ScheduledProducts/ScheduledProducts';
import StorageImages from '../StorageImages/StorageImages';
import Shipping from '../Shipping/Shipping';
import ShippingReports from '../ShippingReports/ShippingReports';
import FacebookConfigs from '../FacebookConfigs/FacebookConfigs';
import InventoryReports from '../InventoryReports/InventoryReports';
import PreOrderGoals from '../Shopify/Preorder/PreOrderGoals';
import FacebookCountryBreakDowns from '../FacebookCountryBreakdown/FacebookCountryBreakdowns';
import FacebookAdGroups from '../FacebookAdGroups/FacebookAdGroups';
import BringItBack from '../Shopify/BringItBack/BringItBack';
import BringItBackConfigs from '../Shopify/BringItBack/BringItBackConfigs';
import ProductsSync from '../Shopify/Products/Sync/ProductsSync';
import ProductDiscounts from '../Shopify/Products/Discounts/ProductDiscounts';
import ReviewsAndComments from '../ReviewsAndComments/ReviewsAndComments';
import MintsoftOrderSplits from '../Warehouse/Mintsoft/MintsoftOrderSplits';
import PostPurchase from '../Shopify/Checkouts/PostPurchase/PostPurchase';
import ConversionRates from '../Shopify/Conversion/Rates/ConversionRates';
import MintsoftOrdersSync from '../Warehouse/Orders/Sync/MintsoftOrdersSync';
import Carriers from '../Warehouse/Carriers/Carriers';
import TrustPilotReviewsInvitations from '../Reviews/Mintsoft/TrustPilotReviewsInvitations';
import RedditConfigs from '../Reddit/Configs/RedditConfigs';
import RedditAds from '../Reddit/Ads/RedditAds';
import RedditAdsCountryBreakdowns from '../Reddit/CountryBreakdowns/RedditAdsCountryBreakdowns';
import RedditAdGroups from '../Reddit/RedditAdGroups/RedditAdGroups';
import RedditSync from '../Reddit/RedditSync/RedditSync';
import ListProducts from '../Products/ListProducts/ListProducts';
import ProductEncapsulation from '../Products/CreateProduct/ProductEncapsulation';
import ProductsStyles from '../Products/ProductsStyles/ProductsStyles';
import VariantsStyles from '../Products/ProductsStyles/Variants/VariantsStyles';
import { has_general_access, has_admin_access, has_marketing_access, has_product_management_access } from '../../Utilities/HelperFunctions';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280
  }
}));


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function DashboardLayout(props) {

  const { type } = props;
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const cmsContext = useContext(CmsContext);


  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%'
          }}
        >
         { type === "dashboard" && has_general_access(cmsContext.current_user) ? <Dashboard></Dashboard> :  type === "products" && has_product_management_access(cmsContext.current_user) ? <ProductEncapsulation type={'Create'}></ProductEncapsulation> : 
         type === "product_ind_view" && has_product_management_access(cmsContext.current_user) ? <ProductEncapsulation type={'independent_view'}></ProductEncapsulation>  : type === "collections" && has_product_management_access(cmsContext.current_user) ? 
         <CollectionEncapsulation type="Create"></CollectionEncapsulation> : type === "list_collections" && has_product_management_access(cmsContext.current_user) ? <ListCollections></ListCollections> : 
         type === "list_products" && has_product_management_access(cmsContext.current_user) ? <ListProducts></ListProducts> : type === "schedule_products" && has_product_management_access(cmsContext.current_user) ? <ScheduledProducts></ScheduledProducts> : 
         type === "facebook_ads" && has_marketing_access(cmsContext.current_user) ? <FacebookAds></FacebookAds> : type === "analytics_contacts" && has_general_access(cmsContext.current_user) ? <AnalyticsCMS></AnalyticsCMS> : 
         type === "instagram_tags" && has_marketing_access(cmsContext.current_user) ? <Instagram></Instagram> : type === "facebook_tags" && has_marketing_access(cmsContext.current_user) ? <FacebookTags></FacebookTags> : 
         type === "facebook_configs" && has_marketing_access(cmsContext.current_user) ? <FacebookConfigs></FacebookConfigs> : type === "facebook_sync" && has_marketing_access(cmsContext.current_user) ? <FacebookSync></FacebookSync> : 
         type === "google_ads" && has_marketing_access(cmsContext.current_user) ? <GoogleAds></GoogleAds> : type === "google_search" && has_marketing_access(cmsContext.current_user) ? <GoogleSearch></GoogleSearch> : 
         type === "google_manage" && has_marketing_access(cmsContext.current_user) ? <GoogleAdsManageSearch></GoogleAdsManageSearch> : type === "instagram_shopify" && has_marketing_access(cmsContext.current_user) ? <InstagramShopify></InstagramShopify> : 
         type === "storage_images" && has_admin_access(cmsContext.current_user) ? <StorageImages></StorageImages> : type === "shipping_services" && has_admin_access(cmsContext.current_user) ? <Shipping></Shipping> : 
         type === "shipping_reports" && has_admin_access(cmsContext.current_user) ? <ShippingReports></ShippingReports> : type === "product_styles" && has_product_management_access(cmsContext.current_user) ? <ProductsStyles></ProductsStyles> : 
         type === "product_variants" && has_product_management_access(cmsContext.current_user) ? <VariantsStyles></VariantsStyles> : type === "inventory_reports" && has_admin_access(cmsContext.current_user) ? <InventoryReports></InventoryReports> : 
         type === "shopify_order" && has_product_management_access(cmsContext.current_user) ? <PreOrderGoals></PreOrderGoals> : type === "facebook_breakdowns" && has_marketing_access(cmsContext.current_user) ? <FacebookCountryBreakDowns></FacebookCountryBreakDowns> : 
         type === "facebook_ad_groups" && has_marketing_access(cmsContext.current_user) ? <FacebookAdGroups></FacebookAdGroups> : type === "bring_it_back_targets" && has_product_management_access(cmsContext.current_user) ? <BringItBack></BringItBack> : 
         type === "bring_it_back_configs" && has_product_management_access(cmsContext.current_user) ? <BringItBackConfigs></BringItBackConfigs> : type === "products_sync" && has_product_management_access(cmsContext.current_user) ? <ProductsSync></ProductsSync> : 
         type === "product_discounts" && has_product_management_access(cmsContext.current_user) ? <ProductDiscounts></ProductDiscounts> : type === "reviews_comments" && has_admin_access(cmsContext.current_user) ? <ReviewsAndComments></ReviewsAndComments> : 
         type === "mintsoft_order_splits" && has_product_management_access(cmsContext.current_user) ? <MintsoftOrderSplits></MintsoftOrderSplits> : type === "post_purchase" && has_product_management_access(cmsContext.current_user) ? <PostPurchase></PostPurchase> : 
         type === "shopify_conversion_rates" && has_admin_access(cmsContext.current_user) ? <ConversionRates></ConversionRates> : type === "mintsoft_orders_sync" && has_product_management_access(cmsContext.current_user) ? <MintsoftOrdersSync></MintsoftOrdersSync> : 
         type === "carrier_services" && has_admin_access(cmsContext.current_user) ? <Carriers></Carriers> : type === "trust_pilot_reviews_invitations" && has_admin_access(cmsContext.current_user) ? 
         <TrustPilotReviewsInvitations></TrustPilotReviewsInvitations> : type === "reddit_configs" && has_marketing_access(cmsContext.current_user) ? <RedditConfigs></RedditConfigs> : 
         type === "reddit_ads" && has_marketing_access(cmsContext.current_user) ? <RedditAds></RedditAds> : 
         type === "reddit_ad_groups" && has_marketing_access(cmsContext.current_user) ? <RedditAdGroups></RedditAdGroups> :
         type === "reddit_breakdowns" && has_marketing_access(cmsContext.current_user) ? <RedditAdsCountryBreakdowns></RedditAdsCountryBreakdowns> :
         type === "reddit_sync" && has_marketing_access(cmsContext.current_user) ? <RedditSync></RedditSync> : 
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        textAlign="center"
        >
        <img src='/static/images/unauthorized.png' alt='add_file'/>
        </Box>
         }
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
      <DashboardSidebar onClose={() => setSidebarOpen(false)} open={isSidebarOpen} />

      <Snackbar anchorOrigin={{vertical: "bottom", horizontal: "center"}} open={cmsContext.snackbar_state.is_open} autoHideDuration={3000}  onClose={() =>{cmsContext.setSnackBarOpen(false, null)}} >

        <Alert severity={cmsContext.snackbar_state.severity} sx={ cmsContext.snackbar_state.severity === 'success' ? { width: '100%', background:'#4caf50' } : { width: '100%' } }>
          {cmsContext.snackbar_state.message}
        </Alert>  

      </Snackbar>

    </>
  );
};


export default DashboardLayout;