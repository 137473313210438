import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableFooter,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Button,
  Stack,
  Tabs,
  Tab,
  Divider
} from '@mui/material';
import { useTheme, styled, alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { tabsClasses } from '@mui/material/Tabs';
import styles from './ListProducts.module.css';

/** ---------------------------------------
 *  Custom Styled Tabs
 *  ---------------------------------------
 */
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    variant="scrollable"
    scrollButtons="auto"
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  // A slight background color and padding to separate tabs from the background
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(1.2),
  [`& .${tabsClasses.scrollButtons}`]: {
    '&.Mui-disabled': { opacity: 0.3 },
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  // The colored indicator bar
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    borderRadius: 2,
    backgroundColor: theme.palette.primary.main,
  },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minHeight: 40,
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    transition: '0.2s',
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
    '&.Mui-focusVisible': {
      backgroundColor: alpha(theme.palette.primary.main, 0.32),
    },
  })
);

/** ---------------------------------------
 *  Table Pagination Actions
 *  ---------------------------------------
 */
function TablePaginationActions(props) {

  const { count, page, rowsPerPage, onPageChange } = props;
  
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) =>
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
TablePaginationActions.propTypes = {
  count:         PropTypes.number.isRequired,
  onPageChange:  PropTypes.func.isRequired,
  page:          PropTypes.number.isRequired,
  rowsPerPage:   PropTypes.number.isRequired,
};

/** ---------------------------------------
 *  Sorting Utilities
 *  ---------------------------------------
 */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    return order !== 0 ? order : a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/** ---------------------------------------
 *  EnhancedTableHead
 *  ---------------------------------------
 */
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    source,
  } = props;

  const createSortHandler = (property) => (event) =>
    onRequestSort(event, property);

  const headCells = [
    { id: 'image',
       numeric: false,
       disablePadding: true, 
       label: '' },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'sku',
      numeric: false,
      disablePadding: false,
      label: source === 'Sage' ? 'Analysis Code 1' : 'SKU',
    },
    {
      id: 'barcode',
      numeric: false,
      disablePadding: false,
      label: source === "Mintsoft" ? 'EAN' : 'Barcode',
    },
    {
      id: 'country_of_origin',
      numeric: false,
      disablePadding: false,
      label: 'Country of Origin',
    },
    {
      id: 'created_at',
      numeric: false,
      disablePadding: false,
      label: 'Created At',
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: false,
      label: 'Updated At',
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all products' }}
          />
        </TableCell>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            variant="head"
            sx={{
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id && (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected:     PropTypes.number.isRequired,
  onRequestSort:   PropTypes.func.isRequired,
  onSelectAllClick:PropTypes.func.isRequired,
  order:           PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy:         PropTypes.string.isRequired,
  rowCount:        PropTypes.number.isRequired,
  source:          PropTypes.string,
};

/** ---------------------------------------
 *  EnhancedTableToolbar
 *  ---------------------------------------
 */
const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <>
      {numSelected < 1 ? (
        <StyledTabs
          value={props.selected_tab}
          onChange={props.handleTabChange}
          aria-label="Store tabs"
        >
          {props.all_tabs.map((store, index) => (
            <StyledTab key={index} label={store.name} />
          ))}
        </StyledTabs>
      ) : (
        <Toolbar
          disableGutters
          sx={{
            pl: { sm: 2.5 },
            pr: { xs: 1, sm: 1 },
            pt: 0,
            pb: 0,
          }}
        >
          {numSelected > 0 && (
            <Stack
              direction="row"
              sx={{ flex: '1 1 100%' }}
              alignItems="center"
              spacing={1}
            >
              <Typography color="inherit" variant="subtitle1" component="div">
                {numSelected} selected
              </Typography>

              {numSelected === 1 && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => props.handle_product_state('deploy')}
                >
                 {props.selected_option.type === "products_skus" ? "Edit / Deploy" : "View"}
                </Button>
              )}
            </Stack>
          )}

          {numSelected === 1 && (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => props.delete_products(props.selected_option)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      )}
    </>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

/** ---------------------------------------
 *  Main ProductsListTable Component
 *  ---------------------------------------
 */
export default function ProductsListTable(props) {
  const theme = useTheme();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [dense] = React.useState(false);

  const source = props.selected_option?.name;

  const selected = React.useMemo(
    () => props.all_products.filter((product) => product.is_checked).length,
    [props.all_products]
  );

  // Handle sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Select all
  const handleSelectAllClick = (event) => {
    const isChecked = event.target.checked;
    props.set_all_products((previous) =>
      previous.map((product) => ({
        ...product,
        is_checked: isChecked,
      }))
    );
  };

  // Pagination
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Row update (checkbox or field)
  const handle_row_update = (product_id, type, value) => {
    if (type === 'price' && value < 0) return;
    props.set_all_products((previous) =>
      previous.map((prod) =>
        prod.id === product_id ? { ...prod, [type]: value } : prod
      )
    );
  };

  // Switch layout type
  const handle_product_state = (type, index) => {
    if (index > -1) {
      props.set_component_state({
        layout_type: type,
        product_id: props.all_products[index].id,
      });
    } else {
      const selectedProduct = props.all_products.find((p) => p.is_checked);
      if (selectedProduct) {
        props.set_component_state({
          layout_type: type,
          product_id: selectedProduct.id,
        });
      }
    }
  };

  // Filtering
  useEffect(() => {
    const filteredRows = get_products_from_filters(props.all_products);
    setRows(filteredRows);
  }, [props.selected_filters, props.all_products]);

  function get_products_from_filters(all_products) {
    if (!all_products || all_products.length === 0) return [];
    let filtered = [...all_products];

    // Filter by title
    if (props.selected_filters.search_value?.length > 0) {
      filtered = filtered.filter((row) =>
        row.title
          .toLowerCase()
          .includes(props.selected_filters.search_value.toLowerCase())
      );
    }

    // Filter by vendor
    if (props.selected_filters.selected_vendors.length > 0) {
      filtered = filtered.filter(
        (row) =>
          props.selected_filters.selected_vendors.indexOf(row.vendor) > -1
      );
    }

    // Filter by tags
    if (props.selected_filters.selected_tags.length > 0) {
      filtered = filtered.filter((row) =>
        row.tags.split(', ').join(',').includes(props.selected_filters.selected_tags.join(','))
      );
    }

    // Filter by status
    if (props.selected_filters.selected_status.length > 0) {
      filtered = filtered.filter((row) =>
        props.selected_filters.selected_status.includes(row.last_request_status)
      );
    }

    // Filter by SKU
    if (props.selected_filters.selected_sku.length > 0) {
      filtered = filtered.filter((row) =>
        row.variants?.some((v) =>
          v.sku
            .toLowerCase()
            .includes(props.selected_filters.selected_sku.toLowerCase())
        )
      );
    }

    return filtered;
  }

  // Click row => open detail/deploy
  const handleClickRow = (event, index) => {
    event.preventDefault();
    handle_product_state('deploy', index);
  };

  return (
    <Card sx={{ width: '100%', mt: 3, borderRadius: 3, boxShadow: 3 }}>
      <CardContent>
        {props.all_products && (
          <>
            <EnhancedTableToolbar
              selected_option={props.selected_option}
              products_type={props.products_type}
              source={source}
              selected_tab={props.selected_tab}
              handleTabChange={props.handleTabChange}
              all_tabs={props.all_tabs}
              handle_bulk_actions_change={props.handle_bulk_actions_change}
              numSelected={selected}
              delete_products={props.delete_products}
              handle_product_state={handle_product_state}
            />
            <Divider sx={{ my: 2 }} />

            <TableContainer>
             {props.all_products.length > 0 ? <Table
                sx={{ minWidth: 750 }}
                size={dense ? 'small' : 'medium'}
                aria-labelledby="tableTitle"
                stickyHeader
              >
                <EnhancedTableHead
                  selected_option={props.selected_option}
                  numSelected={selected}
                  order={order}
                  orderBy={orderBy}
                  source={source}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={props.all_products.length}
                />
                <TableBody>
                  {(props.rowsPerPage > 0
                    ? rows.slice(
                        page * props.rowsPerPage,
                        page * props.rowsPerPage + props.rowsPerPage
                      )
                    : rows
                  ).map((product, index) => {
                    const isChecked = product.is_checked;
                    const inventory = product.variants
                      ? product.variants.reduce(
                          (sum, v) => sum + (parseInt(v.quantity) || 0),
                          0
                        )
                      : 0;
                    const url =
                      product.imageFiles?.length > 0 &&
                      product.imageFiles[0].url
                        ? product.imageFiles[0].url
                        : '/static/images/tights.png';

                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: isChecked
                            ? alpha(theme.palette.primary.main, 0.08)
                            : 'inherit',
                          transition: 'background-color 0.2s',
                          '&:hover': {
                            backgroundColor: alpha(
                              theme.palette.action.hover,
                              0.6
                            ),
                          },
                        }}
                      >
                        <TableCell padding="checkbox" sx={{ whiteSpace: 'nowrap' }}>
                          <Checkbox
                            color="primary"
                            checked={isChecked}
                            onChange={(e) =>
                              handle_row_update(
                                product.id,
                                'is_checked',
                                e.target.checked
                              )
                            }
                          />
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            pr: 0,
                            width: 70,
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <Box
                            sx={{
                              border: '1px solid',
                              borderColor: 'grey.300',
                              width: 50,
                              height: 50,
                              borderRadius: 1,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              overflow: 'hidden',
                              backgroundColor: 'grey.50',
                            }}
                          >
                            <img
                              style={{ width: '100%', height: '100%' }}
                              alt="product"
                              src={url}
                            />
                          </Box>
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ minWidth: 200 }}
                        >
                          <Typography
                            variant="subtitle2"
                            onClick={(event) => handleClickRow(event, index)}
                            sx={{
                              textDecoration: 'none',
                              color: 'text.primary',
                              cursor: 'pointer',
                              '&:hover': { textDecoration: 'underline' },
                            }}
                          >
                            {product.name}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                          <Typography variant="body2">
                            {source === 'Sage'
                              ? product.analysis_code_1
                              : product.sku}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                          <Typography variant="body2">
                          {source === 'Mintsoft'
                              ? product.ean
                              : product.barcode}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                          <Typography variant="body2">
                            {product?.country_of_origin?.Code || product?.country_code || 'N/A'}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                          <Typography variant="body2">
                            {product.created_at}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                          <Typography variant="body2">
                            {product.updated_at}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: 'All', value: -1 },
                      ]}
                      colSpan={12}
                      count={rows.length}
                      rowsPerPage={props.rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table> : <Typography color="primary" sx={{textAlign:'center', marginTop:1.2}} variant="h6" fontSize={14}>No Products</Typography>}
            </TableContainer>
          </>
        )}
      </CardContent>
    </Card>
  );
}
