import React, { useContext, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Divider,
  TextField,
  Autocomplete,
  Stepper,
  Step,
  StepLabel
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { UserCircle as UserCircleIcon } from "../../../../icons/user-circle";
import CmsContext from "../../../../store/cms-context";
import cloneDeep from "lodash/cloneDeep";
import MintsoftCountries from "../../../../Utilities/MintsoftCountries";
import { parseDouble } from "../../../../Utilities/HelperFunctions";

/*
  This version splits your style-editing form into multiple steps using an MUI Stepper.
  It preserves all your logic/validation but organizes the UI into separate sections.
  On the final step, clicking "Finish" triggers the original handle_style() logic.

  If you prefer a single-page layout but want a more modern look, see the prior version.
*/

function StyleDialog(props) {

  const cmsContext = useContext(CmsContext);

  const dropzoneRef = useRef(null);

  // We'll control which step is active in the Stepper.

  // Steps we want to show in the Stepper.
  
  // Original onSelect logic.
  const onSelect = (index, checked) => {
    const tempVariantImage = { ...props.selected_variant_image };
    tempVariantImage.image_index = checked ? index : -1;
    props.set_selected_variant_image(tempVariantImage);
  };

  function set_style_state(key, value) {
    const tempState = { ...props.style_dialog_state };
    tempState.style[key] = value;
    props.set_style_dialog_state(tempState);
  }

  function set_variant_style_state(key, value) {
    const tempState = { ...props.style_dialog_state };
    tempState.variant_style[key] = value;
    props.set_style_dialog_state(tempState);
  }

  // The original logic that does final submission.
  // We only call this on final step.

  function handle_style() {

    let tempState = cloneDeep(props.style_dialog_state);

    console.log("handle style state was:", tempState);

    // Convert numeric fields
    tempState.style.sale_price_gbp = parseDouble(tempState.style.sale_price_gbp);
    tempState.style.sale_price_eur = parseDouble(tempState.style.sale_price_eur);
    tempState.style.sale_price_usd = parseDouble(tempState.style.sale_price_usd);
    tempState.style.sale_price_cad = parseDouble(tempState.style.sale_price_cad);


    const name = tempState.style.name.trim();
    const colour = tempState.style.colour.trim();
    const hs_code = tempState.style.hs_code?.trim() || "";
    const sage_product_code = tempState.style.sage_product_code.trim();
    const category = tempState.style.category.trim();

    // If sage_product_group is an object, extract its code.
    if (tempState.style.sage_product_group?.code) {
      tempState.style.sage_product_group = tempState.style.sage_product_group.code;
    }

    // Basic validation
    if (!name) {
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid style name!");
      return;
    }

    if (!colour) {
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid color!");
      return;
    }

    if(!category) {
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid category!");
      return;
    }

    if (hs_code.length > 10) {
      cmsContext.setSnackBarOpen(true, "error", "HS Code can have a maximum length of 10 characters!");
      return;
    }

    if (sage_product_code.length > 30) {
      cmsContext.setSnackBarOpen(true, "error", "Sage Product Code can have a maximum length of 30 characters!");
      return;
    }

    if (tempState.style.sale_price_gbp && tempState.style.sale_price_gbp < 0) {
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid GBP Price!");
      return;
    }

    if (tempState.style.sale_price_eur && tempState.style.sale_price_eur < 0) {
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid EUR Price!");
      return;
    }

    if (tempState.style.sale_price_usd && tempState.style.sale_price_usd < 0) {
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid USD Cost Price!");
      return;
    }

    if (tempState.style.sale_price_cad && tempState.style.sale_price_cad < 0) {
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid CAD Cost Price!");
      return;
    }

    // Date formatting
    if (tempState.style.launch_date) {
      try {
        tempState.style.launch_date = format(tempState.style.launch_date, "yyyy-MM-dd");
      } catch (error) {
        console.log("parse error was:", error);
        cmsContext.setSnackBarOpen(true, "error", "Please enter a valid date!");
        return;
      }
    }

    // Final data preparation
    tempState = {
      style: {
        id: tempState.style.id,
        name: tempState.style.name,
        category: tempState.style.category,
        style: tempState.style.style,
        colour: tempState.style.colour,
        greige_code: tempState.style.greige_code,
        launch_collection: tempState.style.launch_collection,
        launch_date: tempState.style.launch_date,
        lifecycle_status: tempState.style.lifecycle_status,
        manufacture: tempState.style.manufacture,
        seasonal_category: tempState.style.seasonal_category,
        fabric_composition: tempState.style.fabric_composition,
        care_instructions: tempState.style.care_instructions,
        reorder_status: tempState.style.reorder_status,
        sale_price_gbp: tempState.style.sale_price_gbp,
        sale_price_eur: tempState.style.sale_price_eur,
        sale_price_usd: tempState.style.sale_price_usd,
        sale_price_cad: tempState.style.sale_price_cad,
        x_department: tempState.style.x_department,
        x_product_group: tempState.style.x_product_group,
        x_product_type: tempState.style.x_product_type,
        x_style: tempState.style.x_style,
        sage_product_code: tempState.style.sage_product_code,
        sage_product_group: tempState.style.sage_product_group
      }
    };

    props.post_style(tempState.style, props.style_dialog_state.type);

  }

  function handle_close() {
    const tempState = { ...props.style_dialog_state };
    tempState.is_dialog_open = false;
    props.set_style_dialog_state(tempState);
  }

  // Logic to move between steps.
  const handleNext = () => {

      if (props.style_dialog_state.activeStep === props.style_dialog_state.steps.length - 1) {
        // This is the final step, so we finish.
        handle_style();
        return;
      }

      const temp_stat = { ...props.style_dialog_state };
      temp_stat.activeStep = temp_stat.activeStep + 1;
      props.set_style_dialog_state(temp_stat);

  };

  const handleBack = () => {

    if (props.style_dialog_state.activeStep > 0) {

      const temp_stat = { ...props.style_dialog_state };
      temp_stat.activeStep = temp_stat.activeStep - 1;
      props.set_style_dialog_state(temp_stat);

    }

  };

  // We'll create a function that returns the form fields relevant to each step.

  function renderStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <div className="space-y-1">  {/* Add spacing for elements in each step */}
            <Typography sx={{marginTop:1}} variant="subtitle1" className="font-semibold">
              Basic Information
            </Typography>
            <Divider sx={{marginTop:1}} />
            <Grid sx={{marginTop:1}} container spacing={2}>  {/* a bit top padding */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Name"
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  variant="outlined"
                  value={props.style_dialog_state.style.name}
                  onChange={(e) => set_style_state("name", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Category"
                  variant="outlined"
                  value={props.style_dialog_state.style.category}
                  onChange={(e) => set_style_state("category", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Style"
                  variant="outlined"
                  value={props.style_dialog_state.style.style}
                  onChange={(e) => set_style_state("style", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Colour"
                  variant="outlined"
                  value={props.style_dialog_state.style.colour}
                  onChange={(e) => set_style_state("colour", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Greige Code"
                  variant="outlined"
                  value={props.style_dialog_state.style.greige_code}
                  onChange={(e) => set_style_state("greige_code", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Fabric Composition"
                  variant="outlined"
                  value={props.style_dialog_state.style.fabric_composition}
                  onChange={(e) => set_style_state("fabric_composition", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Manufacture"
                  variant="outlined"
                  value={props.style_dialog_state.style.manufacture}
                  onChange={(e) => set_style_state("manufacture", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />

              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Care Instructions"
                  variant="outlined"
                  value={props.style_dialog_state.style.care_instructions}
                  onChange={(e) => set_style_state("care_instructions", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>

            </Grid>
          </div>
        );
      case 1:
        return (
          <div className="space-y-2">
            <Typography sx={{marginTop:1}} variant="subtitle1" className="font-semibold">
              Launch & Status
            </Typography>
            <Divider sx={{marginTop:1}} />
            <Grid sx={{marginTop:1}} container spacing={2}>

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Seasonal Category"
                  variant="outlined"
                  value={props.style_dialog_state.style.seasonal_category}
                  onChange={(e) => set_style_state("seasonal_category", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                    label="Launch Date"
                    value={props.style_dialog_state.style.launch_date}
                    onChange={(newValue) => {
                      const tempState = { ...props.style_dialog_state };
                      tempState.style.launch_date = newValue;
                      props.set_style_dialog_state(tempState);
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Launch Collection"
                  variant="outlined"
                  value={props.style_dialog_state.style.launch_collection}
                  onChange={(e) => set_style_state("launch_collection", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>

              
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Lifecycle Status"
                  variant="outlined"
                  value={props.style_dialog_state.style.lifecycle_status}
                  onChange={(e) => set_style_state("lifecycle_status", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Reorder Status"
                  variant="outlined"
                  value={props.style_dialog_state.style.reorder_status}
                  onChange={(e) => set_style_state("reorder_status", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>

            </Grid>
          </div>
        );
      case 2:
        return (
          <div className="space-y-3">
            <Typography sx={{marginTop:1}} variant="subtitle1" className="font-semibold">
              Pricing
            </Typography>
            <Divider sx={{marginTop:1}} />
            <Grid sx={{marginTop:1}} container spacing={2} >
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  type="number"
                  label="Sale Price (GBP)"
                  variant="outlined"
                  value={props.style_dialog_state.style.sale_price_gbp}
                  onChange={(e) => set_style_state("sale_price_gbp", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  type="number"
                  label="Sale Price (EUR)"
                  variant="outlined"
                  value={props.style_dialog_state.style.sale_price_eur}
                  onChange={(e) => set_style_state("sale_price_eur", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  type="number"
                  label="Sale Price (USD)"
                  variant="outlined"
                  value={props.style_dialog_state.style.sale_price_usd}
                  onChange={(e) => set_style_state("sale_price_usd", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  type="number"
                  label="Sale Price (CAD)"
                  variant="outlined"
                  value={props.style_dialog_state.style.sale_price_cad}
                  onChange={(e) => set_style_state("sale_price_cad", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        );
      case 3:
        return (
          <div className="space-y-4">
            <Typography sx={{marginTop:1}} variant="subtitle1" className="font-semibold">
              Extra Fields
            </Typography>
            <Divider sx={{marginTop:1}} />
            <Grid sx={{marginTop:1}} container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Xdepartment"
                  variant="outlined"
                  value={props.style_dialog_state.style.x_department}
                  onChange={(e) => set_style_state("x_department", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Xproduct Group"
                  variant="outlined"
                  value={props.style_dialog_state.style.x_product_group}
                  onChange={(e) => set_style_state("x_product_group", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Xproduct Type"
                  variant="outlined"
                  value={props.style_dialog_state.style.x_product_type}
                  onChange={(e) => set_style_state("x_product_type", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Xstyle"
                  variant="outlined"
                  value={props.style_dialog_state.style.x_style}
                  onChange={(e) => set_style_state("x_style", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        );
      case 4:
        return (
          <>
            <Typography sx={{marginTop:1}} variant="subtitle1" className="font-semibold">
              Sage
            </Typography>
            <Divider sx={{marginTop:1}} />
            <Grid sx={{marginTop:1}} container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  label="Sage Product Code"
                  variant="outlined"
                  value={props.style_dialog_state.style.sage_product_code}
                  onChange={(e) => set_style_state("sage_product_code", e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disabled={props.style_dialog_state.type === "Update" || props.style_dialog_state.type === "View"}
                  id="prouct-group"
                  options={props.style_dialog_state.sage_product_groups}
                  getOptionLabel={(option) => (option.code ? option.code : "")}
                  value={props.style_dialog_state.style.sage_product_group}
                  isOptionEqualToValue={(option, value) => {
                    return value.code ? value.code === option.code : false;
                  }}
                  onChange={(event, newValue) => {
                    set_style_state("sage_product_group", newValue);
                  }}
                  renderOption={(props, option) => {
                    const key = `listItem-${option.code}`;
                    return (
                      <li {...props} key={key} style={{paddingLeft:10}} className="cursor-pointer">
                        {option.code}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sage Product Group"
                      placeholder="Sage Product Group"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
           </>
        );
      default:
        return <div>Unknown step</div>;
    }
  }

  return (
    <Dialog
      open={props.style_dialog_state.is_dialog_open}
      onClose={handle_close}
      aria-labelledby="stepper-style-dialog-title"
      aria-describedby="stepper-style-dialog-description"
      fullWidth
      maxWidth="md"
      className="rounded-2xl"
    >
      <DialogTitle
        id="stepper-style-dialog-title"
        className="text-2xl font-bold tracking-wide bg-gray-50 border-b border-gray-200"
      >
        {props.style_dialog_state.type} Style
      </DialogTitle>
      <DialogContent dividers className="bg-white px-6 py-4">
        <div className="flex flex-col items-center mb-6">
          <Avatar
            sx={{ height: 120, width: 120 }}
            src="/static/images/prototype.png"
            className="shadow-lg"
          >
            <UserCircleIcon fontSize="large" />
          </Avatar>
          <Typography sx={{marginTop:1}} variant="h6" className="mt-3 font-semibold">
            Style Wizard
          </Typography>
          <Typography sx={{marginTop:1}} variant="body2" color="textSecondary" className="text-center max-w-sm">
            Follow the steps to create or update a style.
          </Typography>
        </div>

        {/* Stepper to visualize progress */}
        <Stepper sx={{marginTop:2}} activeStep={props.style_dialog_state.activeStep} alternativeLabel className="mb-6">
          {props.style_dialog_state.steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {/* The actual fields for the current step */}
        <div className="mt-4">{renderStepContent(props.style_dialog_state.activeStep)}</div>
      </DialogContent>

      <DialogActions className="flex justify-end bg-gray-50 border-t border-gray-200 px-6 py-3 space-x-2">
        {/* Cancel always present */}
        <Button onClick={handle_close} variant="outlined" className="mr-auto">
          Cancel
        </Button>
        {/* Back only if not on step 0 */}
        {props.style_dialog_state.activeStep > 0 && (
          <Button onClick={handleBack} variant="outlined">
            Back
          </Button>
        )}
        {/* Next or Finish */}
       {!(props.style_dialog_state.type === "View" && props.style_dialog_state.activeStep === props.style_dialog_state.steps.length - 1) ? <LoadingButton
          variant="contained"
          color="primary"
          loading={props.style_dialog_state.isLoading}
          onClick={handleNext}
          className="bg-blue-600 hover:bg-blue-700 text-white shadow-md"
        >
          {props.style_dialog_state.activeStep === props.style_dialog_state.steps.length - 1 ? props.style_dialog_state.type : "Next"}
        </LoadingButton> : null}
      </DialogActions>
    </Dialog>
  );
}

export default StyleDialog;
