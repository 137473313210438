import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl, FormControlLabel, Switch } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../../store/cms-context';

import { getAccessJwtToken } from '../../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Analytics, Storage } from 'aws-amplify';
import { alpha, styled } from '@mui/material/styles';
import { grey , blue} from '@mui/material/colors';
import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimeZones from "../../../Utilities/TimeZones";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import { clone, cloneDeep, min } from "lodash";
import { ONE_DAY } from "../../../Utilities/Metrics";
import { has_product_management_write_access } from "../../../Utilities/HelperFunctions";


const CustomSwitch = styled(Switch)(({ theme }) => ({

  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        color:blue[800]
      }
    },
    '&:hover': {
      backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
    }
  },
  '& .MuiSwitch-thumb': {
    color:grey[200]
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: blue[300],
        opacity: 1,
        border: 0,
  },
  
}));

function BringItBackConfigs(props) {


  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_collections, set_all_collections] = React.useState([]);

  const [component_state, set_component_state] = React.useState({layout_type: 'list', collection_id : ''})

  const [all_stores, set_all_stores] = React.useState([]);

  const [active_stores, set_active_stores] = React.useState([]);

  const navigate = useNavigate();


  useEffect(() => {

    isMountedRef.current = true

    get_configurations_settings()

    return () => isMountedRef.current = false;

  }, [])



  async function get_picture_async(key, index){

    const picUrl = await Storage.get(key, {
      level: 'public',
      bucket: 'snag-cms175447-staging',
      expires:86400,
      region: 'eu-west-2',
    });

    console.log('url was', picUrl)

    set_all_collections((previous_collections) => {

      const temp_collections = [...previous_collections]

      if(index < temp_collections.length){
        temp_collections[index].image.url = picUrl;
      }

      return(set_all_collections(temp_collections))

    })

  }


  function handle_active_stores_change(event, newValue){

    set_active_stores(newValue)

  }


  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Configurations</Typography> 

          <div>

          <Button variant="contained" onClick = {() => {update_configurations()}}>Update Configurations</Button>

          </div>

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >

      <Paper elevation = {2} style={{marginTop:16, padding:20}}>
        
        <Grid
          container
          spacing={3}
          alignItems="center"
        >

          <Grid
            item
            xs={12}
          >

          <Autocomplete
                multiple
                id="active-accounts"
                options={all_stores}
                getOptionLabel={(option) => option.name}
                value={active_stores}
                isOptionEqualToValue={(option, value) => option.store_domain === value.store_domain}
                onChange={(event, newValue) => {handle_active_stores_change(event, newValue)}}
                renderInput={(params) => (
                  <TextField {...params} label="Active Stores" placeholder="Active Stores" />
                )}
          />     

        </Grid>

    

        {active_stores.map((store, index) => { 

          return(

            <Grid key={index} item xs={12} >

            <Grid container spacing={1}>

            <Grid
            item 
            xs={12}
            >

              <Typography style={{marginBottom:8}} fontSize={12}><strong>{store.name}</strong></Typography>

            </Grid>

            <Grid
              item
              xs={12}
              md={6}
            >

              <Grid
                item
                xs={12}
              >

              <Autocomplete
                    id="archive-collection"
                    options={store.all_collections}
                    getOptionLabel={(option) => option.title ? option.title : ''}
                    value={store.archive_collection}
                    isOptionEqualToValue={(option, value) => option.id === value.id }
                    onChange={(event, newValue) => {

                      const temp_stores = [...active_stores]

                      temp_stores[index].archive_collection = newValue

                      set_active_stores(temp_stores)

                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.title}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Archive Collection" placeholder="Archive Collection" />
                    )}
              />     

            </Grid>


            </Grid>

            <Grid
              item
              xs={12}
              md={6}
            >

              <Autocomplete
                  multiple
                  freeSolo
                  fullWidth
                  id="tags-outlined"
                  value={store.excluded_tags}
                  defaultValue={[]}
                  options={[]}
                  onChange={(event, newValue) =>{

                    const temp_stores = [...active_stores]

                    temp_stores[index].excluded_tags = newValue

                    set_active_stores(temp_stores)


                  }}
                  renderInput={params => (
                      <TextField
                          {...params}
                          variant="outlined"
                          label="Excluded Tags"
                          placeholder="Excluded Tags"
                      />
                  )}
              />

            </Grid>


          </Grid>

          </Grid>

          )
            
          })}

        </Grid>

        </Paper>


    
        </Grid>



      </Grid>

    </Container>      


    </Box>

     }

    </> 


  );

  function get_configurations_settings() {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/bring_it_back/configurations',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {

              setIsLoading(false)
  
              console.log('result was: ', cloneDeep(result))

              if(result.data){

                var stores = result.data.stores

                const act_stores = result.data.active_stores;

                set_all_stores(stores);

                const temp_act_stores = []

                if(act_stores && act_stores.length > 0){

                for (var z=0; z< stores.length; z++){

                  for (var z1=0; z1< act_stores.length; z1++){

                    if(act_stores[z1].store.toString() === stores[z].store.toString()){

                      const temp_store = act_stores[z1]

                      temp_store.name = stores[z].name

                      temp_store.store_domain = stores[z].store_domain

                      stores[z].all_collections.forEach((collection) => {

                        if(collection.id === temp_store.archive_collection.toString()){
                          temp_store.archive_collection = collection
                        }

                      })

                      temp_store.all_collections = stores[z].all_collections

                      temp_act_stores.push(temp_store)

                      break;

                    }

                  }

                }

                console.log('setting excluded campaigns: ', temp_act_stores)

                set_active_stores(temp_act_stores)

              }

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
      )

    })


  }

  function update_configurations(){

    console.log('updating')

    var request_body = {}

    request_body.configurations = {}

    var act_stores = cloneDeep(active_stores)

    var is_valid = true


    act_stores.forEach((active_store) =>{

      if(!active_store.archive_collection || (Object.keys(active_store.archive_collection).length === 0)){
        is_valid = false
      }

      if(active_store.excluded_tags.length === 0){
        is_valid = false
      }

    })

    if(!is_valid){

      cmsContext.setSnackBarOpen(true, "error", "Please enter valid values for all fields!")
      return

    }

    const processed_stores = []

    act_stores.forEach((active_store) => {

      processed_stores.push({"store": active_store.store, "archive_collection": parseInt(active_store.archive_collection.id), "excluded_tags": active_store.excluded_tags})

    })

    request_body.configurations = processed_stores;

    request_body.request_type = "Update"

    console.log('request body: ', request_body)

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      if(!has_product_management_write_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
      }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/bring_it_back/configurations', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was: ', result)

              if(result.message){


                console.log('yes type rules')

                cmsContext.setSnackBarOpen(true, "success", result.message)

                get_configurations_settings()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }


};


export default BringItBackConfigs;