import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl, Select, MenuItem, Divider} from '@mui/material';

import { LoadingButton } from "@mui/lab";

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../store/cms-context';

import { getAccessJwtToken } from '../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Analytics, Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import BarChart from "./SubComponents/BarChart";
import TimeZones from "../../Utilities/TimeZones";
import TimezoneSelect from "react-timezone-select";
import { clone, cloneDeep, min } from "lodash";
import { default_insta_shopify_stream_link_dialog, ONE_DAY } from "../../Utilities/Metrics";
import SyncTasksListTable from "./SubComponents/StreamTasksListTable";
import NewTaskDialog from "./SubComponents/NewTaskDialog";
import StreamTasksListTable from "./SubComponents/StreamTasksListTable";
import { has_admin_access } from "../../Utilities/HelperFunctions";




function InstagramShopify(props) {

  
  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [mode, set_mode] = React.useState(0); // 0 -> all tasks, 1 -> create tasks

  const [isLoading, setIsLoading] = useState(false);

  const [source_account, set_source_account] = useState('');

  const [source_campaign, set_source_campaign] = useState('');

  const [target_campaign, set_target_campaign] = useState('');

  const [target_account, set_target_account] = useState('');

  const [source_adsets, set_source_adsets] = useState([]);

  const [target_adsets, set_target_adsets] = useState([]);

  const [source_ads, set_source_ads] = useState([]);

  const [target_ads, set_target_ads] = useState([]);

  const [selected_source_adset, set_selected_source_adset] = useState([]);

  const [selected_source_ads, set_selected_source_ads] = useState([]);

  const [selected_target_adset, set_selected_target_adset] = useState([]);

  const [selected_target_ads, set_selected_target_ads] = useState([]);

  const [all_collections, set_all_collections] = React.useState([]);

  const [component_state, set_component_state] = React.useState({layout_type: 'list', collection_id : ''})

  const [all_campaigns, set_all_campaigns] = React.useState({});

  const [all_accounts, set_all_accounts] = React.useState([]);

  const [active_accounts, set_active_accounts] = React.useState([]);

  const [analytics, set_analytics] = React.useState([]);

  const [all_streams, set_all_streams] = React.useState([]);

  const [isLinkAccountsLoading, setIsLinkAccountsLoading] = React.useState(false);

  const [link_dialog_state, set_link_dialog_state] = React.useState(cloneDeep(default_insta_shopify_stream_link_dialog))

  const navigate = useNavigate();


  useEffect(() => {

    isMountedRef.current = true

    get_all_streams()

    //get_configurations_settings()

    return () => isMountedRef.current = false;

  }, [])

  useEffect(() =>{

    console.log('all campaigns: ', all_campaigns)

  }, [all_campaigns])


  useEffect(() =>{

    console.log('source account was: ', source_account)

  }, [source_account])



  




  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Sync Ads</Typography> 


          <LoadingButton loading={isLinkAccountsLoading} variant="contained" onClick = {() => {show_link_accounts_dialog('Create')}}>Create Stream</LoadingButton>

        </Stack>

      </Grid>

     {all_streams != null && all_streams.length > 0 ?

        <Grid
        item
        xs={12}
        >

          <Grid
          container
          spacing={3}
          alignItems="center"
          >

          <Grid
          item
          xs={12}
          >

            <StreamTasksListTable delete_tasks={delete_tasks} all_tasks={all_streams} set_all_tasks={set_all_streams}></StreamTasksListTable>

          </Grid>

        </Grid>

      </Grid>

      : null}



      {analytics.length > 0 ? analytics.map((account_level_analytics, index) => {

        return(
          <Grid key={index} item xs={12}>
            <BarChart analytics={account_level_analytics}></BarChart>
          </Grid>
        )

      }) : null}



      </Grid>

    </Container>      


    </Box>

     }

     <NewTaskDialog handle_stream_update={handle_stream_update} link_dialog_state={link_dialog_state} set_link_dialog_state={set_link_dialog_state}></NewTaskDialog>

    </> 


  );


  function show_link_accounts_dialog(action_type){

    setIsLinkAccountsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

    fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/instagram/shopify/accounts',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt,
            'email':cmsContext.current_shop.email
          }})
          .then(res => res.json())
          .then(
            (result) => {

              console.log('result was: ', result)

              setIsLinkAccountsLoading(false)

              if(result.shopify_accounts){

                const temp_dialog_state = cloneDeep(default_insta_shopify_stream_link_dialog);

                temp_dialog_state.instagram_accounts = result.instagram_accounts

                temp_dialog_state.shopify_accounts = result.shopify_accounts

                temp_dialog_state.action_type = action_type
  
                temp_dialog_state.is_dialog_open = true

                set_link_dialog_state(temp_dialog_state);
    
                console.log('result was: ', result)

              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please try again")

              }
  
            },
        
            (error) => {
              setIsLinkAccountsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please try again")
              console.log('error was',error)
            }
           
      )

    })
    

  }

  function delete_tasks(){

    var selected_stream = {}

    all_streams.forEach((stream) =>{
      if(stream.is_checked){
        selected_stream = stream
      }
    })

    if(Object.keys(selected_stream).length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid stream")
      return
    }

    const request_body = {}

    request_body.stream = selected_stream

    request_body.stream.email = cmsContext.current_shop.email

    request_body.stream.last_request = "Delete"

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      if(!has_admin_access(auth_creds.current_user)){
          cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
          setIsLoading(false)
          return
        }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/instagram/shopify/streams', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)
                reset_page(true)
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }
  

  function get_all_streams() {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/instagram/shopify/streams', {
          crossDomain:true,
          method: 'GET',
          headers: {
            'email': cmsContext.current_shop.email,
            'Authorization': auth_creds.jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was',cloneDeep(result))



              if(result.all_streams){

                if(result.all_streams.length === 0){
                  cmsContext.setSnackBarOpen(true, "info", "No streams yet. Let's create one to get started.")
                  return
                }

                result.all_streams.forEach((stream) =>{
                  stream.is_checked = false
                })

                set_all_streams(result.all_streams)
              
              }else if(result.errorMessage){
                
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
      )

    })


  }




  function reset_page(delayed){

    setTimeout(function() {

      window.location.reload();

  }, delayed?100:1)
}


  function set_dialog_loading(isLoading){

    const temp_dialog_state = {...link_dialog_state}

    temp_dialog_state.isLoading = isLoading

    set_link_dialog_state(temp_dialog_state)

  }


  function handle_stream_update(){

    console.log('syncing')


    var request_body = {}

    request_body.stream = {}


    if(link_dialog_state.stream_name.length === 0 || link_dialog_state.stream_name.indexOf(' ') >= 0){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid stream name without spaces")
      return
    }

    if(link_dialog_state.selected_stream_type.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid stream type")
      return
    }

    if(link_dialog_state.selected_stream_type === "Hashtag" && (link_dialog_state.hash_tag_value.length === 0 || link_dialog_state.stream_name.indexOf(' ') >= 0)){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid Hashtag without spaces")
      return
    }

    if(link_dialog_state.selected_stream_type === "Account" && Object.keys(link_dialog_state.selected_instagram_account).length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid Instagram Account")
      return
    }

    if(Object.keys(link_dialog_state.selected_shopify_account).length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid shopify account")
      return
    }

    if(link_dialog_state.sort_type.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid sort type")
      return
    }

    if(link_dialog_state.number_of_posts < 1){
      cmsContext.setSnackBarOpen(true, "error", "Number of posts should be greater than 0")
      return
    }

    request_body.stream.name = link_dialog_state.stream_name

    request_body.stream.stream_type = link_dialog_state.selected_stream_type

    request_body.stream.sort_type = link_dialog_state.sort_type

    if(Object.keys(link_dialog_state.selected_instagram_account).length > 0){
      request_body.stream.instagram_account =  link_dialog_state.selected_instagram_account.id
      request_body.stream.instagram_account_name = link_dialog_state.selected_instagram_account.name
    }

    if(link_dialog_state.hash_tag_value.length > 0){
      request_body.stream.hashtag = link_dialog_state.hash_tag_value
    }

    request_body.stream.shopify_account = link_dialog_state.selected_shopify_account.store_domain

    request_body.stream.shopify_account_name = link_dialog_state.selected_shopify_account.name

    request_body.stream.number_of_posts = link_dialog_state.number_of_posts

    request_body.stream.email = cmsContext.current_shop.email

    request_body.stream.last_request = "Create"

    console.log('request body: ', request_body)

    set_dialog_loading(true)
 

    getAccessJwtToken().then((auth_creds)=>{

      if(!has_admin_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
      }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/instagram/shopify/streams', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
  
              set_dialog_loading(false)
  
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)
                reset_page(true)
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }


};


export default InstagramShopify;