import { createContext, useState } from "react";



const CmsContext = createContext({is_snackbar_open:false, total_products:[]});



export function CmsContextProvider(props) {

    const [snackbar_state, set_snackbar_state] = useState({is_open:false, severity:'success',message:''});

    const [total_products, set_total_products] = useState([]);

    const [current_shop, set_current_shop] = useState({'store_domain':'snag-test-uk.myshopify.com', 'email':'admin@snagtights.com','connected_stores':[]});

    const [current_user, set_current_user] = useState({})

    function setSnackBarOpen(open, severity, message) {

        set_snackbar_state((previous_state) => {

            const temp_state = {...previous_state}

            temp_state.is_open = open

            if(severity != null){
                temp_state.severity = severity
            }

            if(message != null){
                temp_state.message = message
            }

            return(set_snackbar_state(temp_state))

        })
    
    }

    function setProducts(new_products){

        set_total_products((previous_products) => {

            return(previous_products.concat(new_products))

        });

    }

    const context = {

        snackbar_state: snackbar_state,
        total_products: total_products,
        setProducts: setProducts,
        setSnackBarOpen: setSnackBarOpen,
        current_shop: current_shop,
        set_current_shop: set_current_shop,
        current_user: current_user,
        set_current_user: set_current_user
    };

    return <CmsContext.Provider value={context}>

        {props.children}

    </CmsContext.Provider>

}

export default CmsContext;



// Create context

// snackbar global

// Ensure previous state on all arrays/ states

