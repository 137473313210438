import React, { useState, useRef, useEffect, useContext, useCallback } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../store/cms-context';

import { getAccessJwtToken } from '../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ShippingServiceDialog from "./SubComponents/ShippingServiceDialog";
import ShippingServicesListTable from "./SubComponents/ShippingServicesListTable";
import { default_shipping_service_dialog } from "../../Utilities/Metrics";

import cloneDeep from 'lodash/cloneDeep';
import { has_admin_access } from "../../Utilities/HelperFunctions";


function Shipping(props) {


  console.log("Shipping rendered")

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_shipping_services, set_all_shipping_services] = React.useState([]);

  const [active_accounts, set_active_accounts] = React.useState([]);

  const [email_time, set_email_time] = React.useState('');

  const [shipping_service_dialog_state, set_shipping_service_dialog_state] = React.useState(cloneDeep(default_shipping_service_dialog));


  useEffect(() => {

    isMountedRef.current = true

    get_all_shipping_services()

    return () => isMountedRef.current = false;

  }, [])


 


  function handle_active_accounts_change(event, newValue){

    set_active_accounts(newValue)

  }

  function show_create_shipping_service_dialog(){
    
    const temp_dialog_state = cloneDeep(default_shipping_service_dialog);

    temp_dialog_state.is_dialog_open = true

    temp_dialog_state.type = "Create"

    set_shipping_service_dialog_state(temp_dialog_state);

  }

  const delete_shipping_services = useCallback(() => {
    
    console.log('delete shipping_services')

    var temp_shipping_services = [...all_shipping_services].filter((shipping_service) => shipping_service.is_checked);

    console.log('filtered were', temp_shipping_services)

    if(temp_shipping_services.length === 0){
      // might never execute (need to select shipping_services to display delete button)
      cmsContext.setSnackBarOpen(true, "error", "Please select valid shipping_services!")
      return;
    }
    /*
    else if(temp_shipping_services.length > 20){
      cmsContext.setSnackBarOpen(true, "error", "Please select a maximum of 20 shipping_services!")
      return;
    }
    */

    temp_shipping_services = temp_shipping_services.map((shipping_service) => {

      return(shipping_service.id)

    })

    console.log('final shipping_services were', temp_shipping_services)

    const request_body = {}

    request_body.shipping_service = temp_shipping_services

    request_body.request_type = "Delete"

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)
    

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds) => {

       if(!has_admin_access(auth_creds.jwt)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
      }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/shipping/services', {
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':auth_creds.jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was: ', result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_shipping_services()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {
                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)
                }
          );
  
      });

  }, [all_shipping_services]);


  const set_dialog_shipping_service = useCallback((shipping_service, is_open) => {

      const temp_shipping_service_state = cloneDeep(default_shipping_service_dialog)

      temp_shipping_service_state.shipping_service = shipping_service

      temp_shipping_service_state.is_dialog_open = is_open

      temp_shipping_service_state.type = "Update"

      set_shipping_service_dialog_state(temp_shipping_service_state)

  },[])

  const set_dialog_loading = useCallback(( is_loading) => {

    const temp_shipping_service_state = {...shipping_service_dialog_state}

    temp_shipping_service_state.isLoading = is_loading

    console.log('dialog state was: ', temp_shipping_service_state)

    set_shipping_service_dialog_state(temp_shipping_service_state)

  },[shipping_service_dialog_state])


  const reset_dialog_state = useCallback((is_loading) => {

    set_shipping_service_dialog_state(cloneDeep(default_shipping_service_dialog))

  },[])





  const post_shipping_service = useCallback((shipping_service, request_type) => {

    set_dialog_loading(true)

    console.log('updating')

    var request_body = {}

    request_body.shipping_service = shipping_service

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    getAccessJwtToken().then((auth_creds)=>{

      if(!has_admin_access(auth_creds.jwt)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
      }


      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/shipping/services', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)
                reset_dialog_state()

                get_all_shipping_services()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_dialog_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
                set_dialog_loading(false)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })



  },[reset_dialog_state, set_dialog_loading, cmsContext]);







  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Shipping Services</Typography>

          <div>

          <Button variant="contained" onClick = {() => {show_create_shipping_service_dialog()}}>Create Shipping Service</Button>

          </div>

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >


         {shipping_service_dialog_state ? <ShippingServicesListTable delete_shipping_services={delete_shipping_services} set_dialog_shipping_service={set_dialog_shipping_service} all_shipping_services={all_shipping_services} set_all_shipping_services={set_all_shipping_services}>

          </ShippingServicesListTable>  : null}


      </Grid>


      </Grid>

    </Container>      


    </Box>

     }

     <ShippingServiceDialog post_shipping_service={post_shipping_service} shipping_service_dialog_state={shipping_service_dialog_state} set_shipping_service_dialog_state={set_shipping_service_dialog_state}></ShippingServiceDialog>

    </> 


  );


  function get_all_shipping_services() {
    
    console.log('store_access_token')


    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/shipping/services', {
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
              setIsLoading(false)
  
              console.log('result was',result)

              if(result.data){


                if(result.data.length === 0){

                  cmsContext.setSnackBarOpen(true, "info", "No shipping services yet. Let's create one to get started.")

                  set_all_shipping_services([])

                  return

                }


                const all_shipping_services = []

                var temp_shipping_service;

                result.data.forEach((shipping_service) =>{

                    temp_shipping_service = cloneDeep(shipping_service)
                    temp_shipping_service.is_checked = false
                    all_shipping_services.push(temp_shipping_service)

                })

                console.log('all options')

              
                set_all_shipping_services(all_shipping_services)

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }

              
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
           
      )

    })


  }


};


export default Shipping;