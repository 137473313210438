import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl, Slider, ButtonGroup, Switch } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../store/cms-context';

import { getAccessJwtToken } from '../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import GAdsLinkDialog from "../GAdsLinkDialog/GAdsLinkDialog";


import cloneDeep from 'lodash/cloneDeep';
import FlowTasks from "../FlowTasks/FlowTasks";

import { default_link_dialog } from "../../Utilities/Metrics"
import { LoadingButton } from "@mui/lab";

import BarChart from "./SubComponents/BarChart"

import CurrenciesInfo from '../../Utilities/CurrenciesInfo'
import { CustomSwitch } from "../../Utilities/UIWidgets";
import { has_marketing_write_access } from "../../Utilities/HelperFunctions";


const marks = [
  {
    value: 0,
    label: '0x',
  },
  {
    value: 4,
    label: '4x',
  },
  {
    value: 5,
    label: '5x',
  },
  {
    value: 20,
    label: '20x',
  },
];


function GoogleAds(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [active_accounts, set_active_accounts] = React.useState([]);

  const [email_time, set_email_time] = React.useState('');

  const [analytics, set_analytics] = React.useState([])

  const [link_dialog_state, set_link_dialog_state] = React.useState(cloneDeep(default_link_dialog));

  const [isLinkAccountsLoading, setIsLinkAccountsLoading] = React.useState(false)

  const [all_gads_merch_connections, set_all_gads_merch_connections] = React.useState([]);


  useEffect(() => {

    isMountedRef.current = true

    get_google_ads_link_data()

    return () => isMountedRef.current = false;

  }, [])


  function valuetext(value) {
    return `${value}x`;
  }
 


  function show_link_accounts_dialog(action_type){

    setIsLinkAccountsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

    fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/gads_google_merchant',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt,
            'email':cmsContext.current_shop.email
          }})
          .then(res => res.json())
          .then(
            (result) => {

              console.log('result was: ', result)

              setIsLinkAccountsLoading(false)

              if(result.merchant_stores){

                const temp_dialog_state = cloneDeep(default_link_dialog);

                temp_dialog_state.action_type = action_type
  
                temp_dialog_state.is_dialog_open = true

                temp_dialog_state.merchant_stores = result.merchant_stores

                set_link_dialog_state(temp_dialog_state);
    
                console.log('result was: ', result)

              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please try again")

              }
  
            },
        
            (error) => {
              setIsLinkAccountsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please try again")
              console.log('error was',error)
            }
           
      )

    })
    

  }



  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Merchant Center Links</Typography>

          <div>

          <LoadingButton loading={isLinkAccountsLoading} variant="contained" onClick = {() => {show_link_accounts_dialog('Update')}}>Link Accounts</LoadingButton>

          </div>

        </Stack>

      </Grid>


        {Object.keys(all_gads_merch_connections).length > 0 ? 
        
        <Grid
          item
          xs={12}
        >

          <FlowTasks all_tasks={all_gads_merch_connections}></FlowTasks>

        </Grid> : null}


      </Grid>

    </Container>


    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Google Ads Configurations</Typography> 

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >

      <Paper  elevation = {2} style={{marginTop:16, padding:20}}>
        
        <Grid
          container
          spacing={3}
          alignItems="center"
        >


    

        {all_gads_merch_connections.map((connection, index) => { 

          return(

            <Grid key={index} item xs={12} >

            <Grid container spacing={2}>

            <Grid
            item 
            xs={6}
            >

              <Typography style={{marginBottom:8}} fontSize={12}><strong>{connection.source_store_name + " - " + connection.target_store_name}</strong></Typography>

            </Grid>


            <Grid item xs={6}>

            <div style={{display:'flex', float:'right'}}>
            <CustomSwitch checked={connection.adRules.isActive ? connection.adRules.isActive: false} onChange={(event) =>{const temp_accounts = [...all_gads_merch_connections];temp_accounts[index].adRules.isActive = event.target.checked; set_all_gads_merch_connections(temp_accounts)}}></CustomSwitch>
            <ButtonGroup size="small" variant="contained" aria-label="outlined primary button group">
              <Button size="small" onClick={() => {handle_gads_configuration_update(index, "update")}}>Update</Button>
              <Button size="small" onClick={() => {handle_gads_configuration_update(index, "delete")}}>Delete</Button>
            </ButtonGroup>
            </div>

      
            </Grid>


            <Grid
              item
              xs={6}
            >

            <FormControl fullWidth >
              <InputLabel htmlFor="outlined-adornment-cpa">Budget</InputLabel>
              <OutlinedInput
                id="outlined-adornment-cpa"
                value={all_gads_merch_connections[index].adRules.budget ? all_gads_merch_connections[index].adRules.budget: ''}
                type="number"
                size="small"
                onChange={(event) => {

                  console.log('value was: ', event.target.value)

                  const temp_accounts = [...all_gads_merch_connections];

                  if(event.target.value >= 0){
                    temp_accounts[index].adRules.budget = event.target.value;
                  }
                  else{
                    temp_accounts[index].adRules.budget = 0;
                  }

                  set_all_gads_merch_connections(temp_accounts);

                }}
                startAdornment={<InputAdornment position="start">{CurrenciesInfo[all_gads_merch_connections[index].target_currency].symbol}</InputAdornment>}
                label="Budget"
              />
            </FormControl>

            </Grid>

            <Grid
              item
              xs={6}
            >

            <FormControl fullWidth >
              <InputLabel htmlFor="outlined-adornment-cpa">ROAS</InputLabel>
              <OutlinedInput
                id="outlined-adornment-cpa"
                value={all_gads_merch_connections[index].adRules.roas ? all_gads_merch_connections[index].adRules.roas: ''}
                type="number"
                size="small"
                onChange={(event) => {

                  console.log('value was: ', event.target.value)

                  const temp_accounts = [...all_gads_merch_connections];

                  if(event.target.value >= 0){
                    temp_accounts[index].adRules.roas = event.target.value;
                  }
                  else{
                    temp_accounts[index].adRules.roas = 0;
                  }

                  set_all_gads_merch_connections(temp_accounts);

                }}
                startAdornment={<InputAdornment position="start">{CurrenciesInfo[all_gads_merch_connections[index].target_currency].symbol}</InputAdornment>}
                label="Budget"
              />
            </FormControl>

            </Grid>

          </Grid>

          </Grid>

          )
            
          })}

        </Grid>

        </Paper>


    
        </Grid>

        { analytics.length > 0 ? analytics.map((account_level_analytics, index) =>{

          return(
            <Grid key={index} item xs={12}>
              <BarChart analytics={account_level_analytics}></BarChart>
            </Grid>
          )

        }) : null}



         </Grid>

       </Container>      


      </Box>

      }

        </>       


    </Box>

     }

     <GAdsLinkDialog link_dialog_state={link_dialog_state} set_link_dialog_state={set_link_dialog_state} handle_merchant_gads_update={handle_merchant_gads_update}></GAdsLinkDialog>


    </> 


  );




  function get_google_ads_link_data() {
    
    console.log('store_access_token')


    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/gads/merchant_links',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt,
            'email':cmsContext.current_shop.email
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
  
              setIsLoading(false)
  
              console.log('result was',result)

              if(result.links){

                const temp_links = [...result.links]

                temp_links.forEach((link) =>{
                  link.isLoading = false
                  link.adRules.isActive = false

                  if(link.adRules.budget){
                    link.adRules.budget = link.adRules.budget.toString()
                  }

                  if(link.adRules.roas){
                    link.adRules.roas = link.adRules.roas.toString()
                  }

                })

                set_all_gads_merch_connections(temp_links)

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }

              /*

             
              if(result.contacts){

                console.log('setting analytics')

                if(result.contacts.length === 0){

                  cmsContext.setSnackBarOpen(true, "info", "No contacts yet. Let's create one to get started.")

                  return

                }


                const all_contacts = []

                var temp_contact;

                result.contacts.forEach((contact) =>{
                  temp_contact = cloneDeep(default_contact.contact)
                  for(var key in contact){
                    temp_contact[key] = contact[key]
                  }
                  temp_contact.is_checked = false
                  console.log('temp contact was: ', temp_contact)
                  all_contacts.push(temp_contact)
                })

              
                set_all_gads_merch_connections(all_contacts)

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
                */
              
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
           
      )

    })


  }


  function set_dialog_loading(is_loading){

    const temp_contact_state = {...link_dialog_state}

    temp_contact_state.isLoading = is_loading

    set_link_dialog_state(temp_contact_state)

  }

  function reset_gads_dialog_state(){

    set_link_dialog_state(cloneDeep(default_link_dialog))

  }


  function handle_gads_configuration_update(index, action_type){

    const temp_config = {...all_gads_merch_connections[index]}

    console.log('config was: ', temp_config)

    
    var request_body = {}

    if(action_type === "update"){

      var budget = temp_config.adRules.budget ? temp_config.adRules.budget : ""

      var roas = temp_config.adRules.roas ? temp_config.adRules.roas : ""
      
      budget = parseFloat(budget)

      roas = parseFloat(roas)

      console.log("value were: ", budget, " : ", roas)
      
      if(isNaN(budget) || isNaN(roas)){
        cmsContext.setSnackBarOpen(true, "error", "Please enter a valid values for budget and ROAS!")
        return
      }

      console.log(temp_config)
      
    }

    request_body.operation = action_type

    request_body.email = cmsContext.current_shop.email

    request_body.merchant_id = temp_config.merchant_id

    request_body.gads_id = temp_config.gads_id

    request_body.adRules = temp_config.adRules

    console.log('body was: ', request_body)

    setIsLoading(true)

  
    getAccessJwtToken().then((auth_creds)=>{

      if(!has_marketing_write_access(auth_creds.current_user)){
      cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
      setIsLoading(false)
      return
    }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/gads/shopping_ads/max_performance', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              setIsLoading(false)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })




    console.log('update')

  }



  function handle_merchant_gads_update(selected_merchant_account){


    if(Object.keys(selected_merchant_account).length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid merchant account!")
      return
    }

    var has_target = false

    selected_merchant_account.gads_accounts.forEach((account) =>{
      if(account.is_checked){
        has_target = true
      }
    })

    if(!has_target){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid Google Ads Account!")
      return
    }

    console.log(selected_merchant_account)

    
    set_dialog_loading(true)


    console.log('updating')

    var request_body = {}

    request_body.merchant_account = selected_merchant_account


    console.log('body was: ', request_body)
    
  
    getAccessJwtToken().then((auth_creds)=>{

      if(!has_marketing_write_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        set_dialog_loading(false)
        return
      }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/gads/merchant_links', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              set_dialog_loading(false)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", "Success, please refresh in a while to see results")

                reset_gads_dialog_state()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })

    

  }


  function delete_contacts(){

    /*

    console.log('delete contacts')

    var temp_contacts = [...all_contacts].filter((contact) => contact.is_checked);

    console.log('filtered were', temp_contacts)

    if(temp_contacts.length === 0){
      // might never execute (need to select contacts to display delete button)
      cmsContext.setSnackBarOpen(true, "error", "Please select valid contacts!")
      return;
    }else if(temp_contacts.length > 20){
      cmsContext.setSnackBarOpen(true, "error", "Please select a maximum of 20 contacts!")
      return;
    }

    temp_contacts = temp_contacts.map((contact) => {

      return({'id': contact.id})

    })

    console.log('final contacts were', temp_contacts)

    const request_body = {}

    request_body.contacts = temp_contacts

    request_body.request_type = "delete"

    request_body.account_email = cmsContext.current_shop.email
    

    setIsLoading(true)

    getAccessJwtToken().then((jwt)=>{


      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/contacts',{
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was',result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_contacts()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {
                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)
                }
          );
  
      });
    */
  }
  

};


export default GoogleAds;