import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Avatar,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stepper,
  Step,
  StepLabel
} from '@mui/material';
import { format } from "date-fns";
import { UserCircle as UserCircleIcon } from '../../../../icons/user-circle';
import LoadingButton from '@mui/lab/LoadingButton';
import CmsContext from '../../../../store/cms-context';
import cloneDeep from 'lodash/cloneDeep';
import MintsoftCountries from '../../../../Utilities/MintsoftCountries';
import { parseDouble } from '../../../../Utilities/HelperFunctions';

// Define steps
var steps = ["Style", "Variation"];

function CreateProductDialog(props) {

  console.log("create product dialog state was: ", props)

  const cmsContext = React.useContext(CmsContext);

  if(props.create_product_dialog_state.type === "Deploy"){

    steps = ["Style", "Variation", "Deployment"]

  }



  // Handle next step
  const handleNext = () => {

    if (props.create_product_dialog_state.activeStep === steps.length - 1) {
      // If it's the last step, handle the final action (e.g., deploy)
      if (props.create_product_dialog_state.type === "Deploy") {
        handle_product("Deploy");
      } else {
        handle_create_product("Create");
      }
      return;
    }

    const temp_stat = { ...props.create_product_dialog_state };
    temp_stat.activeStep = temp_stat.activeStep + 1;
    props.set_create_product_dialog_state(temp_stat);

  };

  // Handle previous step
  const handleBack = () => {
    const temp_stat = { ...props.create_product_dialog_state };
    temp_stat.activeStep = temp_stat.activeStep - 1;
    props.set_create_product_dialog_state(temp_stat);
  };

  // Handle closing the dialog
  const handle_close = () => {
    const temp_state = { ...props.create_product_dialog_state };
    temp_state.is_dialog_open = false;
    props.set_create_product_dialog_state(temp_state);
  };

  // Handle style creation/update
  const handle_create_product = (request_type) => {

    let temp_state = cloneDeep(props.create_product_dialog_state);

    console.log('handle product state was: ', temp_state)

    // Validation and data preparation
    if (temp_state.style_id < 0) {
      cmsContext.setSnackBarOpen(true, "error", "Invalid Style!");
      return;
    }

    const barcode = temp_state.product.barcode.trim();
    const size = temp_state.product.size.trim();
    const weight = parseDouble(temp_state.product.weight);
    const category = temp_state.product.category;
    const style = temp_state.product.style;
    const hs_code = temp_state.product.hs_code.trim();
    const colour = temp_state.product.colour.trim();
    const customs_description = temp_state.product.customs_description.trim();
    const country_of_origin = temp_state.product.country_of_origin.Code;
    const carton_quantity = parseInt(temp_state.product.carton_quantity.toString());
    const dye_batch_code = temp_state.product.dye_batch_code.trim();
    const cost_price_gbp = parseDouble(temp_state.product.cost_price_gbp.toString());
    const cost_price_eur = parseDouble(temp_state.product.cost_price_eur.toString());
    const cost_price_usd = parseDouble(temp_state.product.cost_price_usd.toString());
    const cost_price_cad = parseDouble(temp_state.product.cost_price_cad.toString());

    const sale_price_gbp = parseDouble(temp_state.product.sale_price_gbp.toString());
    const sale_price_eur = parseDouble(temp_state.product.sale_price_eur.toString());
    const sale_price_usd = parseDouble(temp_state.product.sale_price_usd.toString());
    const sale_price_cad = parseDouble(temp_state.product.sale_price_cad.toString());


    if(!size){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid size!");
      return;
    }

    if(!colour){
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid colour!");
      return;
    }

    var sku;

    if(category && style && colour && size){

      sku = `SNAG-${category}-${style}-${colour}-${size}`

    }else{
      cmsContext.setSnackBarOpen(true, "error", "Can't generate sku from invalid Product!");
      return;
    }


    if (barcode.length === 0) {
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid barcode!");
      return;
    }

    if (!weight || weight < -1) {
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid weight!");
      return;
    }


    if (temp_state.product.launch_date) {

      try {
        temp_state.product.launch_date = format(temp_state.product.launch_date, "yyyy-MM-dd");
      } catch (error) {
        console.log("parse error was:", error);
        cmsContext.setSnackBarOpen(true, "error", "Please enter a valid date!");
        return;
      }

    }


    // continue here handle post


    // Submit data
    props.post_product(
      {
        product_id: temp_state.style_id,
        size: size,
        sku: sku,
        barcode: barcode,
        weight: weight,
        hs_code: hs_code,
        customs_description: customs_description,
        country_of_origin: country_of_origin,
        carton_quantity: carton_quantity,
        dye_batch_code: dye_batch_code,
        cost_price_gbp: cost_price_gbp,
        cost_price_eur: cost_price_eur,
        cost_price_usd: cost_price_usd,
        cost_price_cad: cost_price_cad
      },
      temp_state.default_type,
      request_type
    );

  };

  // Handle product deployment

  const handle_product = (request_type) => {

    let temp_state = cloneDeep(props.create_product_dialog_state);

    // Validation and data preparation
    if (!(temp_state.product && temp_state.product.id > -1)) {
      cmsContext.setSnackBarOpen(true, "error", "Invalid Product!");
      return;
    }

    if (request_type === "Deploy") {

      if (!(temp_state.selected_deployment && temp_state.selected_deployment.name)) {
        cmsContext.setSnackBarOpen(true, "error", "Please select a deployment option!");
        return;
      }

      if (temp_state.selected_deployment.type === "sage_products") {

        if (!(temp_state.selected_deployment.sage_site && temp_state.selected_deployment.sage_site.site_id.length > 0)) {
          cmsContext.setSnackBarOpen(true, "error", "Please select a valid Sage Site!");
          return;
        }

        if (!(temp_state.product.sage_product_code && temp_state.product.sage_product_code.toString().length > 0)) {
          cmsContext.setSnackBarOpen(true, "error", "Sage Product Code can't be undefined!");
          return;
        }

        if (!(temp_state.product.sage_product_group && temp_state.product.sage_product_group.code && temp_state.product.sage_product_group.code.length > 0)) {
          cmsContext.setSnackBarOpen(true, "error", "Sage Product Group can't be undefined!");
          return;
        }

        // Prepare for Sage deployment
        temp_state.product.sage_product_code = temp_state.product.sage_product_code.toString();
        
        temp_state.product.sage_product_group = temp_state.product.sage_product_group.code;

        // Submit data
        props.post_product(temp_state, request_type);

      } else if (temp_state.selected_deployment.type === 'mintsoft_products') {

        if(!(temp_state.selected_deployment.mintsoft_client && temp_state.selected_deployment.mintsoft_client.id)) {

          cmsContext.setSnackBarOpen(true, "error", "Please select a valid mintsoft client!");
          return;

        }

        // Submit data
        props.post_product(temp_state, request_type);

      }  else {

        // Handle other platforms here
        return;

      }

    }

  };

  // Update product state
  const set_product_state= (key, value) => {
    const temp_state = { ...props.create_product_dialog_state };
    temp_state.product[key] = value;
    props.set_create_product_dialog_state(temp_state);
  };

  // Render step content
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="space-y-4">

            <Grid sx={{marginTop:0}} container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Name"
                  value={props.create_product_dialog_state.product.name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Category"
                  value={props.create_product_dialog_state.product.category}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Style"
                  value={props.create_product_dialog_state.product.style}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Colour"
                  value={props.create_product_dialog_state.product.colour}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Greige Code"
                  value={props.create_product_dialog_state.product.greige_code}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Launch Collection"
                  value={props.create_product_dialog_state.product.launch_collection}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Launch Date"
                  value={props.create_product_dialog_state.product.launch_date}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Lifecycle Status"
                  value={props.create_product_dialog_state.product.lifecycle_status}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Reorder Status"
                  value={props.create_product_dialog_state.product.reorder_status}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Manufacture"
                  value={props.create_product_dialog_state.product.manufacture}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Seasonal Category"
                  value={props.create_product_dialog_state.product.seasonal_category}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Fabric Composition"
                  value={props.create_product_dialog_state.product.fabric_composition}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Care Instructions"
                  value={props.create_product_dialog_state.product.care_instructions}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  type="number"
                  label="Sale Price (GBP)"
                  value={props.create_product_dialog_state.product.sale_price_gbp}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  type="number"
                  label="Sale Price (EUR)"
                  value={props.create_product_dialog_state.product.sale_price_eur}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  type="number"
                  label="Sale Price (USD)"
                  value={props.create_product_dialog_state.product.sale_price_usd}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  type="number"
                  label="Sale Price (CAD)"
                  value={props.create_product_dialog_state.product.sale_price_cad}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Xdepartment"
                  value={props.create_product_dialog_state.product.x_department}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Xproduct Group"
                  value={props.create_product_dialog_state.product.x_product_group}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Xproduct Type"
                  value={props.create_product_dialog_state.product.x_product_type}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Xstyle"
                  value={props.create_product_dialog_state.product.x_style}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Sage Product Code"
                  value={props.create_product_dialog_state.product.sage_product_code}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Sage Product Group"
                  value={props.create_product_dialog_state.product.sage_product_group?.code || ""}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        );
      case 1:
        return (
            <>

            <Grid sx={{marginTop:0}} container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  disabled={props.create_product_dialog_state.type === "Deploy"}
                  label="Size"
                  value={props.create_product_dialog_state.product.size}
                  onChange={(e) => set_product_state("size", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={props.create_product_dialog_state.type === "Deploy"}
                  label="Barcode"
                  value={props.create_product_dialog_state.product.barcode}
                  onChange={(e) => set_product_state("barcode", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={props.create_product_dialog_state.type === "Deploy"}
                  type="number"
                  label="Weight (KG)"
                  value={props.create_product_dialog_state.product.weight}
                  onChange={(e) => set_product_state("weight", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={props.create_product_dialog_state.type === "Deploy"}
                  label="HS Code"
                  value={props.create_product_dialog_state.product.hs_code}
                  onChange={(e) => set_product_state("hs_code", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={props.create_product_dialog_state.type === "Deploy"}
                  label="Customs Description"
                  value={props.create_product_dialog_state.product.customs_description}
                  onChange={(e) => set_product_state("customs_description", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  disabled={props.create_product_dialog_state.type === "Deploy"}
                  options={MintsoftCountries}
                  onChange={(event, newValue) => {
                    set_product_state("country_of_origin", newValue);
                  }}
                  isOptionEqualToValue={(option, value) => option.Code === value.Code}
                  getOptionLabel={(option) => option.Name || ""}
                  value={props.create_product_dialog_state.product.country_of_origin}
                  renderInput={(params) => (
                    <TextField {...params} label="Country of Origin" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={props.create_product_dialog_state.type === "Deploy"}
                  type="number"
                  label="Carton Quantity"
                  value={props.create_product_dialog_state.product.carton_quantity}
                  onChange={(e) => set_product_state("carton_quantity", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={props.create_product_dialog_state.type === "Deploy"}
                  label="Dye Batch Code"
                  value={props.create_product_dialog_state.product.dye_batch_code}
                  onChange={(e) => set_product_state("dye_batch_code", e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  disabled={props.create_product_dialog_state.type === "Deploy"}
                  type="number"
                  label="Cost Price GBP"
                  value={props.create_product_dialog_state.product.cost_price_gbp}
                  onChange={(e) => set_product_state("cost_price_gbp", e.target.value)}
                  fullWidth
                />
              </Grid>
             
              <Grid item xs={6}>
                <TextField
                  disabled={props.create_product_dialog_state.type === "Deploy"}
                  type="number"
                  label="Cost Price EUR"
                  value={props.create_product_dialog_state.product.cost_price_eur}
                  onChange={(e) => set_product_state("cost_price_eur", e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={props.create_product_dialog_state.type === "Deploy"}
                  type="number"
                  label="Cost Price USD"
                  value={props.create_product_dialog_state.product.cost_price_usd}
                  onChange={(e) => set_product_state("cost_price_usd", e.target.value)} 
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={props.create_product_dialog_state.type === "Deploy"}
                  type="number"
                  label="Cost Price CAD"
                  value={props.create_product_dialog_state.product.cost_price_cad}
                  onChange={(e) => set_product_state("cost_price_cad", e.target.value)}
                  fullWidth
                />
              </Grid>
            
            </Grid>
            </>
        );
      case 2:
        return (
          <>

            <Grid sx={{marginTop:0}} container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Deployment Platform</InputLabel>
                  <Select
                    value={props.create_product_dialog_state.selected_deployment}
                    onChange={(e) => {
                      const tempState = { ...props.create_product_dialog_state };
                      tempState.selected_deployment = e.target.value;
                      props.set_create_product_dialog_state(tempState);
                    }}
                    label="Deployment Platform"
                  >
                    {props.create_product_dialog_state.all_deployment_options.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {props.create_product_dialog_state.selected_deployment?.type === "sage_products" && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Sage Site</InputLabel>
                    <Select
                      value={props.create_product_dialog_state.selected_deployment.sage_site}
                      onChange={(e) => {
                        const tempState = { ...props.create_product_dialog_state };
                        tempState.selected_deployment.sage_site = e.target.value;
                        props.set_create_product_dialog_state(tempState);
                      }}
                      label="Sage Site" 
                    >
                      {props.create_product_dialog_state.configs.sage_sites.map((site, index) => (
                       // For test site only <MenuItem disabled={site.company_id !== 8} key={index} value={site}>
                       <MenuItem key={index} value={site}>
                          {site.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {props.create_product_dialog_state.selected_deployment?.type === "shopify_products" && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Shopify Store</InputLabel>
                    <Select
                      value={props.create_product_dialog_state.selected_deployment.shopify_store}
                      onChange={(e) => {
                        const tempState = { ...props.create_product_dialog_state };
                        tempState.selected_deployment.shopify_store = e.target.value;
                        props.set_create_product_dialog_state(tempState);
                      }}
                      label="Shopify Store" 
                    >
                      {props.create_product_dialog_state.configs.shopify_stores.map((store, index) => (
                        <MenuItem key={index} value={store}>
                          {store.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {props.create_product_dialog_state.selected_deployment?.type === "mintsoft_products" && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Mintsoft Client</InputLabel>
                    <Select
                      value={props.create_product_dialog_state.selected_deployment.mintsoft_client}
                      onChange={(e) => {
                        const tempState = { ...props.create_product_dialog_state };
                        tempState.selected_deployment.mintsoft_client = e.target.value;
                        props.set_create_product_dialog_state(tempState);
                      }}
                      label="Mintsoft Client" 
                    >
                      {props.create_product_dialog_state.configs.mintsoft_clients.map((client, index) => (
                        <MenuItem key={index} value={client}>
                          {client.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </>
        );
      default:
        return <div>Unknown step</div>;
    }
  };

  return (
    <Dialog
      open={props.create_product_dialog_state.is_dialog_open}
      onClose={handle_close}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {props.create_product_dialog_state.type} Product
      </DialogTitle>
      <DialogContent dividers>
         <Avatar
          sx={{ height: 120, width: 120, ml: 'auto', mr:'auto' }}
          src="/static/images/prototype.png"
          className="shadow-lg"
        >
          <UserCircleIcon fontSize="large" />
        </Avatar>
        <Stepper sx={{marginTop:4}} activeStep={props.create_product_dialog_state.activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="mt-4" style={{marginTop:10}}>{renderStepContent(props.create_product_dialog_state.activeStep)}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handle_close}>Cancel</Button>
        {props.create_product_dialog_state.activeStep > 0 && (
          <Button onClick={handleBack}>Back</Button>
        )}
        <LoadingButton
          variant="contained"
          onClick={handleNext}
          loading={props.create_product_dialog_state.isLoading}
        >
          {props.create_product_dialog_state.activeStep === steps.length - 1 ? props.create_product_dialog_state.type : "Next"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateProductDialog;