import React, { useState, useRef, useEffect, useContext, useCallback } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../../store/cms-context';

import { getAccessJwtToken } from '../../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { default_carrier_mapping_dialog } from "../../../Utilities/Metrics";

import cloneDeep from 'lodash/cloneDeep';

import MintsoftOrderSplitDialog from "./SubComponents/CarrierMappingDialog";

import MintsoftOrderSplitsTable from "./SubComponents/ CarrierMappingsTable";

import CurrenciesInfo from "../../../Utilities/CurrenciesInfo";

import CarrierMappingsTable from "./SubComponents/ CarrierMappingsTable";
import CarrierMappingDialog from "./SubComponents/CarrierMappingDialog";
import { has_admin_access } from "../../../Utilities/HelperFunctions";




function Carriers(props) {

  console.log("Carriers rendered")

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [carriers_data, set_carriers_data] = useState([]);

  const [all_carrier_mappings, set_all_carrier_mappings] = useState([])

  const [carrier_mapping_dialog_state, set_carrier_mapping_dialog_state] = React.useState(cloneDeep(default_carrier_mapping_dialog));


  useEffect(() => {

    isMountedRef.current = true

    get_carrier_data()

    return () => isMountedRef.current = false;

  }, [])


 

  function show_create_carrier_mapping_dialog() {
    
    const temp_dialog_state = cloneDeep(default_carrier_mapping_dialog);

    temp_dialog_state.carriers_data = carriers_data

    temp_dialog_state.is_dialog_open = true

    temp_dialog_state.type = "Create"

    set_carrier_mapping_dialog_state(temp_dialog_state);

  }


  const delete_carrier_mappings = useCallback(() => {
    
    console.log('delete carrier_mappings')

    var temp_carrier_mappings = [...all_carrier_mappings].filter((carrier_mapping) => carrier_mapping.is_checked);

    console.log('filtered were', temp_carrier_mappings)
    
    if(temp_carrier_mappings.length === 0){

      cmsContext.setSnackBarOpen(true, "error", "Please select valid carrier mappings!")
      return;

    }


    temp_carrier_mappings = temp_carrier_mappings.map((carrier_mapping) => {

      return(carrier_mapping.id)

    })

    console.log('final mintsoft order splits were', temp_carrier_mappings)

    const request_body = {}

    request_body.mapping = temp_carrier_mappings

    request_body.request_type = "Delete"

    request_body.account_email = cmsContext.current_shop.email

    console.log('delete body was: ', request_body)

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds) => {

      if(!has_admin_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
      }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/carriers/mappings', {
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':auth_creds.jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was: ', result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_carrier_data()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {

                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)

                }
          );
  
      });

  }, [all_carrier_mappings]);


  const set_dialog_carrier_mapping = useCallback((carrier_mapping, is_open) => {

      const temp_carrier_mapping_dialog_state = cloneDeep(default_carrier_mapping_dialog)

      temp_carrier_mapping_dialog_state.carrier_mapping.id = carrier_mapping.id

      //temp_carrier_mapping_dialog_state.carrier_mapping.source = carrier_mapping.source

      temp_carrier_mapping_dialog_state.carrier_mapping.target = carrier_mapping.target

      temp_carrier_mapping_dialog_state.carrier_mapping.source_carrier = carrier_mapping.source_carrier_name

      temp_carrier_mapping_dialog_state.carrier_mapping.target_carrier = carrier_mapping.target_carrier_name

      temp_carrier_mapping_dialog_state.carrier_mapping.target_type = carrier_mapping.target_type

      temp_carrier_mapping_dialog_state.carriers_data = carriers_data

      temp_carrier_mapping_dialog_state.is_dialog_open = is_open

      temp_carrier_mapping_dialog_state.type = "Update"

      console.log("new state was: ", temp_carrier_mapping_dialog_state)

      set_carrier_mapping_dialog_state(temp_carrier_mapping_dialog_state)

     // get_shopify_carriers(carrier_mapping.source)

      //get_mintsoft_couriers(carrier_mapping.target)


  }, [carriers_data])


  const set_dialog_loading = useCallback(( is_loading ) => {

    const temp_carrier_mapping_dialog_state = {...carrier_mapping_dialog_state}

    temp_carrier_mapping_dialog_state.isLoading = is_loading

    console.log('dialog state was: ', temp_carrier_mapping_dialog_state)

    set_carrier_mapping_dialog_state(temp_carrier_mapping_dialog_state)

  },[carrier_mapping_dialog_state])



  const reset_dialog_state = useCallback((is_loading) => {

    set_carrier_mapping_dialog_state(cloneDeep(default_carrier_mapping_dialog))

  },[])





  const post_carrier_mapping = useCallback((carrier_mapping, request_type) => {

    set_dialog_loading(true)

    console.log('updating')

    var request_body = {}

    request_body.mapping = carrier_mapping

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    getAccessJwtToken().then((auth_creds) => {

      if(!has_admin_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        set_dialog_loading(false)
        return
      }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/carriers/mappings', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                reset_dialog_state()
                
                get_carrier_data()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_dialog_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
                set_dialog_loading(false)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }, [reset_dialog_state, set_dialog_loading, cmsContext]);






  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px", overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Carrier Mappings</Typography>

          <div>

          <Button variant="contained" onClick = {() => {show_create_carrier_mapping_dialog()}}>Create Mapping</Button>

          </div>

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >


          {all_carrier_mappings ? <CarrierMappingsTable delete_carrier_mappings={delete_carrier_mappings} set_dialog_carrier_mapping={set_dialog_carrier_mapping} all_carrier_mappings={all_carrier_mappings} set_all_carrier_mappings={set_all_carrier_mappings}>

          </CarrierMappingsTable>  : null}

        </Grid>

      </Grid>

    </Container>      

    </Box>

     }

     {carrier_mapping_dialog_state ? <CarrierMappingDialog all_carrier_mappings={all_carrier_mappings} set_all_carrier_mappings={set_all_carrier_mappings} post_carrier_mapping={post_carrier_mapping} carrier_mapping_dialog_state={carrier_mapping_dialog_state} set_carrier_mapping_dialog_state={set_carrier_mapping_dialog_state} get_shopify_carriers={get_shopify_carriers} get_mintsoft_couriers={get_mintsoft_couriers}></CarrierMappingDialog> : null}

    </> 


  );


  function get_shopify_carriers(store) {

    console.log('store_access_token')

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/shopify/carriers',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt,
            'store': store
          }})
          .then(res => res.json())
          .then(
            (result) => {
            
              setIsLoading(false)
  
              console.log('result was: ', result)
            
              if(result.carrier_services) {

                set_carrier_mapping_dialog_state((previous_carrier_mapping_dialog_state) => {

                  const temp_state = {...previous_carrier_mapping_dialog_state}

                  temp_state.source_shipping_carriers = result.carrier_services

                  return set_carrier_mapping_dialog_state(temp_state)

                })

              } else if (result.errorMessage) {
                
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else {

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
              
            },
        
            (error) => {

              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)

            }
          
      )

    })

  }


  function get_mintsoft_couriers(mintsoft_client) {

    console.log('store_access_token')

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/mintsoft/couriers',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt,
            'mintsoft_client': mintsoft_client
          }})
          .then(res => res.json())
          .then(
            (result) => {
            
              setIsLoading(false)
  
              console.log('result was: ', result)
            
              if(result.couriers) {

                set_carrier_mapping_dialog_state((previous_carrier_mapping_dialog_state) => {

                  var temp_carrier_mapping_dialog_state = {...previous_carrier_mapping_dialog_state}

                  temp_carrier_mapping_dialog_state.target_shipping_carriers = result.couriers

                  return set_carrier_mapping_dialog_state(temp_carrier_mapping_dialog_state)

                })

              } else if (result.errorMessage) {
                
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else {

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
              
            },
        
            (error) => {

              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)

            }
          
      )

    })

  }


  function get_carrier_data() {

    console.log("get carrier data called")
    
    console.log('store_access_token')

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/carriers/mappings',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
            
              setIsLoading(false)
  
              console.log('result was',result)
             
              if(result.data) {

                if(result.data.carrier_mappings.length === 0) {

                  cmsContext.setSnackBarOpen(true, "info", "No mappings yet. Let's create one to get started.")

                }

                set_carriers_data(result.data)

                result.data.carrier_mappings.forEach((carrier) => {

                  carrier.is_checked = false

                })

                set_all_carrier_mappings(result.data.carrier_mappings)


              }else if(result.errorMessage){
                
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
              
            },
        
            (error) => {

              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)

            }
           
      )

    })


  }

};

export default Carriers;