import React from 'react';

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, IconButton, ButtonGroup } from '@mui/material';

import { useState, useRef, useEffect, useContext, useCallback } from "react";

import Editor from '../Products/CreateProduct/SubComponents/Editor';

import CreateCollectionSideExtras from './CreateCollectionSideExtras';

import CollectionTypeAndConditions from './CollectionTypeAndConditions';

import SearchEngineListingPreview from '../Products/CreateProduct/SubComponents/SearchEngineListingPreview';

import { getAccessJwtToken } from '../../store/globalUtilities';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import CmsContext from '../../store/cms-context';

import { Storage } from 'aws-amplify';

import { useNavigate } from 'react-router-dom';

import CollectionProducts from './CollectionProducts';

import SelectProductsDialog from '../SelectProductsDialog/SelectProductsDialog';

import { sort_options } from '../../Utilities/Metrics';
import { has_product_management_access, has_product_management_write_access } from '../../Utilities/HelperFunctions';

function CreateCollection(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false)

  const [title, settitle] = useState('')

  const [ck_state, set_ck_state] = useState('')

  const [initial_collections_data, set_initial_collections_data] = useState({"publications":[],"templates":[]})

  const [selected_collection_template, set_selected_collection_template] = useState('')

  const [schedule_date, set_schedule_date] = useState({'date': new Date().setTime((new Date()).getTime() + (1*60*60*1000)), 'should_schedule':false})

  const [collection_conditions, set_collection_conditions] = useState([{'condition_key':'', 'condition_type':'','condition_value':''}])

  const [imageFile, setImageFile] = useState({})

  const [collection_type, set_collection_type] = useState("automated")

  const [should_match, set_should_match] = useState("all")

  const [collection, set_collection] = useState({})
  
  const [page_title, set_page_title] = useState('')

  const [meta_description, set_meta_description] = useState('')

  const [url_handle_text, set_url_handle_text] = useState('')

  const [collection_products, set_collection_products] = useState([])

  const [all_products, set_all_products] = useState([])

  const [isProductsLoading, setIsProductsLoading] = React.useState(false)

  const [isAllProductsLoading, setIsAllProductsLoading] = React.useState(false)

  const [sort_order, set_sort_order] = React.useState(0)

  const [product_dialog_open, set_product_dialog_open] = React.useState(false)

  const [search_value, set_search_value] = React.useState('') 



  let navigate = useNavigate();



  useEffect(()=>{

    isMountedRef.current = true

    if(!isLoading){

    if(props.type === "Edit"){

      get_collection_data("Edit")

    }else if(props.type === "Create"){

      get_collection_data("Create")

    }else{
      
      process_collection_data(props.variation)

    }

  }

    return () => isMountedRef.current = false;

  },[])


  useEffect(() =>{

    console.log('changed collection was: ', collection)

  },[collection])



  useEffect(() =>{

    if(collection_products.length > 0 && collection_type === "manual"){


    set_collection_products((previous_products) =>{

      if(previous_products !== null){

      const temp_products = [...previous_products]

      if(sort_options[sort_order] === "Product title A-Z"){

      temp_products.sort(function(first, second) {
        return first.title.localeCompare(second.title)
       });

      }else if(sort_options[sort_order] === "Product title Z-A"){

        temp_products.sort(function(first, second) {
          return second.title.localeCompare(first.title)
         });

      }
      else if(sort_options[sort_order] === "Product title Z-A"){

        temp_products.sort(function(first, second) {
          return second.title.localeCompare(first.title)
         });

      }
  
       console.log("sorted products were: ", temp_products)
  
       if(temp_products.length > 0){
        return set_collection_products(temp_products)
       }

      }

    })


    }

  },[sort_order])




  useEffect(() => {

    console.log('ck state was', ck_state)

  },[ck_state])

  useEffect(() => {

    console.log("initial data was", initial_collections_data)

  },[initial_collections_data])


  useEffect(() => {

    if(collection.id){

      console.log("Getting collection products")

      //get_collection_products("automated")

    }

  },[collection])


  const editor_ready = () => {

    if(collection && collection.ck_state){

      console.log('html was', collection.ck_state)
      set_ck_state(collection.ck_state)

    }else{
      console.log("no html")
    }

  }

  useEffect(() =>{

    if(product_dialog_open){

      if(all_products.length === 0){
        get_collection_products("Create")
      }else{
        sync_selected_products(null)
      }

    }

  },[product_dialog_open])


  function sync_selected_products(force_products) {


    const temp_products = [...collection_products];


    set_all_products((previous_all_products) =>{

      var temp_all_products;

      if(force_products != null){

      temp_all_products = [...force_products]

      }else{

        temp_all_products = [...previous_all_products]

      }

      var found = false;

        for(var z=0; z<temp_all_products.length;z++){
        found = false;
        for(var i=0; i<temp_products.length;i++){

          if(temp_all_products[z].id === temp_products[i].id){
            found = true;
          }
        }
        temp_all_products[z].is_checked = found;
      }

      return set_all_products(temp_all_products)

    })


  }

  async function get_file_url(image){



    const url = await Storage.get(image.aws_key, {
      level: 'public',
      bucket: 'snag-cms175447-staging',
      expires:86400,
      region: 'eu-west-2',
    });

    image.originalSource = url
    
    setImageFile(image)


  }


  function reset_page(delayed){

    
      setTimeout(function() { //Start the timer
        window.location.reload();
    }, delayed?1000:1)

  }

  const delete_image_file = useCallback(async(file) => {

    console.log('deleting file', file , file.modified_name)

    if(props.type === "Create"){

      try {

        console.log('remove key', cmsContext.current_shop.email + "/images/" + file.modified_name)
        
        const result = await Storage.remove(cmsContext.current_shop.email + "/images/" + file.modified_name);

        console.log('delete result was: ' , result)

        setImageFile({})
  
      } catch (error) {

        cmsContext.setSnackBarOpen(true, "error", error)

        console.log('error deleting some files')

      }

    }else{
      setImageFile({})
    }

    return;

  }, [cmsContext, props.type])




  return(

  
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
        display: props.is_enabled ? "block" : "none"
      }}
    >

      {!isLoading && Object.keys(initial_collections_data).length > 0 ?


      <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
    
      <Grid
          container
          spacing={2}
          style={{overflow:'visible'}}
        >

          <Grid
            item
            xs={12}
            md={props.type === "Deploy" ? 8 : 12}
          >

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={2}
          >

            <div style={{display:'flex', alignItems:'center'}}>
              
            {props.type === "Edit" ? <IconButton aria-label="delete" color="primary" onClick={() => {props.set_component_state({layout_type: 'list', collection_id : ''})}}>
              <ArrowBackIcon />
            </IconButton> : null}

            <Typography variant="h5">Collections / {props.type}</Typography> 

            </div>

            {props.type !== "Deploy" ? <Button variant="contained" disabled = {title.trim().length === 0} onClick = {() => {sendCollectionRequest(props.type === "Edit" ? "Update" : "Create")}}>{props.type === "Edit" ? "Update" : props.type === "Deploy" ? "Deploy" : "Create"} Collection</Button> : null}

          </Stack>

        </Grid>

        {props.type === "Deploy" ? <Grid
          item
          xs={12}
          md={4}
        >

            <ButtonGroup disableElevation variant="contained" >
            <Button onClick = {() => {sendCollectionRequest("Delete")}}>{props.product_type === "Master" ? "Delete": "Delete"}</Button>
            <Button onClick = {() => {sendCollectionRequest("Save")}}>Save</Button>
            <Button onClick = {() => {sendCollectionRequest("Deploy")}}>{props.product_type === "Master" ? "Deploy all": "Deploy"}</Button>
            </ButtonGroup>

        </Grid> : null}

          <Grid
            item
            xs={12}
            md={8}
          >


        <Grid
          container
          spacing={3}
          alignItems="center"
         >

          <Grid
            item
            xs={12}
          >

          <Card elevation={2} style={{padding:20,overflow:'visible'}} >

          <TextField id="outlined-basic" label="Title" variant="outlined" value={title} onChange={(event)=>{settitle(event.target.value);}} InputLabelProps={{ shrink: true }} fullWidth/>

          <Typography variant='subtitle1' style={{marginTop:20}}>Description</Typography>

          <Editor editor_ready = {editor_ready} ck_state={ck_state} set_ck_state={set_ck_state}></Editor>

        </Card>

        </Grid>
    
        </Grid>


        <CollectionTypeAndConditions type_disabled = {props.type !== "Create"} should_match={should_match} set_should_match={set_should_match} collection_type = {collection_type} set_collection_type = {set_collection_type} collection_conditions={collection_conditions} set_collection_conditions={set_collection_conditions}></CollectionTypeAndConditions>

       {(collection_type === "manual" || props.type !== "Create") ? <CollectionProducts remove_collection_product={remove_collection_product} collection_type={collection_type} search_value={search_value} set_search_value={set_search_value} sort_order={sort_order} set_sort_order={set_sort_order} set_product_dialog_open={set_product_dialog_open} collection_type={collection_type} isProductsLoading={isProductsLoading} collection_products={collection_products} set_collection_products={set_collection_products}></CollectionProducts> : null}

        <SearchEngineListingPreview page_title={page_title} set_page_title={set_page_title} meta_description={meta_description} set_meta_description={set_meta_description} url_handle_text={url_handle_text} set_url_handle_text={set_url_handle_text} type="collection"></SearchEngineListingPreview>

      </Grid>

        <Grid
            item
            xs={12}
            md={4}
        >

          <CreateCollectionSideExtras collection={collection} type={props.type} delete_image_file={delete_image_file} initial_collections_data = {initial_collections_data} set_initial_collections_data = {set_initial_collections_data} imageFile = {imageFile} setImageFile = {setImageFile} schedule_date = {schedule_date} set_schedule_date = {set_schedule_date} selected_collection_template = {selected_collection_template} set_selected_collection_template = {set_selected_collection_template} ></CreateCollectionSideExtras>

        </Grid>


        </Grid>

        </Container>      

        : isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> : null}  



        <SelectProductsDialog isAllProductsLoading={isAllProductsLoading} search_value={search_value} set_search_value={set_search_value} reset_all_products={reset_all_products} product_dialog_open={product_dialog_open} set_product_dialog_open={set_product_dialog_open} all_products={all_products} set_all_products={set_all_products} collection_products={collection_products} set_collection_products={set_collection_products} handle_selected_products={handle_selected_products}></SelectProductsDialog>

        </Box>

  )

  function remove_collection_product(index){

    console.log("index was: ", index)

    const temp_products = [...collection_products];

    temp_products.splice(index, 1)

    set_collection_products(temp_products)

  }

  function reset_all_products(){

    const temp_products = [...all_products]

    temp_products.forEach((product) => {

      product.is_checked = false

    })

    set_all_products(temp_products)

  }

  function handle_selected_products(){

    const selected_products = []
    
    const temp_products = [...all_products]

    temp_products.forEach((product) => {

      if(product.is_checked){
        selected_products.push(product)
      }

    })

    set_collection_products(selected_products)

    set_product_dialog_open(false)

  }


  function get_collection_data(type) {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{


    const request_headers = {'store_domain': cmsContext.current_shop.store_domain, 'Authorization': auth_creds.jwt}

    if(type === "Edit"){
      request_headers.collection_id = props.collection_id
    }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/create_collection_data',{
          crossDomain:true,
          method: 'GET',
          headers: request_headers})
          .then(res => res.json())
          .then(
            (result) => {

              if(!isMountedRef.current){
                return
              }
  
              setIsLoading(false)
  
              console.log('result was', result)
  
              if(result.data && result.data.publications){

                var temp_data = {...result.data}

                temp_data = temp_data.publications.map((publication) => {
                  return(Object.assign(publication,{'is_checked':false}))
                });


                temp_data = {'publications':temp_data,'templates':[]}

                if(result.data.collection){

                  const collection = result.data.collection

                  refresh_state_from_collection(collection)
  
                  set_collection(collection)

                  if(collection.publications && collection.publications.length > 0){

                    console.log('inside previous 0')

                    console.log('inside previous')

                    temp_data.publications.forEach((publication) => {

                      collection.publications.forEach((published_publication) =>{
                        if(published_publication.id === publication.node.id){
                          publication.is_checked = true
                        }else{
                          console.log('not equals', publication.node.id, published_publication.id)
                        }
                      })

                    })

                  }
  
                }

                console.log("data was", temp_data)
                
                set_initial_collections_data(temp_data)
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{
                cmsContext.setSnackBarOpen(true, "error", "Error getting data, please try again.")
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              console.log('error was',error)
            }
      )

    })


  }

  function process_collection_data(result){


    console.log('result inner was: ' , result)


    if(result.data){

      var temp_data = {...result.data}

      temp_data.publications = temp_data.publications.map((publication) => {
        return(Object.assign(publication,{'is_checked':false}))
      }); 

      if(result.data.collection){

        const collection = result.data.collection

        refresh_state_from_collection(collection)

        if(props.collection_type !== collection.type ){

          collection.last_request_status = "New"

        }

        set_collection(collection)

       // console.log('body was', collection.descriptionHtml)

        //settitle(collection.title)
      
        if(collection.publications && collection.publications.length > 0){

          console.log('inside previous 0')

          console.log('inside previous')

          temp_data.publications.forEach((publication) => {

            collection.publications.forEach((published_publication) =>{
              if(published_publication.id === publication.node.id){
                publication.is_checked = true
              }else{
                console.log('not equals', publication.node.id, published_publication.id)
              }
            })

          })

        }

      }

      delete temp_data.collection

      console.log('temp data was ', temp_data)
      
      set_initial_collections_data(temp_data)

    
    }else{
      
      console.log('Error', result)

      cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")

    }


  }



  function sendCollectionRequest(request_type){



    const request_body = {};

  
    if(title.trim().length === 0){

      console.log('invalid title')
      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid title")
      return

    }

    const final_collection = {}
    
    if(Object.keys(imageFile).length > 0){

      final_collection.image = {
        //"src": imageFile.originalSource,
        "aws_key": imageFile.aws_key
      }

    }else{
      final_collection.image={}
    }


    if(collection_type === "automated"){

      var condition_items = [];

      var temp_condition_item = {};

      var invalid_items_found= false;

      for(var i=0; i < collection_conditions.length; i++){

        temp_condition_item = collection_conditions[i];

        // handle empty and non empty

        if(temp_condition_item.condition_value.trim().length === 0 && (temp_condition_item.condition_type !== "IS_SET" && temp_condition_item.condition_type !== "IS_NOT_SET")){
          invalid_items_found = true;
          break
        }else{
          if(temp_condition_item.condition_type === "IS_SET" || temp_condition_item.condition_type === "IS_NOT_SET"){
            temp_condition_item.condition_value = "TRUE"
          }
          //condition_items.push({'column': "shp_strt_"+temp_condition_item.condition_key+"_shp_end", 'relation': "shp_strt_"+temp_condition_item.condition_type+"_shp_end",'condition': temp_condition_item.condition_value.trim()})
          condition_items.push({'column': temp_condition_item.condition_key, 'relation': temp_condition_item.condition_type, 'condition': temp_condition_item.condition_value.trim()})
        }

      }

      if(invalid_items_found){
        cmsContext.setSnackBarOpen(true, "error", "Please enter valid condition values")
        return
      }

      console.log('conditions were', condition_items);

      final_collection.collection_rules= condition_items;
  
    }

    request_body.request_type = request_type

    const publications = [];

    initial_collections_data.publications.forEach(publication => {

      if(publication.is_checked){
        publications.push({"id": publication.node.id, "name": publication.node.name})
      }

    });


    final_collection.publications = publications;


    var final_collection_products = collection_products.map((product) =>{
      return product.id
    })

    final_collection.collection_products = final_collection_products

    final_collection.title = title;

    final_collection.ck_state = ck_state;

    final_collection.selected_collection_template = selected_collection_template;

    final_collection.schedule_date = schedule_date;

    final_collection.collection_conditions = collection_conditions;

    final_collection.imageFile = imageFile;

    final_collection.collection_type = collection_type;

    final_collection.should_match = should_match;

    final_collection.page_title = page_title;

    final_collection.meta_description = meta_description;

    final_collection.url_handle_text = url_handle_text;

    final_collection.initial_collections_data = initial_collections_data;

    final_collection.disjunctive = should_match === "any";

    final_collection.sort_order = sort_options[sort_order];

    request_body.collection = final_collection;

    if(collection.id){

      if(props.collection_type === "Master" || collection.parent){

        request_body.collection.id = collection.id

      }

    }

    if(props.collection_type === "Variation"){

      console.log('collection id was: ', collection.id)

      request_body.collection.parent = collection.parent ? collection.parent : collection.id

    }

    request_body.collection_type = props.collection_type


    console.log(JSON.stringify(request_body))

    const temp_collection = {...collection}

    console.log('body was: ', request_body)


    setIsLoading(true)


    getAccessJwtToken().then((auth_creds)=>{

      // handle expired jwt?

     if(!has_product_management_write_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
      }


    fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/collections', {
            crossDomain:true,
            method: 'POST',
            headers: {
              'email': cmsContext.current_shop.email,
              'store_domain': props.variation ? props.variation.store.store_domain : cmsContext.current_shop.store_domain,
              'content-type':'application/json',
              'Authorization': auth_creds.jwt
            },
            body: JSON.stringify(request_body)
          })
            .then(res => res.json())
            .then(
              (result) => { 

                setIsLoading(false)

                console.log('result was',result)

                if(result.message){

                  cmsContext.setSnackBarOpen(true, "success", result.message)

                  if(props.product_type !== "Master"){

                    if(request_type === "Save"){
                      temp_collection.last_request_status = "Saved"
                    }else if(request_type === "Delete"){
                      temp_collection.last_request_status = "Deleting"
                    }else{
                      temp_collection.last_request_status = "Deploying"
                    }

                  }

                  set_collection(temp_collection)

                  if(props.type !== "Deploy"){
                  reset_page(true)
                  }else{
                    if(props.collection_type === "Master"){
                      setTimeout(function(){
                        if(request_type === "Deploy" || request_type === "Save"){
                        props.get_collection_data()
                        }else{
                          reset_page()
                        }
                      }, 1000); 
                    }
                  }

                }else if(result.errorMessage){
                  cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                  return
                }else{
                  cmsContext.setSnackBarOpen(true, "error", "Error creating collection, please try again")
                  return
                }
                
              },
          
              (error) => {
                setIsLoading(false)
                cmsContext.setSnackBarOpen(true, "error", error.toString())
                console.log('error was',error)
              }
        );

    });
  
  
  }

  async function get_picture_async(key, index,type){

    const picUrl = await Storage.get(key, {
      level: 'public',
      bucket: 'snag-cms175447-staging',
      expires:86400,
      region: 'eu-west-2',
    });

    console.log('url was', picUrl)

    if(type === "all"){

      set_all_products((previous_products) =>{

        const temp_products = [...previous_products]
  
        if(index < temp_products.length && temp_products[index].media && temp_products[index].media.length > 0){
          temp_products[index].media[0].url = picUrl;
        }else{
          console.log('invalid temp products 0')
        }
  
        return(set_all_products(temp_products))
  
      })

    }else{

    set_collection_products((previous_products) =>{

      const temp_products = [...previous_products]

      console.log('temp products were ', temp_products)

      if(index < temp_products.length && temp_products[index].media && temp_products[index].media.length > 0){
        temp_products[index].media[0].url = picUrl;
      }else{
        console.log('invalid temp products 1')
      }

      return(set_collection_products(temp_products))

    })

  }

  }

  function get_collection_products(request_type) {
    
    console.log('getting collection products')

    // user api

    // error handling and state managment required

    if(request_type === "Create"){

      setIsAllProductsLoading(true)

    }else{

      setIsProductsLoading(true)

    }

  

    getAccessJwtToken().then((auth_creds)=>{


    const request_headers = {'store_domain': cmsContext.current_shop.store_domain, 'Authorization':auth_creds.jwt,'collection_id':collection.id,'collection_type':  (props.collection_id && collection.id === props.collection_id) ? "Master" : !props.collection_id ? "Master" :"Variation", 'request_type': request_type}

    console.log('headers were: ', request_headers, " : ", collection.id)

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/collections/products',{
          crossDomain:true,
          method: 'GET',
          headers: request_headers})
          .then(res => res.json())
          .then(
            (result) => {

              if(!isMountedRef.current){
                return
              }
  
              if(request_type === "Create"){

                setIsAllProductsLoading(false)
          
              }else{
          
                setIsProductsLoading(false)
          
              }
  
              console.log('result was', result)
  
              if(result.products){

                var type = "";

                if(request_type === "Create"){

                  type = "all";

                  const temp_products = [...result.products]

                  sync_selected_products(temp_products)

                }else{

                set_collection_products(result.products)

                }

                const temp_products = [...result.products]

                for(var product_index in temp_products){

                  if(temp_products[product_index].media.length > 0){
                    get_picture_async(temp_products[product_index].media[0].aws_key, product_index, type)
                  }

                }
              
          
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{
                cmsContext.setSnackBarOpen(true, "error", "Error getting data, please try again.")
              }
  
            },
        
            (error) => {
              setIsProductsLoading(false)
              console.log('error was',error)
            }
      )

    })


  }


  function refresh_state_from_collection(collection){ 

    if(collection.title){

    settitle(collection.title);

    }

    if(collection.selected_collection_template){

    set_selected_collection_template(collection.selected_collection_template);

    }

    if(collection.schedule_date){

    set_schedule_date(collection.schedule_date);

    }

    if(collection.collection_conditions){

    set_collection_conditions(collection.collection_conditions)

    }

    if(collection.imageFile){

    get_file_url(collection.imageFile)

    }

    if(collection.collection_type){

    set_collection_type(collection.collection_type);

    }

    if(collection.should_match != null){

    set_should_match(collection.should_match);

    }

    if(collection.collection_products){

      set_collection_products(collection.collection_products)

      const temp_products = [...collection.collection_products]

      for(var product_index in temp_products){

        if(temp_products[product_index].media.length > 0){
          get_picture_async(temp_products[product_index].media[0].aws_key, product_index, "collection")
        }else{
          console.log('temp product was: ',temp_products[product_index])
        }

      }

    }

  }
  


};





export default React.memo(CreateCollection);

