

export function parseDouble(input) {

    const parsed = parseFloat(input);
  
    return isNaN(parsed) ? null : parsed;
  
}



export function has_admin_access(user) {

    return user && user.groups && user.groups.includes("Admins");

}


export function has_general_access(user) {

    return user && user.groups && user.groups.includes("EveryOne");

}


export function has_marketing_access(user) {

  return user && user.groups && (user.groups.includes("Admins") || user.groups.includes("marketing_read") || user.groups.includes("marketing_write"));

}

export function has_marketing_write_access(user) {

    console.log("cognito user was: ", user)

    return user && user.groups && (user.groups.includes("Admins") || user.groups.includes("marketing_write"));
  
  }


export function has_product_management_access(user) {

    return user && user.groups && (user.groups.includes("Admins") || user.groups.includes("product_management_read") || user.groups.includes("product_management_write"));
  
}

export function has_product_management_write_access(user) {

    return user && user.groups && (user.groups.includes("Admins") || user.groups.includes("product_management_write"));
  
}

