import React, { useState, useRef, useEffect, useContext, useCallback } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../../store/cms-context';

import { getAccessJwtToken } from '../../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import cloneDeep from 'lodash/cloneDeep';

import RedditTaggedListTable from "../RedditTaggedListTable/RedditTaggedListTable";

import RedditDialog from "../RedditDialog/RedditDialog";

import RedditAdsCountryBreakdownListTable from "./RedditAdsCountryBreakdownListTable";

import RedditAdGroupDialog from "../RedditAdGroupDialog/RedditAdGroupDialog";

import { default_reddit_ad_group_dialog as default_ad_group_dialog } from "../../../Utilities/Metrics";
import { has_marketing_write_access } from "../../../Utilities/HelperFunctions";



const default_spend = 25;

function RedditAdsCountryBreakdowns(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_reddit_breakdowns, set_all_reddit_breakdowns] = React.useState([]);

  const [reddit_dialog_state, set_reddit_dialog_state] = React.useState({reddit: {}, is_dialog_open: false, is_dialog_loading: false});

  const [reddit_ad_group_dialog_state, set_reddit_ad_group_dialog_state] = React.useState(cloneDeep(default_ad_group_dialog));

  const [orderBy, setOrderBy] = React.useState({'cpa':'asc', 'conversions':'asc', 'spend':'asc', 'active_property':'cpa'});

  const [next_token, set_next_token] = React.useState({});

  const [timestampOrder, setTimestampOrder] = React.useState('descending');

  const [hasMore, setHasMore] = React.useState(false);

  const [calendar_open, set_calendar_open] = React.useState(false);

  const [time_range, set_time_range] = React.useState('');

  const [ranges_button_text, set_ranges_button_text] = React.useState('Time range');

  const [QueryExecutionId, setQueryExecutionId] = React.useState('');
  
  const [selected_country, set_selected_country] = React.useState('all');

  const [filter_spend, set_filter_spend] = React.useState(default_spend);

  const [filter_title, set_filter_title] = React.useState('');

  const [filter_headline, set_filter_headline] = React.useState('');

  const [filter_description, set_filter_description] = React.useState('');

  useEffect(() => {

    console.log("selected country was: ", selected_country)

  },[selected_country])


  useEffect(() => {

    isMountedRef.current = true

    get_all_reddit_breakdowns(selected_country, time_range, timestampOrder, QueryExecutionId, next_token, filter_spend, filter_title, filter_headline, filter_description, orderBy)

    return () => isMountedRef.current = false;

  }, [])


  function perform_search() {

    set_all_reddit_breakdowns([])

    get_all_reddit_breakdowns(selected_country, time_range, timestampOrder, '', '', filter_spend, filter_title, filter_headline, filter_description, orderBy)

  }


  useEffect(() => {
    perform_search()
  },[orderBy])




  
  function handle_timestamp_order_change(tOrder){

    setTimestampOrder(tOrder)

    set_all_reddit_breakdowns([])

    setQueryExecutionId('')

    set_next_token('')
    
    get_all_reddit_breakdowns(selected_country, time_range, tOrder, '', '', '', '', '', '', orderBy)

  }


  function show_create_reddit_dialog(){
    
    const temp_dialog_state = cloneDeep({});

    temp_dialog_state.is_dialog_open = true

    set_reddit_dialog_state(temp_dialog_state);

  }


  function onDateChange(ranges) {
    // ranges ...
   // alert("changed check the console log");

   if(ranges.startDate && ranges.endDate){

    set_ranges_button_text(ranges.startDate.toISOString().split('T')[0] + " - " + ranges.endDate.toISOString().split('T')[0])

    set_time_range(ranges.startDate.toISOString().split('T')[0] + "-to-" + ranges.endDate.toISOString().split('T')[0])

   }

    console.log("ranges: ", ranges);

  };

  function clear_time_range(){

    console.log("clear time range called")

    set_time_range('')

    set_ranges_button_text('Time range')

    get_all_reddit_breakdowns(selected_country, '', timestampOrder, '', '', filter_spend, filter_title, filter_headline, filter_description, orderBy)

  }

  function clearSearch() {

    set_all_reddit_breakdowns([])

    set_time_range('')
    
    set_selected_country('all')

    set_filter_spend('')

    set_filter_title('')

    set_ranges_button_text('Time range')

    setQueryExecutionId('')

    set_next_token('')

    get_all_reddit_breakdowns('', '', '', '', '', '', '', '', '', orderBy)

  }

  function perform_search() {

    set_all_reddit_breakdowns([])

    get_all_reddit_breakdowns(selected_country, time_range, timestampOrder, '', '', filter_spend, filter_title, filter_headline, filter_description, orderBy)

  }

  



  const post_ad_group = useCallback((post_reddit_ad_gp_dialog_state, all_reddit_breakdowns, reddit_ad_group_dialog_state) => {

    var request_body = {}

    const ad_group_dialog_state = {...post_reddit_ad_gp_dialog_state}

    const request_type = ad_group_dialog_state.type

    console.log("ad group dialog state from function was: ", ad_group_dialog_state)

    if(ad_group_dialog_state.adgroup.id.trim().length === 0 || ad_group_dialog_state.adgroup.id.trim().includes("'")) {

      cmsContext.setSnackBarOpen(true, "error", "Please enter a valid ad group name!")
      return

    }

    const ads = []

    // continue here: sometimes is_checked doesn't works

    // reset is_checked whenever dialog closed or any other main operation

    ad_group_dialog_state.ads.forEach((ad) => {

      const exists = ads.some(existingAd => existingAd.ad_id === ad.ad_id);

      if (!exists) {
        ads.push({'account_id': ad.account_id, 'ad_id': ad.ad_id, 'ad_group_id': ad_group_dialog_state.adgroup.name, 'name': ad.name, 'image_hash': ad.image_hash });
      }

      console.log("ads were: ", ads)

    })

    if(ads.length === 0){

      cmsContext.setSnackBarOpen(true, "error", "Please select valid ads!")
      return

    }

    request_body.ad_group = cloneDeep(ad_group_dialog_state.adgroup)

    request_body.request_type = request_type

    request_body.ads = ads

    console.log('ad group body was: ', request_body)

    toggle_ad_group_dialog(true, true, reddit_ad_group_dialog_state)

    getAccessJwtToken().then((auth_creds) => {


      if(!has_marketing_write_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
      }
      

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/ad_groups', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
          })
          .then(res => res.json())
          .then(
            (result) => {

              if(result.message){

                const temp_breakdowns = [...all_reddit_breakdowns]

                temp_breakdowns.forEach((breakdown) => {
                  breakdown.is_checked = false
                })

                set_all_reddit_breakdowns(temp_breakdowns)

                toggle_ad_group_dialog(false, false, reddit_ad_group_dialog_state)

                cmsContext.setSnackBarOpen(true, "success", result.message)

              }else if(result.errorMessage){

                toggle_ad_group_dialog(true, false, reddit_ad_group_dialog_state)

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)

              }else{

                toggle_ad_group_dialog(true, false, reddit_ad_group_dialog_state)

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")

                console.log('Error: ', result)

              }
  
            },
        
            (error) => {

              toggle_ad_group_dialog(true, false, reddit_ad_group_dialog_state)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was: ', error)

            }
          
      )

    })

  }, []);



  return(

    <>
   

   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
 
  >

    
    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Reddit Ads Country Breakdown</Typography>

          {/* <div>

          <ToggleButtons timestampOrder={timestampOrder} handle_timestamp_order_change={handle_timestamp_order_change}></ToggleButtons>

          </div> : null */}

        </Stack>

      </Grid>



      <Grid item xs={12}>

        <RedditAdsCountryBreakdownListTable clear_time_range={clear_time_range} filter_title={filter_title} set_filter_title={set_filter_title} filter_headline={filter_headline} set_filter_headline={set_filter_headline} filter_description={filter_description} set_filter_description={set_filter_description} filter_spend={filter_spend} set_filter_spend={set_filter_spend} selected_country={selected_country} set_selected_country={set_selected_country} clearSearch={clearSearch} ranges_button_text={ranges_button_text} perform_search={perform_search} onDateChange={onDateChange} calendar_open={calendar_open} set_calendar_open={set_calendar_open} timestampOrder={timestampOrder} handle_timestamp_order_change={handle_timestamp_order_change} set_dialog={set_dialog} all_reddit_breakdowns={all_reddit_breakdowns} set_all_reddit_breakdowns={set_all_reddit_breakdowns} show_ad_group_dialog={show_ad_group_dialog} orderBy={orderBy} setOrderBy={setOrderBy}></RedditAdsCountryBreakdownListTable>
        
      </Grid>



      <Grid item xs={12}>

      {isLoading ? <div style={{textAlign:'center', marginTop:40}}> <CircularProgress /> </div> : null}

      </Grid>

      <Grid item xs={12}>

      <div style={{width:'100%', textAlign:'center'}}>
      {hasMore && !isLoading ? <Button variant="contained" onClick = {() => {get_all_reddit_breakdowns(selected_country, time_range, timestampOrder, QueryExecutionId, next_token, filter_spend, filter_title, filter_headline, filter_description, orderBy)}}>Load more</Button> : null}

      </div>
      </Grid>

    </Grid>
        
    </Container>

    <RedditDialog reddit_dialog_state={reddit_dialog_state} set_reddit_dialog_state={set_reddit_dialog_state}></RedditDialog>

    <RedditAdGroupDialog post_ad_group={post_ad_group} all_reddit_breakdowns={all_reddit_breakdowns} reddit_ad_group_dialog_state={reddit_ad_group_dialog_state} set_reddit_ad_group_dialog_state={set_reddit_ad_group_dialog_state}></RedditAdGroupDialog>

    </Box>


    </> 


  );


  

  function set_dialog(reddit, is_open) {

    console.log('setting dialog reddit was: ', reddit)

    toggle_dialog(true, true, reddit)

    getAccessJwtToken().then((auth_creds) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/breakdowns', {
          crossDomain: true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt,
            'image_hash': reddit.image_hash
            
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
              toggle_dialog(true, false, reddit)
  
              if(result.breakdowns) {

                const breakdown = result.breakdowns[0]

                const dialog_state = {}

                dialog_state.reddit = cloneDeep(reddit)

                dialog_state.breakdown = breakdown

                dialog_state.is_dialog_open = is_open
            
                set_reddit_dialog_state(dialog_state)

              }else if(result.errorMessage) {

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else {

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {

              toggle_dialog(false, false, reddit)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
              
            }
           
      )

    })

  }

  function toggle_dialog(is_open, isLoading, reddit) {

    const dialog_state = {}

    dialog_state.is_dialog_open = is_open

    dialog_state.is_dialog_loading = isLoading

    dialog_state.reddit = cloneDeep(reddit)

    set_reddit_dialog_state(dialog_state)

  }


  async function get_picture_async(account_id, image_hash, creative_id, index){

    console.log('getting picture: ', account_id, " : image has : ", image_hash, index)

    getAccessJwtToken().then((auth_creds)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/images', {
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization': auth_creds.jwt,
            'source_account_id': account_id,
            'image_hash': image_hash,
            'creative_id': creative_id
          }})
          .then(res => res.json())
          .then(
            (result) => {

              console.log('result was: ', result)

              set_all_reddit_breakdowns((previous_breakdowns) => {

                const temp_breakdowns = [...previous_breakdowns]
          
                if(index < temp_breakdowns.length){

                  if(result.errorMessage){
                    temp_breakdowns[index].url = "error"
                  }else{
                    temp_breakdowns[index].url = result.url;
                  }
                  
                }
          
                return(set_all_reddit_breakdowns(temp_breakdowns))
          
              })
  
            },
        
            (error) => {
              console.log("Error")
            }
           
      )

    })


  }


  function get_all_reddit_breakdowns(selected_country, time_range, timestampOrder, QueryExecutionId , next_token, filter_spend, filter_title, filter_headline, filter_description, orderBy) {

    console.log('executing get all tags')
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    if(filter_spend.toString().trim().length === 0){

      filter_spend = default_spend;

      set_filter_spend(default_spend)

    }

    console.log("filter spend: ", filter_spend)

    console.log("order was: ", timestampOrder, timestampOrder === 'ascending')

    getAccessJwtToken().then((auth_creds)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/breakdowns', {
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt,
            'NextToken': next_token,
            'QueryExecutionId': QueryExecutionId,
            'is_ascending': timestampOrder === 'ascending' ? "ASC" : "DESC",
            'selected_country': selected_country,
            'image_hash': '',
            'time_range': time_range,
            'filter_spend': filter_spend,
            'filter_title':filter_title.trim(), 
            'filter_headline':filter_headline.trim(),
            'filter_description':filter_description.trim(),
            'orderby': orderBy['active_property'],
            'order': orderBy[orderBy['active_property']]
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
              setIsLoading(false)

              console.log('result was: ', result)

              if(result.breakdowns){

                console.log('setting analuytis')

                result.breakdowns.forEach((breakdown) =>{

                  breakdown.id = breakdown.ad_id + "_" + breakdown.country

                  breakdown.is_checked = false
                  
                })

                if(result.breakdowns.length === 0){

                  setHasMore(false)
                  cmsContext.setSnackBarOpen(true, "info", "No data yet. Wait for sync to complete.")

                }

                var temp_breakdowns = [...all_reddit_breakdowns]

                if(QueryExecutionId.length === 0){
                  temp_breakdowns = []
                }
                
                temp_breakdowns = temp_breakdowns.concat(result.breakdowns)

                console.log('setting all', temp_breakdowns)

                set_all_reddit_breakdowns(temp_breakdowns)
                
                // No need to get images, as already getting thumbnail urls

                /*

                for(var breakdown_index in temp_breakdowns){

                  if(temp_breakdowns[breakdown_index].account_id && temp_breakdowns[breakdown_index].image_hash){
         
                    get_picture_async(temp_breakdowns[breakdown_index].account_id, temp_breakdowns[breakdown_index].image_hash, temp_breakdowns[breakdown_index].creative_id, breakdown_index)

                  }

                }

                */



                if(result.NextToken){

                  set_next_token(result.NextToken)

                  if(Object.keys(result.NextToken).length > 0){
                    setHasMore(true)
                  }else{
                    setHasMore(false)
                  }
                  
                }else{

                  setHasMore(false)
                  set_next_token('')

                }

                setQueryExecutionId(result.QueryExecutionId)

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
           
      )

    })

  }


  function toggle_ad_group_dialog(is_open, is_loading, reddit_ad_group_dialog_state) {
      
      const temp_state = {...reddit_ad_group_dialog_state}

      console.log("temp state was: ", temp_state)
  
      temp_state.is_dialog_open = is_open

      temp_state.is_dialog_loading = is_loading

      console.log("new dialog state was: ", temp_state)

      set_reddit_ad_group_dialog_state(temp_state)

  }



  function show_ad_group_dialog(type) {

      const temp_state = cloneDeep(default_ad_group_dialog)

      temp_state.is_dialog_open = true

      temp_state.type = type

      const temp_breakdowns = [...all_reddit_breakdowns]

      const selected_ads = []

      var invalid_image = false

      console.log("temp breakdowns were: ", temp_breakdowns)

      temp_breakdowns.forEach((ad) => {

        if(ad.is_checked){
  
          selected_ads.push(cloneDeep(ad))

          if(!ad.thumbnail_url || !ad.thumbnail_url.includes("https")){
            invalid_image = true
          }
  
        }
  
      })

      temp_state.ads = selected_ads
  
      if(selected_ads.length === 0 || invalid_image){
  
        cmsContext.setSnackBarOpen(true, "error", "Please select valid ads, or wait for images to load!")
        return
  
      }
  

      set_reddit_ad_group_dialog_state(temp_state)

  }



};



export default RedditAdsCountryBreakdowns;