import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../../../store/cms-context';

import { getAccessJwtToken } from '../../../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import RateDialog from "./SubComponents/RateDialog";
import RatesListTable from "./SubComponents/RatesListTable";
import { default_conversion_rate } from "../../../../Utilities/Metrics";

import cloneDeep from 'lodash/cloneDeep';
import CurrenciesInfo, { CurrenciesList } from "../../../../Utilities/CurrenciesInfo";
import { has_admin_access, has_product_management_write_access } from "../../../../Utilities/HelperFunctions";


function ConversionRates(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_rates, set_all_rates] = React.useState([]);

  const [active_accounts, set_active_accounts] = React.useState([]);

  const [email_time, set_email_time] = React.useState('');

  const [rate_dialog_state, set_rate_dialog_state] = React.useState(cloneDeep(default_conversion_rate));


  useEffect(() => {

    isMountedRef.current = true

    get_all_rates()

    return () => isMountedRef.current = false;

  }, [])


 


  function handle_active_accounts_change(event, newValue){

    set_active_accounts(newValue)

  }

  function show_create_rate_dialog(){
    
    const temp_dialog_state = cloneDeep(default_conversion_rate);

    temp_dialog_state.is_dialog_open = true

    set_rate_dialog_state(temp_dialog_state);

  }



  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Conversion Rates</Typography>

          <div>

          <Button variant="contained" onClick = {() => {show_create_rate_dialog()}}>Create Rate</Button>

          </div>

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >


          <RatesListTable delete_rates={delete_rates} set_dialog_rate={set_dialog_rate} all_rates={all_rates} set_all_rates={set_all_rates}>

          </RatesListTable>

    
        </Grid>


      </Grid>

    </Container>      


    </Box>

     }

     {rate_dialog_state ? <RateDialog post_rate={post_rate} rate_dialog_state={rate_dialog_state} set_rate_dialog_state={set_rate_dialog_state}></RateDialog> : null}

    </> 


  );

  function set_dialog_rate(rate, is_open){

    const temp_rate_state = cloneDeep(default_conversion_rate)

    temp_rate_state.rate = rate

    temp_rate_state.rate.value = temp_rate_state.rate.rate

    temp_rate_state.is_dialog_open = is_open

    temp_rate_state.type = "Update"

    console.log("setting state: ", temp_rate_state)

    set_rate_dialog_state(temp_rate_state)

  }

  function get_all_rates() {
    
    console.log('store_access_token')


    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/currency/conversions/rates',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization': auth_creds.jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
              setIsLoading(false)
  
              console.log('result was',result)
             
              if(result.rates){

                console.log('setting rates')

                if(result.rates.length === 0){

                  cmsContext.setSnackBarOpen(true, "info", "No rates yet. Let's create one to get started.")

                  set_all_rates([])

                  return

                }


                const all_rates = []

                var temp_rate;

                result.rates.forEach((rate) => {

                  temp_rate = cloneDeep(default_conversion_rate.rate)

                  for(var key in rate){
                    temp_rate[key] = rate[key]
                  }

                  temp_rate.is_checked = false
                  temp_rate.source_currency = CurrenciesInfo[temp_rate.source_currency]
                  temp_rate.target_currency = CurrenciesInfo[temp_rate.target_currency]
                  console.log('temp rate was: ', temp_rate)
                  all_rates.push(temp_rate)
                  
                })

              
                set_all_rates(all_rates)

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }

              
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
           
      )

    })

  }

  

  function set_dialog_loading(is_loading){

    const temp_rate_state = {...rate_dialog_state}

    temp_rate_state.isLoading = is_loading

    set_rate_dialog_state(temp_rate_state)

  }

  function reset_dialog_state(){

    set_rate_dialog_state(cloneDeep(default_conversion_rate))

  }

  function post_rate(rate, request_type){

    set_dialog_loading(true)

    console.log('updating')

    var request_body = {}

    request_body.conversion_rate = rate

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    getAccessJwtToken().then((auth_creds)=>{

      if(!has_admin_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
      }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/currency/conversions/rates', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                reset_dialog_state()

                get_all_rates()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_dialog_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
                set_dialog_loading(false)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })



  }

  function delete_rates(){

    console.log('delete rates')

    var temp_rates = [...all_rates].filter((rate) => rate.is_checked);

    console.log('filtered were', temp_rates)

    if(temp_rates.length === 0){
      // might never execute (need to select rates to display delete button)
      cmsContext.setSnackBarOpen(true, "error", "Please select valid rates!")
      return;
    }else if(temp_rates.length > 20){
      cmsContext.setSnackBarOpen(true, "error", "Please select a maximum of 20 rates!")
      return;
    }

    temp_rates = temp_rates.map((rate) => {

      return(rate.id)

    })

    console.log('final rates were', temp_rates)

    const request_body = {}

    request_body.conversion_rate = temp_rates

    request_body.request_type = "Delete"

    request_body.account_email = cmsContext.current_shop.email
    

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      if(!has_admin_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
      }


      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/currency/conversions/rates',{
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':auth_creds.jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was',result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_rates()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {
                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)
                }
          );
  
      });

  }


};


export default ConversionRates;