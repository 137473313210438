import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Container, Grid, Typography, Stack, Button, CircularProgress } from '@mui/material';

import CmsContext from '../../../../store/cms-context';

import { getAccessJwtToken } from '../../../../store/globalUtilities';

import StyleDialog from "./SubComponents/StyleDialog";

import VariantsListTable from "./SubComponents/VariantsListTable";

import { default_variant_style } from "../../../../Utilities/Metrics";

import cloneDeep from 'lodash/cloneDeep';
import { has_product_management_write_access } from "../../../../Utilities/HelperFunctions";


function VariantsStyles(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [all_variants, set_all_variants] = React.useState([]);

  const [style_dialog_state, set_style_dialog_state] = React.useState(cloneDeep(default_variant_style));


  useEffect(() => {

    isMountedRef.current = true

    get_all_styles()

    return () => isMountedRef.current = false;

  }, [])


 

  function show_create_style_dialog(){
    
    const temp_dialog_state = cloneDeep(default_variant_style);

    temp_dialog_state.is_dialog_open = true

    set_style_dialog_state(temp_dialog_state);

  }



  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Styles</Typography>

          <div>

          <Button variant="contained" onClick = {() => {show_create_style_dialog()}}>Create Style</Button>

          </div>

        </Stack>

      </Grid>

      <Grid
          item
          xs={12}
        >


          <VariantsListTable type="variant_style" delete_variants={delete_variants} set_dialog_style={set_dialog_style} all_variants={all_variants} set_all_variants={set_all_variants}>

          </VariantsListTable>

    
        </Grid>


      </Grid>

    </Container>      


    </Box>

     }

     <StyleDialog post_style={post_style} style_dialog_state={style_dialog_state} set_style_dialog_state={set_style_dialog_state}></StyleDialog>

    </> 


  );

  function set_dialog_style(variant, is_open){

    const temp_style_state = cloneDeep(default_variant_style)

    temp_style_state.variant = variant

    temp_style_state.is_dialog_open = is_open

    temp_style_state.type = "Update"

    set_style_dialog_state(temp_style_state)

  }

  function get_all_styles() {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/products/variants/styles',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt,
            'email':cmsContext.current_shop.email
          }})
          .then(res => res.json())
          .then(
            (result) => {
              

              setIsLoading(false)
  
              console.log('result was',result)

             
              if(result.styles){

                console.log('setting analuytis')

                if(result.styles.length === 0){

                  cmsContext.setSnackBarOpen(true, "info", "No styles yet. Let's create one to get started.")

                  set_all_variants([])

                  return

                }

                const all_variants = []

                var temp_style;

                result.styles.forEach((variant) =>{
                  temp_style = cloneDeep(default_variant_style.style)
                  for(var key in variant){
                    temp_style[key] = variant[key]
                  }
                  temp_style.is_checked = false
                  console.log('temp style was: ', temp_style)
                  all_variants.push(temp_style)
                })

                set_all_variants(all_variants)

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }

              
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
           
      )

    })


  }


  function set_dialog_loading(is_loading){

    const temp_style_state = {...style_dialog_state}

    temp_style_state.isLoading = is_loading

    set_style_dialog_state(temp_style_state)

  }

  function reset_dialog_state(){

    set_style_dialog_state(cloneDeep(default_variant_style))

  }

  function post_style(variant, request_type){

    set_dialog_loading(true)

    console.log('updating')

    var request_body = {}

    request_body.variant_style = variant

    request_body.request_type = request_type

    request_body.account_email = cmsContext.current_shop.email

    console.log('body was: ', request_body)

    getAccessJwtToken().then((auth_creds) => { 

      if(!has_product_management_write_access(auth_creds.current_user)){
      cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
      set_dialog_loading(false)
      return
    }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/products/variants/styles', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
    
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                reset_dialog_state()

                get_all_styles()
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                set_dialog_loading(false)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
                set_dialog_loading(false)

              }
  
            },
        
            (error) => {
              set_dialog_loading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }

  function delete_variants(){

    console.log('delete styles')

    var temp_styles = [...all_variants].filter((variant) => variant.is_checked);

    console.log('filtered were', temp_styles)

    if(temp_styles.length === 0){
      // might never execute (need to select styles to display delete button)
      cmsContext.setSnackBarOpen(true, "error", "Please select valid styles!")
      return;
    }else if(temp_styles.length > 20){
      cmsContext.setSnackBarOpen(true, "error", "Please select a maximum of 20 styles!")
      return;
    }

    temp_styles = temp_styles.map((style) => {

      return(style.variant_id)

    })

    console.log('final styles were', temp_styles)

    const request_body = {}

    request_body.variant_style = temp_styles

    request_body.request_type = "Delete"

    request_body.account_email = cmsContext.current_shop.email

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds) => {

      if(!has_product_management_write_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
      }


      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/products/variants/styles',{
              crossDomain:true,
              method: 'POST',
              headers: {
                'content-type':'application/json',
                'Authorization':auth_creds.jwt
              },
              body: JSON.stringify(request_body)
            })
              .then(res => res.json())
              .then(
                (result) => {
      
                  setIsLoading(false)
  
                  console.log('result was',result)

                  if(result.message){

                    cmsContext.setSnackBarOpen(true, "success", result.message)

                    get_all_styles()
                  
                  }else if(result.errorMessage){

                    cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                    
                  }else{

                    cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                    console.log('Error', result)

                  }
                  
                },
            
                (error) => {
                  setIsLoading(false)
                  cmsContext.setSnackBarOpen(true, "error", error)
                  console.log('error was',error)
                }
          );
  
      });

  }


};


export default VariantsStyles;