import {Auth} from 'aws-amplify';





export const getAccessJwtToken = async () => {
    // Auth.currentSession() checks if token is expired and refreshes 
    // with Cognito if needed automatically

    const session = await Auth.currentSession();

    const user = await Auth.currentAuthenticatedUser();

    const email = user.attributes.email; // Get user's email

    const groups = user.signInUserSession.accessToken.payload['cognito:groups'] || []; // Get user's groups

    const current_user = {"email": email, "groups": groups};

    console.log('congnito email was: ', email);

    console.log('congnito groups were: ', groups);

    let idToken = session.getIdToken();
    
    let jwt = idToken.getJwtToken();

    return {jwt, current_user}

};





export const cartesian = (...args) => {

    if(args.length === 0){
      return [];
    }

    var r = [], max = args.length-1;
    function helper(arr, i) {
        for (var j=0, l=args[i].length; j<l; j++) {
            var a = arr.slice(0); // clone arr
            a.push(args[i][j]);
            if (i===max)
                r.push(a);
            else
                helper(a, i+1);
        }
    }
    helper([], 0);
    return r;

  }