import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, IconButton, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl, Select, MenuItem, Divider, Chip} from '@mui/material';

import { LoadingButton } from "@mui/lab";

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../store/cms-context';

import { getAccessJwtToken } from '../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import { Analytics, Storage } from 'aws-amplify';

import TimePicker from '@mui/lab/TimePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import TimeZones from "../../Utilities/TimeZones";

import TimezoneSelect from "react-timezone-select";

import { clone, cloneDeep, min } from "lodash";

import { default_create_folder_dialog, default_deploy_image_dialog, default_insta_shopify_stream_link_dialog, ONE_DAY } from "../../Utilities/Metrics";

import MediaGrid from './SubComponents/MediaGrid';

import SearchIcon from '@mui/icons-material/Search';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import FoldersTable from "./SubComponents/FoldersTable";
import CreateFolderDialog from "./SubComponents/CreateFolderDialog";
import FilesTable from "./SubComponents/FilesTable";
import DeployImageDialog from "./SubComponents/DeployImageDialog";
import { has_admin_access } from "../../Utilities/HelperFunctions";



function StorageImages(props) {

  //console.log("render")
  
  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const imageEditor = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [isLinkAccountsLoading, setIsLinkAccountsLoading] = React.useState(false);

  const [link_dialog_state, set_link_dialog_state] = React.useState(cloneDeep(default_insta_shopify_stream_link_dialog))

  const [rows, setRows] = React.useState([]);

  const [imageFiles, setImageFiles] = React.useState([]);

  const [selected_images, set_selected_images] = React.useState([]);

  const [editing_image, set_editing_image] = React.useState({});

  const [all_files, set_all_files] = React.useState([])

  const [all_tags, set_all_tags] = React.useState([]);

  const [search_tags, set_search_tags] = React.useState('');

  const [all_folders, set_all_folders] = React.useState([])

  const [selected_folder, set_selected_folder] = React.useState({})

  const [create_folder_dialog_state, set_create_folder_dialog_state] = React.useState(cloneDeep(default_create_folder_dialog))

  const [creating_images, set_creating_images] = React.useState(false)

  const [selected_file, set_selected_file] = React.useState({})

  const [selected_filters, set_selected_filters] = React.useState({ "selected_tags":[],"search_value":""});

  const [deploy_image_dialog_state, set_deploy_image_dialog_state] = React.useState(default_deploy_image_dialog)



  const navigate = useNavigate();

  //const mode = selected_folder != null && Object.keys(selected_folder).length > 0 ? "files" : "folders"

  const [mode, set_mode] = React.useState("folders")

  const myTheme = {
      "header.display": "none"
  };


  useEffect(() => {

    isMountedRef.current = true

     get_all_files("folders")

    return () => isMountedRef.current = false;

  }, [])

  useEffect(() =>{

    if(selected_folder && Object.keys(selected_folder).length > 0){

      get_all_files("files")

      set_mode("files")

    }

  },[selected_folder])


  



  function getBase64(request_type, file, index) {

    var reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function () {

      if(request_type === "create"){

        setImageFiles((previousImages) =>{

          const temp_images = [...previousImages]

          temp_images[index].preview = reader.result

          return setImageFiles(temp_images)

        })

    }else if(request_type === "edit"){

      set_all_files((previous_files) => {

        if(previous_files && index < previous_files.length){

          const temp_files = [...previous_files]
          temp_files[index].preview = reader.result
          return set_all_files(temp_files)

        }

      })

    }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 }
  


  function handle_accepted_files(acceptedFiles) {


    var temp_files = [...imageFiles];

    console.log("file was: ", cloneDeep(acceptedFiles))

    // change with final id

    var temp_time = Date.now()

    acceptedFiles.forEach((file) =>{

      file = Object.assign(file, {id: temp_time, imagePath: file.path, is_uploaded:false, mediaContentType:'',originalSource:'',tags:'',aws_key:'',is_checked:false})
      temp_time = temp_time + 1
    })


    var temp_accepted_files = [...acceptedFiles];

    temp_accepted_files.forEach((file) => {

      file.modified_name = temp_time.toString() + ".jpeg"

    })  
    
    temp_files = temp_files.concat(temp_accepted_files);

    console.log('files were: ', temp_files)

    setImageFiles(temp_files);

    temp_files.forEach(function (value, i) {
      getBase64("create", value, i)
    })

    //upload_files(temp_accepted_files)

    }

    function reset_editor_state(){
      set_creating_images(false)
      set_selected_images([])
      set_editing_image({})
    }

    function reset_files_state(){

      reset_editor_state()
      set_selected_filters({"selected_tags":[],"search_value":""})
      set_search_tags('')
      set_all_files([])
      set_selected_file([])

    }



    useEffect(() => {

    
      if(imageFiles != null && creating_images){

        const temp_images = imageFiles.filter((image) => image.is_checked)

        if(temp_images.length !== 1){
          stop_editing()
        }

       set_selected_images(temp_images)

      }

      else if(all_files != null){

        reset_editor_state()

        const temp_images = all_files.filter((image) => image.is_checked)

        if(all_files.length !== 1){
          stop_editing()
        }

       set_selected_images(temp_images)

       const found_tags = []
  
       all_files.forEach((file) =>{
         file.tags.forEach((tag) => {
 
           if(tag.trim().length > 0 && found_tags.indexOf(tag) === -1) {
             found_tags.push(tag)
           }
 
         })
       })
 
       const filteredRows = get_files_from_filters(all_files);
 
       set_all_tags(found_tags)
 
       setRows(filteredRows)
 

      }

    },[imageFiles, all_files, creating_images])



    function start_editing(){

      const selected_image = selected_images[0]

      console.log('editing called: ', selected_images)

      set_editing_image(selected_image)

      if(selected_image && Object.keys(selected_image).length > 0){

        if(imageEditor.current != null){
  
          imageEditor.current.getInstance().loadImageFromURL(selected_image.preview, 'image').then(result=>{
  
            console.log('loaded')
  
            imageEditor.current.getInstance().ui.activeMenuEvent()
  
          });
  
        }else{
          console.log("null editor")
        }
  
      }

    }



    function show_deploy_dialog(){

      if(!(selected_images && selected_images.length > 0)) {

        cmsContext.setSnackBarOpen(true, "error", "Please select a valid image!")
        return

      }

      const temp_dialog_state = {...deploy_image_dialog_state}

      const temp_stores = cloneDeep(cmsContext.current_shop.connected_stores.map((store) => {return({'store': store, 'is_checked': false})}))

      temp_dialog_state.is_dialog_open = true
      
      temp_dialog_state.selected_stores = temp_stores

      set_deploy_image_dialog_state(temp_dialog_state)

    }

    function hide_deploy_dialog(){

      const temp_dialog_state = {...deploy_image_dialog_state}

      temp_dialog_state.is_dialog_open = false

      set_deploy_image_dialog_state(temp_dialog_state)

    }

    function set_deploy_dialog_loading(is_loading){

      const temp_dialog_state = {...deploy_image_dialog_state}

      temp_dialog_state.isLoading = is_loading

      set_deploy_image_dialog_state(temp_dialog_state)

    }



    function stop_editing(){

      set_editing_image({})

    }


    function apply_editor_changes(){

      const temp_image = {...editing_image}

      var index;

      const temp_image_files = [...imageFiles]

      temp_image_files.forEach(function (file, i) {

        if(file.id === temp_image.id){
          index = i;
        }

      })

      const editorInstance = imageEditor.current.getInstance();

      temp_image_files[index].preview = editorInstance.toDataURL({'format':'jpeg','multiplier':1,'quality':1})

      temp_image_files[index].is_checked = false

      setImageFiles(temp_image_files)

    }

    const handleAllTagsChange = ((event, newValue) =>{

      const temp_filters = {...selected_filters}
      temp_filters.selected_tags = newValue
      set_selected_filters(temp_filters)
  
    })



    useEffect(() => {

      if(all_files && all_files.length > 0) {
  
        const filteredRows = get_files_from_filters(all_files);
  
        setRows(filteredRows)

        reset_editor_state()
  
      }
  
    }, [selected_filters])
  
  
    function get_files_from_filters(all_files){

  
      var filteredRows = [...all_files]
  
      if(selected_filters.search_value.length > 0){
  
        filteredRows = filteredRows.filter((row) => {
          return row.name.toLowerCase().includes(selected_filters.search_value.toLowerCase());
        });
  
      }
  
      if(selected_filters.selected_tags.length > 0){
  
        filteredRows = filteredRows.filter((row) =>{
          return row.tags.join(',').includes(selected_filters.selected_tags.join(','))
        })
  
      }
  
      return filteredRows;

  }
  


  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1060px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <div style={{display:'flex'}}>

          {mode === "files" ? <IconButton aria-label="delete" color="primary" onClick={() => {reset_files_state();set_selected_folder({});set_mode("folders")}}>
                <ArrowBackIcon />
          </IconButton>: null}

          <Typography sx={{marginTop:0.4, marginLeft:0.2}} variant="h5">{mode === "folders" ? "Folders" : (mode === "files" || creating_images) && selected_folder && selected_folder.folder_name ? (selected_folder.folder_name + " / " + "Images") : mode === "files" ? "All Images" : ""}</Typography>

          </div>

          <div style={{display:'flex'}}>

         { mode === "files" && !creating_images && editing_image && editing_image.preview ? <Button variant="contained" onClick={() => {handle_file_update("files", "Update")}}>Update</Button> : creating_images ? <> <Button variant="contained" onClick={() => {set_creating_images(false);set_mode("files");}}>Cancel</Button> <Button variant="contained" sx={{marginLeft:0.6}} onClick={() => {handle_file_update("files","Create")}}>Save</Button> </> : null}

         { mode && ((mode === "files" && selected_folder && selected_folder.folder_name) || mode === "folders") && !(editing_image && editing_image.preview) && !creating_images ? <LoadingButton sx={{marginLeft:0.6}} variant="contained" onClick = {() => {if(mode === "folders"){ show_create_folder_dialog()} else if( mode === "files") {reset_editor_state();set_creating_images(true);}; }} >{mode === "folders" ? "Create Folder" : mode === "files" ? "Create Image" : ""}</LoadingButton> : null }

          </div>
        </Stack>

      </Grid>



      {all_folders && all_folders.length > 0 && mode !== "creating_images" && (mode === "folders" || (mode === "files" && all_files && all_files.length > 0)) ? <Grid item xs={12} style={{marginTop:8}}>

      <Grid
      container
      spacing={1}
      >

      <Grid
        item
        xs={mode === "files" ? 6: 5}
        >

        <TextField
          id="outlined_search"
          size='small'
          InputLabelProps={{shrink:true}}
          style={{width:'100%'}}
          value={selected_filters.search_value ? selected_filters.search_value : ""}
          onChange={(event) => {const temp_filters = {...selected_filters}; temp_filters.search_value = event.target.value; set_selected_filters(temp_filters);}}
          InputProps={{endAdornment : (
            <InputAdornment position="end">
              <SearchIcon></SearchIcon>
            </InputAdornment>
          ),
          }}
          label="Search"
      />

      </Grid>


      {mode === "files" ? <Grid
        item
        xs={6}
      >
      
      <FormControl fullWidth size='small'>
      
      <Autocomplete
        multiple
        size='small'
        id="excluded-campaigns"
        options={all_tags}
        getOptionLabel={(tag) => tag}
        value={selected_filters.selected_tags != null && selected_filters.selected_tags.length > 0 ? selected_filters.selected_tags : []}
        isOptionEqualToValue={(option, value) => option === value}
        onChange={(event, newValue) => {handleAllTagsChange(event, newValue)}}
        renderInput={(params) => (
          <TextField {...params} label="Tags" placeholder="Tags" />
        )}
      />

      </FormControl>

      </Grid> :

      <Grid
      item
      xs={5}
      >

      <FormControl fullWidth size='small'>

      <TextField
      size='small'
      value={search_tags}
      fullWidth
      InputLabelProps={{shrink:true}}
      label="Tags"
      onChange={(event) => {set_search_tags(event.target.value)}}
      />

      </FormControl>

      </Grid> 
      
      }

      {mode === "folders" || mode === "search" ? <Grid item xs={2}>

        <Button fullWidth variant="contained" onClick={() => {get_all_files("search")}}>Search</Button>

      </Grid> : null}


      </Grid>


      </Grid>

      : null}


      {mode === "folders" ?
      
      <>
      
      {all_folders != null && all_folders.length > 0 ?

        <Grid
        item
        xs={12}
        >

          <FoldersTable handle_file_update={handle_file_update} set_selected_folder={set_selected_folder} all_folders={all_folders} set_all_folders={set_all_folders}></FoldersTable>

        </Grid>


      : null}

      </>

      :

      creating_images ? 
      
      <>

      <Grid item xs={12}>

        <MediaGrid start_editing={start_editing} editing_image={editing_image} apply_editor_changes={apply_editor_changes} selected_images={selected_images} type={props.type} domain={props.variation ? props.variation.store.store_domain : cmsContext.current_shop.store_domain} handle_accepted_files={handle_accepted_files} imageFiles = {imageFiles} setImageFiles = {setImageFiles}></MediaGrid>

      </Grid>

      </>
      
      
      : all_files &&  all_files.length > 0 ? 
    
      <Grid item xs={12}><FilesTable rows={rows} setRows={setRows} reset_editor_state={reset_editor_state} all_tags={all_tags} set_all_tags={set_all_tags} selected_filters={selected_filters} set_selected_filters={set_selected_filters} handle_file_update={handle_file_update} show_deploy_dialog={show_deploy_dialog} start_editing={start_editing} set_editing_image={set_editing_image} selected_file={selected_file} set_selected_file={set_selected_file} all_files={all_files} set_all_files={set_all_files}></FilesTable></Grid>

      : null
      }

      <Grid sx={{display: editing_image && Object.keys(editing_image).length > 0 ? "block" : "none"}} item xs={12}>

     {!creating_images && editing_image && editing_image.tags ? <TextField label="Tags" InputLabelProps={{shrink:true}} size="small" style={{marginTop:12, marginBottom:12}} fullWidth value={editing_image.tags} onChange={(event) => {const temp_image = {...editing_image};temp_image.tags=event.target.value;set_editing_image(temp_image)}}></TextField> : null}

      <ImageEditor
        ref={imageEditor}
        includeUI={{
          theme: myTheme,
          menu: ['shape', 'filter', 'crop', 'flip', 'text','mask','draw', 'rotate','icon'],
          initMenu: 'filter',
          uiSize: {
            width: '100%',
            height: '600px',
          },
          menuBarPosition: 'bottom',
        }}
        cssMaxHeight={500}
        cssMaxWidth={700}
        selectionStyle={{
          cornerSize: 20,
          rotatingPointOffset: 70,
        }}
        usageStatistics={true}
      />

      </Grid>


      </Grid>

    </Container>      


    </Box>

     }

     <CreateFolderDialog handle_file_update={handle_file_update} set_create_folder_dialog_state={set_create_folder_dialog_state} hide_create_folder_dialog={hide_create_folder_dialog} create_folder_dialog_state={create_folder_dialog_state}></CreateFolderDialog>

     <DeployImageDialog handle_file_update={handle_file_update} deploy_image_dialog_state={deploy_image_dialog_state} set_deploy_image_dialog_state={set_deploy_image_dialog_state}></DeployImageDialog>

    </> 


  );



  function show_create_folder_dialog(){

    const temp_dialog_state = cloneDeep(default_create_folder_dialog)

    temp_dialog_state.is_dialog_open = true

    set_create_folder_dialog_state(temp_dialog_state)
    
  }


  function hide_create_folder_dialog(){

    const temp_dialog_state = cloneDeep(default_create_folder_dialog)

    set_create_folder_dialog_state(temp_dialog_state)

  }


  function handle_folder_create(){



  }


  function delete_tasks(){

    var selected_stream = {}

    all_files.forEach((stream) => {
      
      if(stream.is_checked){
        selected_stream = stream
      }

    })

    if(Object.keys(selected_stream).length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid stream")
      return
    }

    const request_body = {}

    request_body.stream = selected_stream

    request_body.stream.email = cmsContext.current_shop.email

    request_body.stream.last_request = "Delete"

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      if(!has_admin_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
      }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/instagram/shopify/streams', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)
                reset_page(true)
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }



  function get_all_files(request_type) {

    // user api

    // error handling and state managment required


    if(request_type === "search" && (selected_filters.search_value.trim().length === 0 && search_tags.trim().length === 0)){
      cmsContext.setSnackBarOpen(true, "error", "Please enter valid search parameters!")
      return
    }

    reset_files_state()

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds) => {

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/files', {
          crossDomain:true,
          method: 'GET',
          headers: {
            'email': cmsContext.current_shop.email,
            'Authorization':auth_creds.jwt,
            'request_type': request_type,
            'parent': request_type === "files" ? selected_folder.folder_name : "",
            'file_name': selected_filters.search_value.trim(),
            'tags': search_tags.trim()
          }})
          .then(res => res.json())
          .then(
            (result) => {

              //console.log('result was: ', result)
  
              setIsLoading(false)
  

              if(result.items){

                if(result.items.length === 0){
                  cmsContext.setSnackBarOpen(true, "info", "No instances yet. Let's create one to get started.")
                  return
                }

                var date;

                result.items.forEach((item) =>{

                  item.is_checked = false

                  if(item.created_at){
                  
                  date = new Date(item.created_at)

                  item.created_at_time = ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
                  ("00" + date.getDate()).slice(-2) + "-" +
                  date.getFullYear() + " " +
                  ("00" + date.getHours()).slice(-2) + ":" +
                  ("00" + date.getMinutes()).slice(-2) + ":" +
                  ("00" + date.getSeconds()).slice(-2);

                  }

                  if(item.updated_at){
                  
                    date = new Date(item.updated_at)
  
                    item.updated_at_time = ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
                    ("00" + date.getDate()).slice(-2) + "-" +
                    date.getFullYear() + " " +
                    ("00" + date.getHours()).slice(-2) + ":" +
                    ("00" + date.getMinutes()).slice(-2) + ":" +
                    ("00" + date.getSeconds()).slice(-2);
  
                  }



                })

                if(request_type === "files" || request_type === "search"){
                  set_mode("files")
                  set_all_files(result.items)
                  load_media_files(cloneDeep(result.items))
                }else if(request_type === "folders"){
                  set_all_folders(result.items)
                }


              }else if(result.errorMessage){
                
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
      )

    })


  }


  async function load_media_files(media_files){

    
    try {

    for(var i=0; i < media_files.length; i++){


     const url = await Storage.get(media_files[i].aws_key, {
        level: 'public',
        bucket: 'snag-cms175447-staging',
        expires:86400,
        region: 'eu-west-2',
      });

      media_files[i].preview = url;

      const data = await fetch(url);
      const blob = await data.blob();

      getBase64("edit", blob, i)

      }

      } catch (error) {
        cmsContext.setSnackBarOpen(true, "error", error)
      }
      

  }





  function reset_page(delayed){

    setTimeout(function() {

      window.location.reload();

  }, delayed?100:1)
}


  function set_dialog_loading(isLoading){

    const temp_dialog_state = {...link_dialog_state}

    temp_dialog_state.isLoading = isLoading

    set_link_dialog_state(temp_dialog_state)

  }


  async function handle_file_update(file_type, request_type) {

    console.log("update initiated")

      var request_body = {}

      if(request_type !== "Deploy" && file_type === "files"){

      if(request_type === "Create" || request_type === "Update"){

      console.log('syncing')

      var all_images;

      if(request_type === "Create"){

        all_images = [...imageFiles]

      }else if(request_type === "Update"){

        all_images = [editing_image]

      }

      if(all_images.length === 0){
        cmsContext.setSnackBarOpen(true, "error", "Please select valid images")
        return
      }

      console.log('images were: ', all_images)
 

      const upload_images = []

      var incorrect_image_found = false

      if(request_type === "Create"){

      all_images.forEach((image) => {

        if(image.preview){

            upload_images.push({id: image.modified_name, preview:image.preview, name:image.name, parent:selected_folder.folder_name, tags: image.tags})

        }else{

          incorrect_image_found = true

        }

      })

     }else if(request_type === "Update"){

      const editorInstance = imageEditor.current.getInstance();
      editing_image.preview = editorInstance.toDataURL({'format':'jpeg','multiplier':1,'quality':1})
      editing_image.updated_at = Date.now()
      upload_images.push(editing_image)

     }



      if(incorrect_image_found){
        cmsContext.setSnackBarOpen(true, "error", "Invalid image found in list!")
        return
      }

      handle_update_loading(file_type, request_type, true)

      var upload_error;

      for (var i=0; i < upload_images.length; i++){

        var url;

        var key;

        console.log('file was: ', upload_images[i])
    
        const response = await fetch(upload_images[i].preview);

        const blob = await response.blob();

        try {

          key = await Storage.put(cmsContext.current_shop.email + "/imagery/" + upload_images[i].parent + "/" + upload_images[i].id, blob, {
          contentType: "image/jpeg", // contentType is optional
          });
    
          // console.log('key was', key)
          
    
          url = await Storage.get(key.key, {
            level: 'public',
            bucket: 'snag-cms175447-staging',
            expires:86400,
            region: 'eu-west-2',
          });
    
    
          console.log('file was', url);

          if(!key){
            throw new Error("Error uploading file to backend!")
          }else{
            upload_images[i].aws_key = key['key']
          }
    
        } catch (error) {
          upload_error = error
          cmsContext.setSnackBarOpen(true, "error", error.toString())
        }

      }


      upload_images.forEach((image) => {
        delete image.preview
      })

      if(upload_error){
        setIsLoading(false)
        return
      }

      request_body.files = upload_images

    }else if(request_type === "Delete"){

      request_body.files = [...selected_images]

      handle_update_loading(file_type, request_type, true)

    }else{
      cmsContext.setSnackBarOpen(true, "error", "Invalid files request!")
      return
    }
    

    }else if(file_type === "folders"){

      if(request_type === "Create" || request_type === "Update"){

      if(create_folder_dialog_state.folder_name.trim().length === 0){
        cmsContext.setSnackBarOpen(true, "error", "Invalid folder name!")
        return
      }

      handle_update_loading(file_type, request_type, true)

      request_body.folder_name = create_folder_dialog_state.folder_name.trim()

    }else if(request_type === "Delete"){

      const selected_folders = []

      all_folders.forEach((folder) =>{
        if(folder.is_checked){
          selected_folders.push(folder)
        }
      })

      if(selected_folders.length === 0){

        cmsContext.setSnackBarOpen(true, "error", "Please select a valid folder!")
        return

      }

      request_body.folder_name = selected_folders[0].folder_name

      handle_update_loading(file_type, request_type, true)

    }else{

      cmsContext.setSnackBarOpen(true, "error", "Invalid folders request!")
      return

    }

    }else if(request_type === "Deploy") {


      const temp_dialog_state = {...deploy_image_dialog_state}

      const selected_stores = []

      temp_dialog_state.selected_stores.forEach((store) => {

        if(store.is_checked){
          selected_stores.push(store.store.store_domain)
        }
        
      })

      if(selected_stores.length === 0) {

        cmsContext.setSnackBarOpen(true, "error", "Please select valid store(s)!")
        return

      }

      request_body.selected_stores = selected_stores

      const temp_images = cloneDeep(selected_images)

      temp_images.forEach((image) =>{
        if(image.preview){
          delete image.preview
        }
      })

      request_body.files = selected_images

      set_deploy_dialog_loading(true)

    }else{

      cmsContext.setSnackBarOpen(true, "error", "Invalid request, please select a valid action!")
      return

    }

    // change with dynamic

    request_body.file_type = file_type

    request_body.request_type = request_type
    
    request_body.email = cmsContext.current_shop.email
  
    console.log('request body: ', request_body)


    getAccessJwtToken().then((auth_creds) => {

      if(!has_admin_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        set_deploy_dialog_loading(false)
        return
      }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/files', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt,
            'InvocationType': file_type === "folders" && request_type === "Delete" ? 'Event' : 'RequestResponse'
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {

              console.log('response was: ', result)

              set_deploy_dialog_loading(false)

              if(result.message){

                if(request_type === "Deploy"){
                  hide_deploy_dialog()
                }else{
                  handle_update_hide(file_type, request_type)
                }

                cmsContext.setSnackBarOpen(true, "success", result.message)
                get_all_files(file_type)
              
              }else if(result.errorMessage){

                handle_update_loading(file_type, request_type, false)
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                handle_update_loading(file_type, request_type, false)
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {

              if(file_type === "folders" && request_type === "Delete" && error.toString().includes("JSON")){
                handle_update_hide(file_type, request_type)
                cmsContext.setSnackBarOpen(true, "success", "Delete task successfully created, please refresh!")
                reset_page(true)
              }else{
                handle_update_loading(file_type, request_type, false)
                cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
                console.log('error was',error)
              }
        
            }
      )

    })

  }

  function handle_update_loading(file_type, request_type, isLoading){

    if(file_type === "folders" && request_type === "Create"){

      const temp_dialog_state = {...create_folder_dialog_state}

      temp_dialog_state.isLoading = isLoading

      set_create_folder_dialog_state(temp_dialog_state)

    }else if(file_type === "folders" && request_type === "Delete"){

      setIsLoading(isLoading)

    }
    else if(file_type === "files"){

      setIsLoading(isLoading)

    }

  }

  function handle_update_hide(file_type, request_type){

    if(file_type === "folders" && request_type === "Create"){

      set_create_folder_dialog_state(cloneDeep(default_create_folder_dialog))

    }else if(file_type === "folders" && request_type === "Delete"){

      setIsLoading(false)

    }else if(file_type === "files" && request_type === "Create"){

      setIsLoading(false)

    }

  }


};


export default StorageImages;