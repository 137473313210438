import React, { useState, useRef, useEffect, useContext } from "react";

import { Box, Card, Container, Grid, Typography, TextField, Stack, Button, CircularProgress, Paper, InputLabel, OutlinedInput, InputAdornment, FormControl, Select, MenuItem, Divider } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';

import CmsContext from '../../../store/cms-context';

import { getAccessJwtToken } from '../../../store/globalUtilities';

import { useNavigate } from 'react-router-dom';

import BarChart from "./SubComponents/BarChart";
import { cloneDeep } from "lodash";
import SyncTasksListTable from "./SubComponents/SyncTasksListTable";
import { has_marketing_write_access } from "../../../Utilities/HelperFunctions";





function RedditSync(props) {

  const cmsContext = useContext(CmsContext);

  const isMountedRef = useRef(null);

  const [mode, set_mode] = React.useState(0); // 0 -> all tasks, 1 -> create tasks

  const [isLoading, setIsLoading] = useState(false);

  const [source_account, set_source_account] = useState('');

  const [source_campaign, set_source_campaign] = useState('');

  const [target_campaign, set_target_campaign] = useState('');

  const [target_account, set_target_account] = useState('');

  const [source_adsets, set_source_adsets] = useState([]);

  const [target_adsets, set_target_adsets] = useState([]);

  const [source_ads, set_source_ads] = useState([]);

  const [target_ads, set_target_ads] = useState([]);

  const [selected_source_adset, set_selected_source_adset] = useState([]);

  const [selected_source_ads, set_selected_source_ads] = useState([]);

  const [selected_target_adset, set_selected_target_adset] = useState([]);

  const [selected_target_ads, set_selected_target_ads] = useState([]);

  const [all_collections, set_all_collections] = React.useState([]);

  const [component_state, set_component_state] = React.useState({layout_type: 'list', collection_id : ''})

  const [all_campaigns, set_all_campaigns] = React.useState({});

  const [all_accounts, set_all_accounts] = React.useState([]);

  const [active_accounts, set_active_accounts] = React.useState([]);

  const [analytics, set_analytics] = React.useState([]);

  const [all_sync_tasks, set_all_sync_tasks] = React.useState([]);

  const navigate = useNavigate();


  useEffect(() => {

    isMountedRef.current = true

    get_all_sync_tasks()

    //get_configurations_settings()

    return () => isMountedRef.current = false;

  }, [])

  useEffect(() =>{

    console.log('all campaigns: ', all_campaigns)

  }, [all_campaigns])


  useEffect(() =>{

    console.log('source account was: ', source_account)

  }, [source_account])

  useEffect(() => {

    set_selected_source_ads([])

    set_source_ads([])

    if(selected_source_adset != null && selected_source_adset.id){

      console.log('adset: ', selected_source_adset)

      get_adset_ads(selected_source_adset.id, "source")


    }

  },[selected_source_adset])

 

  useEffect(() => {
    
    set_selected_target_ads([])

    set_target_ads([])

    if(selected_target_adset != null && selected_target_adset.id){

      console.log('adset: ', selected_target_adset)

      get_adset_ads(selected_target_adset.id, "target")


    }

  },[selected_target_adset])

 

  function handle_source_campaign_change(event, newValue){

    set_source_campaign(newValue)

    console.log('source campaign changed', newValue)

    set_selected_source_adset('')
    set_source_adsets([])

    if(newValue != null && newValue.account_id != null){
      get_campaign_adsets(newValue.id, "source")
    }

  }

  function handle_target_campaign_change(event, newValue){

    set_target_campaign(newValue)

    set_selected_target_adset('')
    set_target_adsets([])

    if(newValue != null && newValue.account_id != null){
      get_campaign_adsets(newValue.id, "target")
    }

  }

  function handle_active_accounts_change(event, newValue){

    set_active_accounts(newValue)

  }




  return(

    <>

   {isLoading ? <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', MozTransform: 'translateX(-50%) translateY(-50%)', WebkitTransform: 'translateX(-50%) translateY(-50%)', transform: 'translateX(-50%) translateY(-50%)'}} /> :
   
   <Box
    component="main"
    sx={{
      flexGrow: 1,
      py: 8,
    }}
  >


    <Container maxWidth={false} style={{maxWidth:"1000px",overflow:'visible'}}>
  
    <Grid
        container
        spacing={2}
        style={{overflow:'visible'}}
      >

        <Grid
          item
          xs={12}
        >

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >

          <Typography variant="h5">Sync Ads</Typography> 

          <div>
          {mode === 1 ? <Button variant="contained" onClick = {() => {set_mode(0)}}>Cancel</Button> : null}
          <Button style={{marginLeft:4}} variant="contained" onClick = {() => {mode === 1 ? sync_ads() : get_configurations_settings();set_mode(1)}}>{mode === 0 ? "New Task" : "Sync Ads"}</Button>
          </div>
        </Stack>

      </Grid>

      { mode === 0 && all_sync_tasks != null && all_sync_tasks.length > 0 ?

        <Grid
        item
        xs={12}
        >

          <Grid
          container
          spacing={3}
          alignItems="center"
          >

          <Grid
          item
          xs={12}
          >

            <SyncTasksListTable all_tasks={all_sync_tasks} set_all_tasks={set_all_sync_tasks}></SyncTasksListTable>

          </Grid>

        </Grid>

      </Grid>

      : null}

     { mode === 1 ?
       <>
      <Grid
          item
          xs={12}
        >

      <Paper elevation = {2} style={{marginTop:16, padding:20}}>
        
        <Grid
          container
          spacing={3}
          alignItems="center"
        >

          <Grid
          item
          xs={12}
          >

            <Typography fontSize={14}><strong>Source Account</strong></Typography>

          </Grid>

          <Grid
            item
            xs={12}
          >

            <FormControl fullWidth>

            <InputLabel id="demo-simple-select-autowidth-label">Account</InputLabel>

            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              fullWidth
              value={source_account}
              onChange={(event)=>{set_source_account(event.target.value)}}
              label="Account"
            >

              {all_accounts.map((item, index)=> (
                <MenuItem
                 key={index} value={item.id}>{item.name}</MenuItem>
              ))}

              
            </Select>

            </FormControl>

        </Grid>


        {source_account && source_account.length > 0 && source_account.split('act_')[1] in all_campaigns ? <Grid
            item
            xs={12}
          >

          <Autocomplete
                id="source-campaign"
                options={all_campaigns[source_account.split('act_')[1]]}
                getOptionLabel={(option) => option.name ? option.name : ""}
                value={source_campaign}
                isOptionEqualToValue={(option, value) => value.id ? option.id === value.id : false}
                onChange={(event, newValue) => {handle_source_campaign_change(event, newValue)}}
                renderOption={(props, option, index) => {

                  const key = `listItem-${option.id}`;
                  return (
                    <li {...props} key={key}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Campaign" placeholder="Campaign" />
                )}
          />
                      

        </Grid> : null}

        {source_adsets.length > 0 ? <Grid
            item
            xs={12}
          >

          <Autocomplete
                id="source-adset"
                options={source_adsets}
                getOptionLabel={(option) => option.name ? option.name : ""}
                value={selected_source_adset}
                isOptionEqualToValue={(option, value) => value.id ? option.id === value.id : false}
                onChange={(event, newValue) => {set_selected_source_adset(newValue)}}
                renderOption={(props, option, index) => {

                  const key = `listItem-${option.id}`;
                  return (
                    <li {...props} key={key}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Adset" placeholder="Adset" />
                )}
          />
                      

        </Grid> : null}

        {source_ads.length > 0 ?
          <Grid
            item
            xs={12}
          >

          <Autocomplete
                multiple
                id="source-ads"
                options={source_ads}
                getOptionLabel={(option) => option.name}
                value={selected_source_ads}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {set_selected_source_ads(newValue)}}
                renderOption={(props, option, index) => {

                  const key = `listItem-${option.id}`;
                  return (
                    <li {...props} key={key}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Ads" placeholder="Ads" />
                )}
          />     

        </Grid> : null}


        </Grid>

        </Paper>
    
        </Grid>


        <Grid item xs={12}>

        <Paper  elevation = {2} style={{marginTop:16, padding:20}}>
        
        <Grid
          container
          spacing={3}
          alignItems="center"
        >

        <Grid
          item
          xs={12}
          >

            <Typography fontSize={14}><strong>Target Account</strong></Typography>

          </Grid>

          <Grid
            item
            xs={12}
          >

            <FormControl fullWidth>

            <InputLabel id="demo-simple-select-autowidth-label">Account</InputLabel>

            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              fullWidth
              value={target_account}
              onChange={(event)=>{set_target_account(event.target.value)}}
              label="Account"
            >

              {all_accounts.map((item, index)=> (
                <MenuItem
                key={index} value={item.id}>{item.name}</MenuItem>
              ))}

              
            </Select>

            </FormControl>
                      

        </Grid>



        {target_account && target_account.length > 0 && target_account.split('act_')[1] in all_campaigns ? <Grid
            item
            xs={12}
          >

          <Autocomplete
                id="target-campaign"
                options={all_campaigns[target_account.split('act_')[1]]}
                getOptionLabel={(option) => option.name ? option.name : ""}
                value={target_campaign}
                isOptionEqualToValue={(option, value) => value.id ? option.id === value.id : false}
                onChange={(event, newValue) => {handle_target_campaign_change(event, newValue)}}
                renderOption={(props, option, index) => {

                  const key = `listItem-${option.id}`;
                  return (
                    <li {...props} key={key}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Campaign" placeholder="Campaign" />
                )}
          />
                      

        </Grid> : null}


        {target_adsets.length > 0 ? <Grid
            item
            xs={12}
          >

          <Autocomplete
                id="target-adset"
                options={target_adsets}
                getOptionLabel={(option) => option.name ? option.name : ""}
                value={selected_target_adset}
                isOptionEqualToValue={(option, value) => value.id ? option.id === value.id : false}
                onChange={(event, newValue) => {set_selected_target_adset(newValue)}}
                renderOption={(props, option, index) => {

                  const key = `listItem-${option.id}`;
                  return (
                    <li {...props} key={key}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Adset" placeholder="Adset" />
                )}
          />
                      

        </Grid> : null}

        {target_ads.length > 0 ?
          <Grid
            item
            xs={12}
          >

          <Autocomplete
                multiple
                id="target-ads"
                options={target_ads}
                getOptionLabel={(option) => option.name}
                value={selected_target_ads}
                readOnly
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {set_selected_target_ads(newValue)}}
                renderOption={(props, option, index) => {

                  const key = `listItem-${option.id}`;
                  return (
                    <li {...props} key={key}>
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Ads" placeholder="Ads" />
                )}
          />     

        </Grid> : null}

        </Grid>
          
          </Paper>
          
          </Grid>
           </> : null}



        { analytics.length > 0 ? analytics.map((account_level_analytics, index) =>{

          return(
            <Grid key={index} item xs={12}>
              <BarChart analytics={account_level_analytics}></BarChart>
            </Grid>
          )

        }) : null}



      </Grid>

    </Container>      


    </Box>

     }

    </> 


  );

  function get_all_sync_tasks() {
    
    console.log('store_access_token')

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/sync_tasks', {
          crossDomain:true,
          method: 'GET',
          headers: {
            'email': cmsContext.current_shop.email,
            'Authorization':auth_creds.jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
              
              setIsLoading(false)
              
              console.log('result was: ', cloneDeep(result))

              if(result.sync_tasks) {
                
                const temp_tasks = [...result.sync_tasks]
                
                temp_tasks.forEach((task) => {
                  task.is_checked = false
                  var error_count = 0;
                  task.selected_source_ads.forEach((source_ad) =>{
                    if(source_ad.task_error != null){
                      error_count = error_count + 1
                    }
                  })
                  task.error_count = error_count
                })

                set_all_sync_tasks(result.sync_tasks)
              
              } else if(result.errorMessage) {
                
                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else {

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {

              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
              
            }
      )

    })


  }


  function get_configurations_settings() {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/sync_configurations',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt
          }})
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was',cloneDeep(result))

              if(result.data){

                var campaigns = result.data.campaigns;

                var reddit_accounts = result.data.reddit_accounts;

                set_all_accounts(reddit_accounts)

                set_all_campaigns(campaigns)
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)
            }
      )

    })


  }


  function get_campaign_adsets(campaign_id, source_type) {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/campaigns/adsets',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt,
            'campaign_id': campaign_id
          }})
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was: ', cloneDeep(result))

              if(result.adsets){
                
                if(source_type === "source"){
                  set_source_adsets(result.adsets)
                }else{
                  set_target_adsets(result.adsets)
                }

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {

              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)

            }
      )

    })


  }


  function get_adset_ads(adset_id, source_type) {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required

    setIsLoading(true)

    getAccessJwtToken().then((auth_creds)=>{

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/campaigns/adsets/ads',{
          crossDomain:true,
          method: 'GET',
          headers: {
            'Authorization':auth_creds.jwt,
            'adset_id': adset_id
          }})
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was',cloneDeep(result))

              if(result.ads){
                
                if(source_type === "source"){
                  set_source_ads(result.ads)
                  set_selected_source_ads(result.ads)
                }else{
                  set_target_ads(result.ads)
                  set_selected_target_ads(result.ads)
                }

              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              } else{
                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)
              }
  
            },
        
            (error) => {

              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
              console.log('error was',error)

            }
      )

    })


  }


  function reset_page(delayed){

    setTimeout(function() {

      window.location.reload();

  }, delayed ? 100:1)
}


  function sync_ads(){

    console.log('sycing')

    var request_body = {}

    request_body.sync_task = {}


    if(source_account == null || source_account.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid source account")
      return
    }

    if(target_account == null || target_account.length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid target account")
      return
    }

    if(source_campaign == null || Object.keys(source_campaign).length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid source campaign")
      return
    }

    if(target_campaign == null || Object.keys(target_campaign).length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select a valid target campaign")
      return
    }

    if(selected_source_adset == null || Object.keys(selected_source_adset).length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select valid source adsets")
      return
    }
  
    if(selected_target_adset == null || Object.keys(selected_target_adset).length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select valid target adsets")
      return
    }
  
    if(selected_source_ads == null || Object.keys(selected_source_ads).length === 0){
      cmsContext.setSnackBarOpen(true, "error", "Please select valid source ads")
      return
    }

    if ((target_ads.length + selected_source_ads.length) > 50){
      cmsContext.setSnackBarOpen(true, "error", "Total number of ads in an adset cannot be greater than 50!")
      return
    }


    request_body.sync_task.source_account = source_account;

    request_body.sync_task.target_account = target_account;


    all_accounts.forEach((child_account) => {
      if(child_account.id === source_account){
        request_body.sync_task.source_account_name = child_account.name
      }else if(child_account.id === target_account){
        request_body.sync_task.target_account_name = child_account.name
      }
    })

    
    request_body.sync_task.source_campaign = source_campaign.id;

    request_body.sync_task.source_campaign_name = source_campaign.name;

    request_body.sync_task.target_campaign = target_campaign.id;

    request_body.sync_task.target_campaign_name = target_campaign.name;

    request_body.sync_task.selected_source_adset = selected_source_adset.id;

    request_body.sync_task.selected_source_adset_name = selected_source_adset.name;

    request_body.sync_task.selected_target_adset = selected_target_adset.id;

    request_body.sync_task.selected_target_adset_name = selected_target_adset.name;

    request_body.sync_task.selected_source_ads = selected_source_ads;

    request_body.sync_task.email = cmsContext.current_shop.email

    console.log('request body: ', request_body)



    setIsLoading(true)

    getAccessJwtToken().then((auth_creds) => {

      if(!has_marketing_write_access(auth_creds.current_user)){
        cmsContext.setSnackBarOpen(true, "error", "You do not have permission to perform this action!")
        setIsLoading(false)
        return
      }

      fetch('https://fri7xzt2n6.execute-api.eu-west-2.amazonaws.com/SnagCMS/reddit/sync_task', {
          crossDomain:true,
          method: 'POST',
          headers: {
            'content-type':'application/json',
            'Authorization':auth_creds.jwt
          },
          body: JSON.stringify(request_body)
        })
          .then(res => res.json())
          .then(
            (result) => {
  
              setIsLoading(false)
  
              console.log('result was',result)

              if(result.message){

                cmsContext.setSnackBarOpen(true, "success", result.message)

                reset_page(true)
              
              }else if(result.errorMessage){

                cmsContext.setSnackBarOpen(true, "error", result.errorMessage)
                
              }else{

                cmsContext.setSnackBarOpen(true, "error", "Error fetching data, please reload page")
                console.log('Error', result)

              }
  
            },
        
            (error) => {
              setIsLoading(false)
              cmsContext.setSnackBarOpen(true, "error", "Error completing request, please try again")
              console.log('error was',error)
            }
      )

    })


  }


};


export default RedditSync;